import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  ManualInputField,
  ManualInputTypeKeys,
  ManualInputTypes,
  isSummableType
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/ManualInputField';
import React, { FormEvent, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { CountIdIcon } from 'src/components/utils/CountIdIcon';
import useStateReducer from 'src/hooks/useStateReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { EditorProps } from 'src/util/processFlow/types';

const useStyles = appMakeStyles(({ resizableContainer, spacing, other }) => ({
  container: {
    ...resizableContainer(1),
    marginLeft: 0,
    maxWidth: '1000px'
  },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  margin: { marginBottom: spacing(1) },
  formControl: {
    margin: spacing(0, 1),
    minWidth: '100%'
  },
  tooltip: {
    alignSelf: 'center'
  }
}));

const ManualInputFieldEditor: React.FC<EditorProps<ManualInputField>> = (props) => {
  const { $t } = useIntl();
  const classes = useStyles();
  const theme = useAppTheme();
  const { domain } = useParams<DomainMatchParams>();

  const [mif, setMif] = useStateReducer(props.data);
  const [isCheckFieldFocused, setIsCheckFieldFocused] = useState(false);

  const onFocusListener = () => setIsCheckFieldFocused(document.activeElement?.id === 'checkField');

  useEffect(() => {
    document.addEventListener('focusin', onFocusListener);
    return () => document.removeEventListener('focusin', onFocusListener);
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const [key, value] = [e.target.name, e.target.value];

    const sideEffects: Partial<ManualInputField> = {};
    // Force summable to false if not a summable type
    if (key === 'inputType' && !isSummableType(value)) {
      sideEffects.isSummable = false;
    }
    setMif({ [key]: value, ...sideEffects });
  };

  const handleCheckboxChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
    setMif({ [name]: event.target.checked });

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const newData = { ...mif };
    newData.maxAttempts = Number(newData.maxAttempts);
    newData.checkField = newData.useCheckField ? newData.checkField || newData.field : '';

    props.onSave(newData);
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        {$t(
          { id: 'mif.title' },
          {
            action: $t({ id: 'edit' }),
            atTo: $t({ id: 'at' }),
            domain
          }
        )}
        <Tooltip
          disableTouchListener
          className={classes.tooltip}
          title={
            <Typography variant="subtitle2">
              {$t({ id: 'dSett.manInputInfo' }, { nl: <br key="nl3" /> })}
            </Typography>
          }
        >
          <HelpOutlineIcon fontSize="small" sx={{ color: 'info.dark' }} />
        </Tooltip>
      </Typography>

      <form onSubmit={handleSubmit}>
        {/* Name, checkfield, inputType | action, Max attempts */}
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              select
              fullWidth
              required
              label={$t({ id: 'mif.inputType' })}
              variant="outlined"
              value={mif.inputType}
              onChange={handleChange}
              inputProps={{ name: 'inputType', id: 'type-select' }}
            >
              {ManualInputTypeKeys.map((value, index) => (
                <MenuItem value={value} key={`${value}${index}`}>
                  {$t({ id: `mif.${value}` })}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="field"
              name="field"
              type="text"
              label={$t({ id: 'mif.field' })}
              value={mif.field}
              onChange={handleChange}
              autoFocus
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              required
              id="description"
              name="description"
              type="text"
              label={$t({ id: 'description' })}
              value={mif.description}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="unity"
              name="unity"
              type="text"
              label={`${$t({ id: 'mif.unity' })} (${$t({
                id: 'optional'
              })})`}
              value={mif.unity}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={mif.useCheckField ? 5 : 12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mif.useCheckField}
                  color="secondary"
                  onChange={handleCheckboxChange('useCheckField')}
                />
              }
              label={$t({ id: 'mif.useCheckField' })}
              labelPlacement="end"
            />
          </Grid>
          {mif.useCheckField && (
            <>
              <Grid item xs={12} md={7} lg={4}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="checkField"
                  name="checkField"
                  type="text"
                  label={
                    isCheckFieldFocused
                      ? $t({ id: 'mif.checkField' })
                      : mif.checkField
                        ? $t({ id: 'mif.checkField' })
                        : (mif.checkField && `<${mif.checkField}>`) ||
                          (mif.field && `<${mif.field}>`) ||
                          $t({ id: 'mif.checkField' })
                  }
                  value={mif.checkField}
                  onChange={handleChange}
                  className={classes.margin}
                />
              </Grid>
              <Grid item xs={12} md={5} lg={3}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="maxAttempts"
                  name="maxAttempts"
                  type="number"
                  label={$t({ id: 'mif.maxAttempts' })}
                  value={mif.maxAttempts}
                  onChange={handleChange}
                  className={classes.margin}
                  inputProps={{ min: 0, max: 3 }}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12} lg={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mif.showHistory}
                  color="secondary"
                  onChange={handleCheckboxChange('showHistory')}
                />
              }
              label={$t({ id: 'mif.showHistory' })}
              labelPlacement="end"
            />
          </Grid>
          {(mif.inputType === ManualInputTypes.decimal ||
            mif.inputType === ManualInputTypes.integer) && (
            <Grid container item xs={12} lg={6} alignItems="baseline">
              <Grid>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mif.isSummable}
                      color="secondary"
                      onChange={handleCheckboxChange('isSummable')}
                    />
                  }
                  label={$t({ id: 'mif.isSummable' })}
                  labelPlacement="end"
                  className={classes.margin}
                />
              </Grid>
              <Grid>
                <Tooltip
                  disableTouchListener
                  className={classes.tooltip}
                  title={
                    <Typography variant="subtitle2">{$t({ id: 'mif.isSummableInfo' })}</Typography>
                  }
                >
                  <HelpOutlineIcon fontSize="medium" style={{ color: theme.palette.info.dark }} />
                </Tooltip>
              </Grid>
              <Grid style={{ alignSelf: 'flex-start', marginTop: theme.spacing(1) }}>
                <CountIdIcon />
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} lg={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mif.mandatory}
                  color="secondary"
                  onChange={handleCheckboxChange('mandatory')}
                />
              }
              label={$t({ id: 'mif.mandatory' })}
              labelPlacement="end"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mif.isAutoFillable ?? false}
                  color="secondary"
                  onChange={handleCheckboxChange('isAutoFillable')}
                />
              }
              label={$t({ id: 'mif.isAutoFillable' })}
              labelPlacement="end"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs />
          <Grid item xs="auto">
            <Button variant="contained" color="secondary" type="submit">
              {$t({ id: 'saveChanges' })}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default ManualInputFieldEditor;
