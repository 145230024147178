import { Fade } from '@mui/material';
import { useEffect, useState } from 'react';
import { getLogo } from 'src/assets/assets';
import { useAuth } from 'src/hooks/useAuth';
import Splash from './Splash';

export const FIRST_TIME_INIT_KEY = 'flyidFirstTimeInit';

// COR Disable before deploy
const DISABLE_FOR_DEV = false;

const LandingLoading: React.FC = ({ children }) => {
  const { loading: authLoading } = useAuth();

  const [isFirstInit, setFirstInit] = useState(
    JSON.parse(localStorage.getItem(FIRST_TIME_INIT_KEY) ?? 'true') as boolean
  );
  // Check if is first init, if not, show splash for as long as auth takes to load,
  // then make sure first init is reset
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isFirstInit) {
      timeoutId = setTimeout(() => {
        localStorage.setItem(FIRST_TIME_INIT_KEY, 'false');
        setFirstInit(false);
      }, 1000);
    }
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      {/* Fade requires it's children to contain an element capable of holding a ref (div does that) */}
      <Fade
        in={!DISABLE_FOR_DEV && (isFirstInit || authLoading)}
        timeout={{ appear: 0, enter: 0, exit: isFirstInit ? 1500 : 500 }}
      >
        <div>{<Splash logo={getLogo()} />}</div>
      </Fade>
      {children}
    </>
  );
};

export default LandingLoading;
