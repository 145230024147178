import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import React from 'react';
import { IntlProvider } from 'react-intl';
import environment from 'src/environment';
import { useAppSelector } from 'src/hooks/reduxHooks';
import getTheme from '../../theme/theme';

const onError =
  !environment.isProduction || true
    ? (err: Error & { code: string }) => {
        if (err.code === 'MISSING_TRANSLATION') {
          // console.warn('Missing translation', err.message);
          return;
        }
        throw err;
      }
    : undefined;

const IntlAndTheme: React.FC = (props) => {
  const { locale, messages } = useAppSelector(({ locale }) => locale);

  return (
    // Migration from v4 to v5 requires StyledEngineProvider:
    // https://mui.com/material-ui/migration/v5-style-changes/#%E2%9C%85-adjust-css-injection-order
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={getTheme(locale)}>
        <IntlProvider locale={locale} messages={messages} onError={onError}>
          {/* Top-level MUI CSS Baselines */}
          <CssBaseline />
          {props.children}
        </IntlProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default IntlAndTheme;
