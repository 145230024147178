import { applyActionCode, verifyPasswordResetCode } from 'firebase/auth';
import useOnceEffect from 'flyid-ui-components/dist/hooks/useOnceEffect';
import { parse } from 'qs';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from 'src/firebase/firebase';
import { useAppDispatch } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { updateUi } from '../../redux/reducers/uiReducer';
import SetPassword from '../user/SetPassword';
import BadRequest from '../widgets/BadRequest';
import LoadingCircle from '../widgets/LoadingCircle';

enum State {
  VALIDATING,
  SET_PASSWORD,
  ERROR
}
const EmailActionsHandler: React.FC = () => {
  const { search } = useLocation();
  const queryParams = parse(search, { ignoreQueryPrefix: true });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { $t } = useIntl();

  const [state, setState] = useStateReducer({
    errorMsg: null as string | null,
    state: State.VALIDATING,
    data: undefined as { email: string; actionCode: string } | undefined
  });

  useOnceEffect(() => {
    switch (queryParams.mode) {
      case 'verifyEmail':
        if (!queryParams.oobCode) {
          setState({
            errorMsg: $t({ id: 'emailHandler.invalidData' }),
            state: State.ERROR
          });
          break;
        }

        applyActionCode(auth, queryParams.oobCode as string)
          .then(async () => {
            await new Promise((resolve) => setTimeout(resolve, 3000));
            await auth.currentUser?.reload();

            dispatch(
              updateUi({
                snackbar: {
                  message: {
                    msgCode: 'emailHandler.emailVerified',
                    msg: 'Your email has been verified!'
                  },
                  duration: 8000,
                  severity: 'success',
                  show: true
                }
              })
            );
            navigate('/', { replace: true });
          })
          .catch((err: Error) =>
            setState({
              errorMsg: err.message,
              state: State.ERROR
            })
          );

        break;
      case 'resetPassword':
        if (!queryParams.oobCode) {
          setState({
            errorMsg: $t({ id: 'emailHandler.invalidData' }),
            state: State.ERROR
          });
          break;
        }

        verifyPasswordResetCode(auth, queryParams.oobCode as string)
          .then((email) => {
            setState({
              errorMsg: null,
              state: State.SET_PASSWORD,
              data: { email, actionCode: queryParams.oobCode as string }
            });
          })
          .catch((err: Error) =>
            setState({
              errorMsg: err.message,
              state: State.ERROR
            })
          );
        break;
      case 'recoverEmail':
      default:
        navigate(-1);
        break;
    }
    return [true];
  }, [queryParams]);

  switch (state.state) {
    case State.ERROR:
      return <BadRequest text={state.errorMsg || 'Error!'} />;
    case State.SET_PASSWORD:
      return <SetPassword resetData={state.data} />;
    case State.VALIDATING:
    default:
      return <LoadingCircle text={$t({ id: 'emailHandler.validatingData' })} />;
  }
};

export default EmailActionsHandler;
