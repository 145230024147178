import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from 'src/redux/store';

/**
 * Establishes a dispatcher with customized redux types for this App
 */
export const useAppDispatch = () => useDispatch<AppDispatch>();

/**
 * Establishes a selector with customized State for this App
 */
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
