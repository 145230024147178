import { releaseProxy, wrap } from 'comlink';
import { DataWorker } from './dataWorker';

/**
 * Creates a worker, a cleanup function and returns it
 *
 * Do not use directly, only exported for test mocking!
 */
export function makeWorkerApiAndCleanup() {
  // Here we create our worker and wrap it with comlink so we can interact with it
  const worker = new Worker(new URL('./dataWorker.ts', import.meta.url));
  // Wrap worker with comlink to make api promise-like
  const workerApi = wrap<DataWorker>(worker);
  // A cleanup function that releases the comlink proxy and terminates the worker
  const cleanup = () => {
    workerApi[releaseProxy]();
    worker.terminate();
  };

  return { workerApi, cleanup };
}
