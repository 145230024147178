import { CardMedia, Skeleton } from '@mui/material';
import { appMakeStyles } from 'src/theme/theme';
import { Nilable } from 'tsdef';

const useStyles = appMakeStyles((theme) => ({
  splashStyle: {
    backgroundColor: theme.palette.primary.main,
    width: '100vw',
    height: '100vh',
    margin: '0',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    top: '0',
    maxWidth: 'none',
    //next lines guarantees the splash screen will always be above everything else
    zIndex: Number.MAX_SAFE_INTEGER,
    position: 'absolute'
  },
  logo: {
    width: 272,
    height: 120
  }
}));

type Props = {
  logo: Nilable<string>;
};

export default function Splash(props: Props) {
  const classes = useStyles();
  return (
    <div className={classes.splashStyle} data-testid={'splash-root'}>
      {props.logo ? (
        <CardMedia component="img" className={classes.logo} image={props.logo} title="Logo" />
      ) : (
        <Skeleton variant="rectangular" className={classes.logo} data-testid="splash-skeleton" />
      )}
    </div>
  );
}
