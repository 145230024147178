import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { PictureTaking } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/PictureTaking';
import React, { FormEvent } from 'react';
import { useIntl } from 'react-intl';
import useStateReducer from 'src/hooks/useStateReducer';
import { appMakeStyles } from 'src/theme/theme';
import { EditorProps } from 'src/util/processFlow/types';

const useStyles = appMakeStyles(({ resizableContainer, spacing, other }) => ({
  container: {
    ...resizableContainer(1),
    marginLeft: 0,
    maxWidth: '1000px'
  },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  margin: { marginBottom: spacing(2) },
  formControl: {
    margin: spacing(0, 1),
    minWidth: '100%'
  }
}));

const PictureTakingWidget: React.FC<EditorProps<PictureTaking>> = (props) => {
  const { $t } = useIntl();
  const classes = useStyles();

  const [picTaking, setPicTaking] = useStateReducer(props.data);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const newData = { ...picTaking };
    props.onSave(newData);
  };

  return (
    <Container className={classes.container}>
      <Typography variant="h5" className={classes.title}>
        {$t({ id: 'picTaking.title' })}
      </Typography>

      <form onSubmit={handleSubmit}>
        {/* Name, checkfield, inputType | action, Max attempts */}
        <Grid container alignItems="center">
          <Grid item xs="auto">
            <TextField
              fullWidth
              variant="outlined"
              required
              id="name"
              name="name"
              type="text"
              label={$t({ id: 'name' })}
              placeholder={$t({ id: 'pictureTaking.name' })}
              value={picTaking.name}
              onChange={(e) => setPicTaking({ name: e.target.value })}
              autoFocus
            />
          </Grid>
          <Grid item xs="auto" sx={{ pl: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={picTaking.isMandatory}
                  color="secondary"
                  onChange={(e) => setPicTaking({ isMandatory: e.target.checked })}
                />
              }
              label={$t({ id: 'mif.mandatory' })}
              labelPlacement="end"
              className={classes.margin}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs />
          <Grid item xs="auto" sx={{ mt: 2 }}>
            <Button variant="contained" color="secondary" type="submit">
              {$t({ id: 'saveChanges' })}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default PictureTakingWidget;
