"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageLoadWrapper = void 0;
const immer_1 = require("immer");
class ImageLoadWrapper {
    constructor() {
        // To allow usage of class instance in redux
        this[_a] = true;
        this.isLoading = false;
        this.isLoaded = false;
        this.loadedAt = 0; // load time ms
    }
}
exports.ImageLoadWrapper = ImageLoadWrapper;
_a = immer_1.immerable;
