// eslint-disable import/no-extraneous-dependencies
import { Router, RouterNavigateOptions } from '@remix-run/router';
import { createBrowserRouter } from 'react-router-dom';

let router: Router;
/**
 * Creates a singleton router for this app
 *
 * @param forceRecreation set to true during tests to enforce recreation at every step. It avoids
 *  conflicts between the router state and tests environment state.
 */
export function createRouter(element: JSX.Element, forceRecreation = false) {
  if (!router || forceRecreation)
    router = createBrowserRouter([{ path: '*', element }], {
    future: {
        v7_fetcherPersist: true,
        v7_normalizeFormMethod: true,
        v7_partialHydration: true,
        v7_relativeSplatPath: true, // Enables relative paths in nested routes
        v7_skipActionErrorRevalidation: true // Prevents revalidation when action errors occur
      }
    });
  return router;
}

/** Returns the singleton router for this app */
export const getRouter = () => router;

/**
 *  Helper method to navigate outside RRD context without forcing await/catch.
 *  Use route as -1 to go back and 1 to go forward.
 */
export const silentyNavigateTo = (route: number | string, opts?: RouterNavigateOptions): void =>
  router.navigate(route as string, opts).catch(null) as unknown as void;

/** To get current pathname, use this method */
export const getCurrentPathname = () => decodeURI(getRouter().state.location.pathname);
