import useWindowDimensions from './useWindowDimensions';

/**
 * Returns current mui breakpoint value given window size.
 * Values from: https://mui.com/material-ui/customization/breakpoints/#default-breakpoints
 */
const useMuiBreakpoints = (): 'xs' | 'sm' | 'md' | 'lg' | 'xl' => {
  const { width } = useWindowDimensions();
  return width < 600 ? 'xs' : width < 900 ? 'sm' : width < 1200 ? 'md' : width < 1536 ? 'lg' : 'xl';
};

export default useMuiBreakpoints;
