import KeyboardIcon from '@mui/icons-material/Keyboard';
import { CustomMarker } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/CustomMarker';
import { HandleType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import React, { memo } from 'react';
import {
  arePropsEqual,
  isCommonNodeDataEqual,
  isSpecificDataEqual
} from 'src/util/processFlow/node';
import { TypedNode } from 'src/util/processFlow/types';
import BaseNode, { Content, Handles } from './BaseNode';
import { NodeProps } from '@xyflow/react';

export const CustomMarkerNode: React.FC<NodeProps<TypedNode<CustomMarker>>> = (props) => {
  const customMarker = props.data.specificData;

  const content: Content = {
    iconStart: KeyboardIcon,
    titleId: 'processFlow.CustomMarker',
    description: customMarker?.name
  };

  const handles: Handles = {
    inputHandles: props.data.parent ? [] : [HandleType.MULTIPLE],
    outputHandles: [HandleType.SINGLE]
  };

  return (
    <div>
      {/* Content and basic handlers */}
      <BaseNode
        selected={props.selected}
        content={content}
        handles={handles}
        id={props.id}
        parent={props.data.parent}
        {...props.data.baseNodeData}
      />
    </div>
  );
};

export default memo(CustomMarkerNode, arePropsEqual(isCommonNodeDataEqual, isSpecificDataEqual));
