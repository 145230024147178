import FlagIcon from '@mui/icons-material/Flag';
import SearchIcon from '@mui/icons-material/Search';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import { Checkbox, IconButton } from '@mui/material';
import {
  AcquisitionDataFlags,
  StandardFlags
} from 'flyid-core/dist/Database/Models/AcquisitionData';
import { getSessionDoc } from 'flyid-core/dist/Util/database';
import { parseEpochFromTimestamp, TimestampLike } from 'flyid-core/dist/Util/time';
import { storeEventCheck } from 'flyid-core/dist/Util/web';
import useStoredState from 'flyid-ui-components/dist/hooks/useStoredState';
import { useMemo } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';
import { buildDocumentRef } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { useClosestSettings } from 'src/hooks/useClosestSettings';
import { selectSettings } from 'src/redux/selectors/dataSelectors';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import { selectCurrentUserProfile } from 'src/redux/selectors/userSelectors';
import {
  HeaderCellObject,
  PICTURES_COLUMN,
  STANDARD_FLAGS_COLUMN,
  TableRow
} from 'src/util/helpers/table';
import { fetchAndShowStoragePictures } from '../../redux/actions/userActions';
import { useSessionDataParser } from '../../workers/dataWorkerApi';
import EnhancedTable, { Selector } from './EnhancedTable/EnhancedTable';
import { ResultFields } from 'flyid-core/dist/Database/Models';
import { isTranslatableError } from 'flyid-core/dist/Util/exceptions';

const showInputDataStorageKey = 'sessionShowInputData';
const showBaseFieldsStorageKey = 'sessionShowBaseFields';

const SessionView: React.FC = () => {
  const { domain, session } = useParams<SessionMatchParams>();

  // Fallback to home if domain or session is missing
  if (!domain || !session) return <Navigate replace to="/" />;

  const dispatch = useAppDispatch();
  const intl = useIntl();
  const $t = intl.$t;

  const { profile, mostRecentSettings, locale, company } = useAppSelector((state) => ({
    company: selectTargetCompany(state),
    profile: selectCurrentUserProfile(state),
    mostRecentSettings: selectSettings(state, domain),
    locale: state.locale.locale
  }));

  const [sessionData, loadingSessionData] = useDocumentDataOnce(
    company && profile ? buildDocumentRef(getSessionDoc(company, domain, session)) : undefined
  );
  const wasTask = Boolean(sessionData?.wasTask);

  const [, settings] = useClosestSettings(
    company,
    domain,
    sessionData?.startTime,
    mostRecentSettings
  );

  const [showInputData, setShowInputData] = useStoredState(showInputDataStorageKey, wasTask);
  const [showBaseFields, setShowBaseFields] = useStoredState(showBaseFieldsStorageKey, false);

  const { isWorking, rowsAndColumnsOrError: parsedDataOrError } = useSessionDataParser(
    sessionData,
    settings,
    showInputData,
    showBaseFields
  );

  const rowsAndColumnsOrError = useMemo(() => {
    /* istanbul ignore next */
    if (isTranslatableError(parsedDataOrError) || !parsedDataOrError) return parsedDataOrError;

    const picturesColumn: HeaderCellObject = {
      id: PICTURES_COLUMN,
      tooltip: $t({ id: 'session.associatedPictures' }),
      export: false,
      icon: {
        iconElement: WallpaperIcon,
        props: {
          fontSize: 'large'
        }
      },
      headerCellProps: {
        padding: 'none',
        align: 'center'
      },
      dataCellProps: {
        align: 'center'
      },
      dataContentGetter: (row: TableRow) =>
        !!row[AcquisitionDataFlags.HasPictures] ? (
          <IconButton aria-label="viewImage" size="large">
            <SearchIcon fontSize="medium" />
          </IconButton>
        ) : null
    };
    const flagsColumn: HeaderCellObject = {
      id: STANDARD_FLAGS_COLUMN,
      export: true,
      tooltip: $t({ id: 'session.acquisitionFlags' }),
      icon: {
        iconElement: FlagIcon,
        props: {
          fontSize: 'large'
        }
      },
      headerCellProps: {
        padding: 'none',
        align: 'center'
      },
      dataCellProps: {
        align: 'center'
      },
      dataContentGetter: (row: TableRow) =>
        Object.entries(StandardFlags)
          .map(([resultkey, dataFlagKey]) =>
            row[dataFlagKey]
              ? (settings?.fieldSettings.resultFields[resultkey as keyof ResultFields] ?? resultkey)
              : null
          )
          // There should never be more than one standard flag in a row, but treat it by precaution
          .filter(Boolean)
          .join('\n') ?? null
    };

    return {
      columns: [picturesColumn, flagsColumn, ...parsedDataOrError.columns],
      rows: parsedDataOrError.rows
    };
  }, [parsedDataOrError, locale]);

  const topToolbarExtraSelectors = [] as Selector[];
  if (wasTask) {
    topToolbarExtraSelectors.push({
      label: $t({ id: 'session.inputFormat' }),
      control: (
        <Checkbox
          sx={{ mr: 2 }}
          color="secondary"
          checked={showInputData}
          onChange={storeEventCheck(showInputDataStorageKey, setShowInputData)}
        />
      )
    });
  }

  topToolbarExtraSelectors.push({
    label: $t({ id: 'session.showBaseFields' }),
    control: (
      <Checkbox
        sx={{ mr: 2 }}
        color="secondary"
        checked={showBaseFields}
        onChange={storeEventCheck(showBaseFieldsStorageKey, setShowBaseFields)}
      />
    )
  });

  const onPictureClick = (row: TableRow) => {
    /* istanbul ignore next */
    if (!settings || !profile || !company) return;
    const filenames = row[AcquisitionDataFlags.PictureFilenames] as string | string[];
    dispatch(
      fetchAndShowStoragePictures({
        company,
        domain,
        address: row.address as string,
        checkedAtMs: parseEpochFromTimestamp(row.checkedAt as TimestampLike),
        pictureFilenames: Array.isArray(filenames) ? filenames : [filenames],
        addressField: settings?.fieldSettings.baseFields.address,
        intl
      })
    );
  };

  const dataLoaded = Boolean(settings && !loadingSessionData && !isWorking);

  return (
    <EnhancedTable
      tableId="session"
      selectionColumn={'address'}
      dataLoaded={dataLoaded}
      data={rowsAndColumnsOrError}
      title={$t({ id: 'domain.title' }, { domain })}
      clickAction={(_, column) =>
        column.id === PICTURES_COLUMN ? { onClick: onPictureClick } : undefined
      }
      toolbarOptions={{
        exportButton: 'show',
        exportFileName: session,
        title: $t({ id: 'session.title' }, { session }),
        extraSelectors: topToolbarExtraSelectors,
        disableExportButton: false
      }}
      forceTableResetChange={[company, domain]}
    />
  );
};

export default SessionView;
