import { Unsubscribe } from 'firebase/auth';
import {
  getCountFromServer,
  getDocs,
  limit,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  startAfter,
  onSnapshot,
  FieldPath
} from 'firebase/firestore';
import { DependencyList, useEffect, useRef } from 'react';
import { Nilable } from 'tsdef';
import useEffectDiff from './useEffectDiff';
import useStateReducer from './useStateReducer';

//COR set to false on release
const DEBUG_PAGINATION_HOOK = false;

type StateType<T> = {
  lastDocument: QueryDocumentSnapshot<T> | null;
  isLoading: boolean;
  count: number;
  data: QueryDocumentSnapshot<T>[] | null;
};
const initialStateData: StateType<never> = {
  lastDocument: null,
  isLoading: false,
  count: 0,
  data: null
};
/**
 * This hook will manage data pagination for the given query.
 *
 * If the dataQuery, column or columnOrder changes, onPageChange will be triggered, setting the
 * selected page to 0. This happens to avoid inconsistent states when the fetched data amount is not
 * enough to fill the data shown by the selected page.
 *
 * The data query must not use different columns for filtering and sorting.
 *
 * @param extraResetDeps parameters that should cause the query to reset as well
 */
const usePaginatedData = <T>(
  dataQuery: Query<T> | null,
  page: number,
  pageSize: number,
  orderByColumn: Nilable<string | FieldPath>,
  columnOrder: Nilable<'asc' | 'desc'>,
  onPageChange: (page: number) => void,
  isDataTable = false,
  useListener?: boolean,
  extraResetDeps: DependencyList = []
) => {
  const [state, setState] = useStateReducer<StateType<T>>({ ...initialStateData });
  const unsubscribe = useRef<Unsubscribe | null>(null);

  const checkData = (reset: boolean): ReturnType<React.EffectCallback> => {
    // How much data we have fetched respecting this query constraints, so far
    const fetchedDataCount = reset ? 0 : (state.data?.length ?? 0);
    // The last element's count obtained, i.e. the maximum amount
    // of available elements in the server respecting this query's constraint
    const currQueryCount = state.count;

    // The number of elements the UI is requiring from this provider
    const uiRequirementCount = pageSize * (isDataTable ? page + 1 : page);

    // In order for this hook to fetch more elements, the following requirements must abide:
    // * Some important parameter changed, forcing reset, or is first fetch. (fetchedDataCount -> 0)
    // OR
    // * The user changed page or pageSize, leading to the need of more data, as long as:
    //   a. the ui provides space for the data, i.e. fetchedDataCount < uiRequirementCount
    //   b. there is more data for fetching, i.e. reset || (fetchedDataCount < currQueryCount)
    if (
      !!dataQuery &&
      fetchedDataCount < uiRequirementCount &&
      (reset || fetchedDataCount < currQueryCount)
    ) {
      let currentDataQuery = query(dataQuery, limit(pageSize));
      // In case the user order by column
      if (columnOrder && orderByColumn) {
        currentDataQuery = query(currentDataQuery, orderBy(orderByColumn, columnOrder));
      }
      // If the query has already been made, start from the last element seen
      if (!reset && state.lastDocument) {
        currentDataQuery = query(currentDataQuery, startAfter(state.lastDocument));
      }

      setState({ isLoading: true });

      if (useListener) {
        // Unsubscribe before making a new subscription
        unsubscribe.current?.();
        if (DEBUG_PAGINATION_HOOK && !!unsubscribe.current) {
          console.log('unsubscribe old query!');
        }

        unsubscribe.current = onSnapshot(currentDataQuery, {
          next: (snap) => {
            // Fetch the count of documents from the server
            getCountFromServer(dataQuery)
              .then((countSnap) => {
                const count = countSnap.data().count;

                // Update the state with the new data and count
                setState({
                  data: [...(reset ? [] : (state.data ?? [])), ...snap.docs],
                  isLoading: false,
                  count,
                  lastDocument: snap.docs[snap.docs.length - 1]
                });
              })
              .catch((error: Error) => {
                if (DEBUG_PAGINATION_HOOK) console.error(`Failed fetching count: ${error.message}`);
                setState({ ...initialStateData });
              });
          },
          error: (error) => {
            if (DEBUG_PAGINATION_HOOK) console.error(`Failed fetching data: ${error.message}`);
            setState({ ...initialStateData });
          }
        });
      } else {
        getCountFromServer(dataQuery)
          .then(async (countSnap) => {
            const count = countSnap.data().count;
            // Total count returned 0, meaning the query will be empty, there's no need to fetch it.
            if (count === 0) {
              setState({ ...initialStateData });
              return;
            }

            const _data = await getDocs(currentDataQuery);
            setState({
              data: [...(reset ? [] : (state.data ?? [])), ..._data.docs],
              isLoading: false,
              count: countSnap.data().count,
              lastDocument: _data.docs[_data.docs.length - 1]
            });

            if (reset) onPageChange(isDataTable ? 0 : 1);
          })
          .catch((err) => {
            if (DEBUG_PAGINATION_HOOK) console.error('Failed counting docs: ', err);
            setState({ ...initialStateData });
          });
      }
    }
  };

  useEffectDiff(
    (diff) => {
      const changes = Object.keys(diff);
      if (changes.length) {
        // page doesn't trigger reset, but all other params do,
        // so  changes to indexes >= 1 (>= columOrder) should trigger reset
        const reset = parseInt(changes.sort().reverse()[0] ?? 0) >= 1;
        return checkData(reset);
      }
    },
    [page, pageSize, columnOrder, orderByColumn, dataQuery, JSON.stringify(extraResetDeps)]
  );

  // Unsubscribe on unmount.
  useEffect(
    () => () => {
      if (DEBUG_PAGINATION_HOOK) console.log('unsubscribed on unmount!');
      unsubscribe.current?.();
      unsubscribe.current = null;
    },
    []
  );

  return {
    isLoading: state.isLoading,
    data: state.data,
    count: state.count
  };
};

export default usePaginatedData;
