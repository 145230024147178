import React, { ErrorInfo, PropsWithChildren } from 'react';

class ErrorBoundary extends React.Component<PropsWithChildren<unknown>, { hasError: boolean }> {
  constructor(props: PropsWithChildren<unknown>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error('An error occured!', error, errorInfo.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props?.children;
  }
}

export default ErrorBoundary;
