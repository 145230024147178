import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { immerable } from 'immer';
import { DeepPartial, Nilable } from 'tsdef';

export class LabelState {
  [immerable]? = true;
  /** The image src from cloud storage */
  src?: string;
  /** The image src defined by the user locally (the user selected an image) */
  localSrc?: string;
  /** Whether fetching has started or not */
  isTriggered? = false;
  /** Whether fetching has finished or not (may have failed) */
  isLoaded? = false;
}

export type UpdateLabelImageStateActionData = {
  labelId: string;
  domain: string;
  labelState: DeepPartial<LabelState>;
};

export type CopyLabelImageStateActionData = {
  sourceId: string;
  targetId: string;
  domain: string;
};

export type SetLabelImageLoadedActionData = {
  labelId: string;
  domain: string;
  src?: string;
};

export type RemoveLabelImageActionData = {
  labelId: string;
  domain: string;
};

type ResetLabelImageStatesData = {
  targetDomain: string;
  /** Set to null to remove all entries */
  labelIds: Nilable<string[]>;
};

export type LabelImagesState = { [domain: string]: { [labelId: string]: Nilable<LabelState> } };

const managementSlice = createSlice({
  name: 'labelImages',
  initialState: {} as LabelImagesState,
  reducers: {
    setLabelImageFetchTriggered: (state, action: PayloadAction<RemoveLabelImageActionData>) => {
      const labelId = action.payload.labelId;
      const domain = action.payload.domain;
      if (!state[domain]) state[domain] = {};

      state[domain][labelId] = Object.assign(state[domain][labelId] || new LabelState(), {
        isTriggered: true,
        isLoaded: false
      });
    },
    setLabelImageLoaded: (state, action: PayloadAction<SetLabelImageLoadedActionData>) => {
      const { labelId, domain, src } = action.payload;

      if (!state[domain]) state[domain] = {};

      state[domain][labelId] = Object.assign(state[domain][labelId] || new LabelState(), {
        src: src,
        isTriggered: false,
        isLoaded: true
      });
    },
    updateLabelImageState: (state, action: PayloadAction<UpdateLabelImageStateActionData>) => {
      const { labelState, labelId, domain } = action.payload;

      if (!state[domain]) state[domain] = {};

      state[domain][labelId] = Object.assign(
        state[domain][labelId] || new LabelState(),
        labelState
      );
    },
    copyLabelImageState: (state, action: PayloadAction<CopyLabelImageStateActionData>) => {
      const { domain, sourceId, targetId } = action.payload;

      if (!state[domain]) return;

      const source = state[domain][sourceId];
      if (!state[domain][sourceId]) return;

      const localSrc = source?.localSrc ?? source?.src;
      if (!localSrc) return;

      // Copy to localSrc
      const newData: LabelState = { isLoaded: true, localSrc };
      state[domain][targetId] = Object.assign(new LabelState(), newData);
    },
    resetLabelImageState: (state, action: PayloadAction<ResetLabelImageStatesData>) => {
      const { targetDomain, labelIds } = action.payload;
      if (labelIds) {
        labelIds.forEach((labelsToRemove) => {
          delete state[targetDomain]?.[labelsToRemove];
        });
      } else state[targetDomain] = {};
    }
  }
});

export const {
  setLabelImageFetchTriggered,
  setLabelImageLoaded,
  updateLabelImageState,
  copyLabelImageState,
  resetLabelImageState
} = managementSlice.actions;

export default managementSlice.reducer;
