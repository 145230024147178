import { useEffect, useState, useRef } from 'react';
import { fetchAndShowStoragePictures } from 'src/redux/actions/userActions';
import { FetchStoragePicturesParams } from 'src/redux/actions/userActions';
import { useAppSelector } from './reduxHooks';
import { selectAcquisitionPictures } from 'src/redux/selectors/dataSelectors';
import { ImageLoadWrapper } from 'flyid-core/dist/Redux';
import { useDispatch } from 'react-redux';

/**
 * Hook props: when first loaded, the props can be undefined. It is expected to be
 * @FetchStoragePicturesParams when it re-render.
 *
 * This hook uses the @fetchAndShowStoragePictures to fetch and store images into
 * the redux state.
 *
 * Then, acquires the value throught useAppSelector and sets it into setImages state.
 *
 * It returns an array of @ImageLoadWrapper
 */

type StoragePicturesParams = FetchStoragePicturesParams | undefined;

const useFirebaseImage = (data: StoragePicturesParams) => {
  const acqImages = useAppSelector((s) => selectAcquisitionPictures(s));
  const [images, setImages] = useState<ImageLoadWrapper[]>([]);
  const prevAcqImagesRef = useRef<typeof acqImages>();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!data) return;

    dispatch(fetchAndShowStoragePictures(data));
  }, [data]);

  useEffect(() => {
    if (!data) return;

    if (prevAcqImagesRef.current !== acqImages) {
      const imageData = data.pictureFilenames
        .map((f) => acqImages[f])
        .filter((image) => image !== undefined);

      // Only update images if imageData has changed
      if (JSON.stringify(imageData) !== JSON.stringify(images)) {
        setImages(imageData);
      }

      prevAcqImagesRef.current = acqImages;
    }
  }, [acqImages, data, images]);

  return images;
};

export default useFirebaseImage;
