"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isCustomMarkerComplete = exports.CustomMarkerRequiredKeys = exports.CustomMarkerEntries = exports.CustomMarkerKeys = exports.CustomMarker = void 0;
const helpers_1 = require("../../../../Util/helpers");
/** Describes a custom marker input */
class CustomMarker {
    constructor() {
        this.name = '';
        this.allowedMarkers = [];
        this.isMandatory = false;
    }
}
exports.CustomMarker = CustomMarker;
exports.CustomMarkerKeys = Object.keys(new CustomMarker());
exports.CustomMarkerEntries = Object.entries(new CustomMarker());
exports.CustomMarkerRequiredKeys = ['name', 'allowedMarkers'];
/** Verifies if label data contains the minimum elements for correct functioning */
const isCustomMarkerComplete = (cm) => {
    const res = Boolean(cm?.name && cm.allowedMarkers?.length && (0, helpers_1.isNonNulli)(cm.isMandatory));
    //   if (!res) console.log(cm?.name, cm?.allowedMarkers, cm?.isMandatory);
    return res;
};
exports.isCustomMarkerComplete = isCustomMarkerComplete;
