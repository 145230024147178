import { cx } from '@emotion/css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import {
  Container,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { MyDialogState } from 'src/redux/reducers/uiReducer';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import {
  selectAuthDomains,
  selectCurrentUserProfile,
  selectDomainParent
} from 'src/redux/selectors/userSelectors';
import { getStore } from 'src/redux/store';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { isKeyUserProf } from 'src/util/helpers/user';
import { Actions } from '../../redux/actions/actionsHandler';
import { updateUi } from '../../redux/reducers/uiReducer';

const useStyles = appMakeStyles((theme) => ({
  container: { ...theme.resizableContainer(2), marginLeft: 0 },
  listContainer: {
    minWidth: theme.spacing(30),
    maxWidth: theme.spacing(50),
    marginBottom: theme.spacing(2)
  },
  listItem: {
    paddingRight: theme.spacing(15),
    width: 'auto'
  },
  itemText: {
    margin: theme.spacing(1, 1)
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start'
  },
  skeletonItemText: {
    minWidth: theme.spacing(30),
    margin: theme.spacing(0, 1),
    height: theme.spacing(8)
  }
}));

const ManageDomains: React.FC = () => {
  const classes = useStyles();
  const theme = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const { profile, targetCompany, currUserAuthDomains } = useAppSelector((s) => ({
    targetCompany: selectTargetCompany(s),
    profile: selectCurrentUserProfile(s),
    currUserAuthDomains: selectAuthDomains(s)
  }));

  const showRemoveDomainDialogConfirmation = (domain: string) => {
    /* istanbul ignore next */
    let currentDomains = currUserAuthDomains ?? [];

    const reduxState = getStore().getState();
    let parentUid = selectDomainParent(reduxState, domain);
    /* istanbul ignore next */
    if (!parentUid) return;

    // If key user, we need to select only the parent's authDomains for backend checking
    if (isKeyUserProf(profile)) {
      const targetDomainParent = selectDomainParent(reduxState, domain);
      /* istanbul ignore next */
      if (!targetDomainParent) return;

      parentUid = targetDomainParent;
      /* istanbul ignore next */
      currentDomains = selectAuthDomains(reduxState, { targetUser: targetDomainParent }) ?? [];
    }

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'manDom.remConfTitle' }),
          message: $t(
            { id: 'manDom.remConfMsg' },
            {
              nl: <br key={`mdnl${domain}`} />,
              domain: <b key={`mdb0${domain}`}>{domain}</b>
            }
          ),
          useCheckbox: true,
          checkboxState: false,
          checkboxMessage: <b>{$t({ id: 'manDom.remConfCheckboxMsg' })}</b>,
          show: true
        }).setConfirmAction(Actions.REMOVE_DOMAIN, {
          data: {
            domain,
            currentDomains
          },
          parentUid
        })
      })
    );
  };

  return (
    <>
      {profile?.keyUser && targetCompany === '' ? (
        <Container className={classes.container}>
          <Typography variant="h4" sx={theme.text.title} data-testid="manage-domains-title">
            {$t({ id: 'manDom.title' })}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={theme.text.subtitle}
            data-testid="manage-domains-subt-select-company"
          >
            {$t({ id: 'keyUserCompany.warning' })}
          </Typography>
        </Container>
      ) : (
        <Container className={classes.container}>
          <Typography variant="h4" sx={theme.text.title} data-testid="manage-domains-title">
            {$t({ id: 'manDom.title' })}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={theme.text.subtitle}
            data-testid="manage-domains-subtitle"
          >
            {$t({ id: 'manDom.subtitle' })}
          </Typography>

          <List
            className={classes.listContainer}
            subheader={
              <ListSubheader sx={{ backgroundColor: 'background.default' }}>
                {$t({ id: 'manDom.domains' })}
              </ListSubheader>
            }
            data-testid="manage-domains-listheader"
          >
            {currUserAuthDomains
              ? [...currUserAuthDomains].sort().map((domain, index) => {
                  return (
                    <ListItem
                      key={`${domain}${index}`}
                      button
                      alignItems="flex-start"
                      className={classes.listItem}
                      component={Link}
                      to={`/domains/${domain}`}
                    >
                      <ListItemText id={domain} className={classes.itemText} primary={domain} />
                      <ListItemSecondaryAction>
                        <Tooltip title={$t({ id: 'manDom.editSett' })}>
                          <IconButton
                            component={Link}
                            to={`/domain/${domain}/settings`}
                            edge="start"
                            aria-label={$t({ id: 'edit' })}
                            sx={{ color: 'info.main' }}
                            size="large"
                            data-testid="manage-domains-edit"
                          >
                            <PhonelinkSetupIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={$t({ id: 'remove' })}>
                          <IconButton
                            edge="end"
                            aria-label={$t({ id: 'remove' })}
                            sx={{ color: 'error.main' }}
                            onClick={() => showRemoveDomainDialogConfirmation(domain)}
                            size="large"
                            data-testid="manage-domains-remove"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })
              : [0, 1, 2].map((v) => (
                  <Container
                    className={cx(classes.listItem, classes.skeletonContainer)}
                    key={`skel${v}`}
                  >
                    <Skeleton
                      variant="text"
                      animation="wave"
                      className={classes.skeletonItemText}
                      data-testid="manage-domains-skeleton"
                    />
                  </Container>
                ))}

            <ListItem />
            <ListItem>
              <Fab
                variant="extended"
                size="medium"
                color="secondary"
                aria-label="add"
                component={Link}
                to="/addDomain"
                data-testid="manage-domains-add"
              >
                <AddCircleIcon sx={{ mr: 1 }} />
                {$t({ id: 'manDom.addDomain' })}
              </Fab>
            </ListItem>
          </List>
        </Container>
      )}
    </>
  );
};

export default ManageDomains;
