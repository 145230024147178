import { useEffect } from 'react';
import useStateReducer from './useStateReducer';

/**
 * This callback should return whether initialization is complete and optionally a destructor
 */
type MountEffectCallback = () => [ran: boolean, destructor?: () => void];

type InternalStateType = {
  ran: boolean;
  destructor?: () => void;
};

/**
 * A hook that will will stop running once `[true, destructor?]` is returned
 * from {@link callback}.
 *
 * As long as `[false]` is returned, the effect will keep running at any {@link dependencyList} state changes
 * or any state change at all if no {@link dependencyList} has been given.
 *
 * Warning: destructor may run every render if no {@link dependencyList} is given
 * due to useEffect design.
 *
 * @param callback MountEffectCallback
 * @param dependencyList
 */
function useOnceEffect(callback: MountEffectCallback, dependencyList?: React.DependencyList) {
  const [state, setState] = useStateReducer<InternalStateType>({
    ran: false,
    destructor: undefined
  });

  useEffect(() => {
    if (!state.ran) {
      const [ran, destructor] = callback();
      if (ran) {
        setState({ ran, destructor });
        return destructor;
      }
    }

    return state.destructor;
  }, dependencyList);
}

export default useOnceEffect;
