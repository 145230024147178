import { Actions, ParameterMap } from '../actions/actionsHandler';
import { LoadingButtonState } from '../types/uiTypes';
import { DialogState, DialogStateData } from 'flyid-core/dist/Redux/types/uiTypes';
import { getUiSlice } from 'flyid-core/dist/Redux/reducers/uiReducer';

const uiSlice = getUiSlice<Actions, ParameterMap, LoadingButtonState>(new LoadingButtonState());
const { updateUi, updateUiNoReset } = uiSlice.actions;
const MyDialogState = DialogState<Actions, ParameterMap>;
type MyDialogStateData = DialogStateData<Actions, ParameterMap>;

export default uiSlice.reducer;
export { MyDialogState, updateUi, updateUiNoReset };
export const uiActions = uiSlice.actions;
export type { MyDialogStateData };
