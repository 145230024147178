import { useEffect, useRef } from 'react';

/**
 * Useful when we need to compare which states have changed and which haven't
 *
 * Copied from https://stackoverflow.com/a/53446665/7784904
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
const usePrevious = <T extends unknown>(value: T, initialValue: T): T => {
  const ref = useRef<T>(initialValue);
  useEffect(() => {
    // console.log("(previous) setting value from", ref.current, "to", value);
    ref.current = value;
  }, [value]);
  return ref.current;
};

/**
 * Useful when we need to compare which states have changed and which haven't
 *
 * Copied from https://stackoverflow.com/a/53446665/7784904
 *
 * Differs from {@link usePrevious} in that values are used as the dependency list for the backing
 * useEffect, which *may* make it more sensible to changes.
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-constraint
export const usePreviousList = <T extends unknown>(value: T[], initialValue?: T[]): T[] => {
  const ref = useRef<T[]>(initialValue ?? []);
  useEffect(() => {
    // console.log("(previous) setting value from", ref.current, "to", value);
    ref.current = value;
  }, value);
  return ref.current;
};

export default usePrevious;
