import { getStoredOrDefault, storeValue } from 'flyid-core/dist/Util/web';
import { Dispatch, SetStateAction, useState } from 'react';
import { StoredStateConverter } from '../converters/storedStateConverters';

/**
 * This hook's state is backed by the browser's localStorage, guaranteeing global access
 * and data persistence over sessions.
 */
function useStoredState<T>(
  key: string,
  def: T,
  converter: StoredStateConverter<T> | undefined = undefined
): [T, Dispatch<SetStateAction<T>>] {
  const storedData = getStoredOrDefault(key, def);
  const [state, setState] = useState<T>(converter?.fromStorage(storedData as string) ?? storedData);

  return [
    state,
    (v: SetStateAction<T>) =>
      storeValue(
        key,
        v instanceof Function ? v(state) : v,
        setState,
        converter?.toStorage as ((v: T) => string) | undefined
      )
  ];
}

export default useStoredState;
