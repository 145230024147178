import React, { useState } from 'react';
import { Box, IconButton, Card, CardMedia } from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { ImageLoadWrapper } from 'flyid-core/dist/Redux';

/**
 * Carousel component that displays a series of images with navigation buttons to move through them.
 *
 * @param {Array} props.images - An array of image objects. Each object should contain:
 *   - {string} src - The source URL of the image.
 *   - {string} alt - The alt text for the image.
 * @param {Object} [props.customMeasures] - Optional custom measures for the carousel.
 *   - {number|string} [props.customMeasures.width] - The width of the carousel. Defaults to 400 if not provided.
 *   - {number|string} [props.customMeasures.height] - The height of the carousel. Defaults to 200 if not provided.
 *   - {number|string} [props.customMeasures.padding] - The padding inside the carousel container. Defaults to 2 if not provided.
 * @param {Function} [props.onClick] - Optional callback function to be triggered when the image is clicked.
 */
type CarouselProps = {
  images: ImageLoadWrapper[];
  customMeasures?: {
    width?: number | string;
    height?: number | string;
    padding?: number | string;
  };
  onClick?: () => void;
};

const Carousel: React.FC<CarouselProps> = (props) => {
  const { images, customMeasures, onClick } = props;
  const [currentIndex, setCurrentIndex] = useState(0);

  // Default values for width, height, and padding
  const defaultWidth = 400;
  const defaultHeight = 200;
  const defaultPadding = 2;

  // Set custom values if provided, otherwise use the defaults
  const width = customMeasures?.width || defaultWidth;
  const height = customMeasures?.height || defaultHeight;
  const padding = customMeasures?.padding || defaultPadding;

  /**
   * Handles the next button click to show the next image in the array.
   * Disables the button when the last image is reached.
   */
  const handleNext = () => {
    if (currentIndex < images.length - 1) setCurrentIndex(currentIndex + 1);
  };

  /**
   * Handles the previous button click to show the previous image in the array.
   * Disables the button when the first image is reached.
   */
  const handlePrev = () => {
    if (currentIndex !== 0) setCurrentIndex(currentIndex - 1);
  };

  /**
   * Triggers the onClick callback if provided when the image is clicked.
   */
  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <Box sx={{ position: 'relative', width, height }}>
      <Card sx={{ height: '100%' }}>
        <Box
          onClick={handleClick}
          sx={{
            cursor: onClick ? 'pointer' : 'default',
            padding
          }}
        >
          <CardMedia
            data-testid="carousel-image"
            component="img"
            height={height}
            image={images[currentIndex].src}
            alt={images[currentIndex].alt}
            sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
          />
        </Box>
      </Card>

      {/* Navigation buttons */}
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          left: 0,
          zIndex: 1,
          transform: 'translateY(-50%)'
        }}
        disabled={currentIndex === 0}
        onClick={handlePrev}
      >
        <ArrowBackIosNew />
      </IconButton>
      <IconButton
        sx={{
          position: 'absolute',
          top: '50%',
          right: 0,
          zIndex: 1,
          transform: 'translateY(-50%)'
        }}
        disabled={currentIndex >= images.length - 1}
        onClick={handleNext}
      >
        <ArrowForwardIos />
      </IconButton>
    </Box>
  );
};

export default Carousel;
