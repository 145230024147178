import { useCallback, useEffect } from 'react';
import { usePreviousList } from './usePrevious';

type DiffType<T> = {
  before: Partial<T>;
  after: Partial<T>;
};
type EffectDiffResult<T> = Record<string | number, DiffType<T>>;
/**
 * Copied from https://stackoverflow.com/a/59843241/7784904
 * Changed it into an effect that identifies changes.
 * It's useful when multiple variables may change at a time and the effect depends on which variable
 * changed. It's superior to creating multiple useEffects in a sense that it doesn't trigger multiple times
 * for multiple simultaneous variable changes.
 */
const useEffectDiff = <T>(
  effectCallback: (diff: EffectDiffResult<T>) => ReturnType<React.EffectCallback>,
  dependencies: T[],
  debug = false,
  dependencyNames: string[] = []
) => {
  const previousDeps = usePreviousList(dependencies);

  const effectWithDiff: React.EffectCallback = useCallback(() => {
    const changedDeps: EffectDiffResult<T> = dependencies.reduce<
      Record<string | number, DiffType<T>>
    >((accum, dependency, index) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index;
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency
          }
        };
      }

      return accum;
    }, {});

    if (debug && Object.keys(changedDeps).length) {
      console.log('[use-effect-diff] ', changedDeps);
    }

    return effectCallback(changedDeps);
  }, [dependencies, previousDeps]);

  useEffect(effectWithDiff, dependencies);
};

export default useEffectDiff;
