"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateParentDimensions = exports.getCurrentParentDimensions = exports.getOutputNodePosition = exports.getNewChildPosition = exports.PARENT_HORIZONTAL_PADDING = exports.PARENT_VERTICAL_START = exports.PARENT_VERTICAL_SPACING = exports.PARENT_TITLE_HEIGHT_PX = exports.ExpectedSizes = exports.createEdgeBetween = exports.generateDoubleId = exports.getTypedElementId = exports.getTypedId = void 0;
const Elements_1 = require("../Database/Models/Settings/ProcessFlow/Elements");
/** Get node id in format NodeType_RandomizedId */
const getTypedId = (node) => `${node.type}_${node.id}`;
exports.getTypedId = getTypedId;
const getTypedElementId = (id, type) => `${type}_${id}`;
exports.getTypedElementId = getTypedElementId;
const generateId = () => Math.random().toString(36).slice(2);
const generateDoubleId = () => generateId().concat(generateId());
exports.generateDoubleId = generateDoubleId;
const createEdgeBetween = (sourceNodeId, targetNodeId, sourceHandle = `${Elements_1.HandleType.SINGLE}_0`, targetHandle = `${Elements_1.HandleType.MULTIPLE}_0`) => {
    return {
        source: sourceNodeId,
        target: targetNodeId,
        sourceHandle,
        targetHandle
    };
};
exports.createEdgeBetween = createEdgeBetween;
/** Used both in front end sizing and backend migration scripts */
exports.ExpectedSizes = {
    [Elements_1.NodeType.Start]: { width: 118, height: 44 },
    [Elements_1.NodeType.LabelDesign]: { width: 174, height: 77 },
    [Elements_1.NodeType.ManualInputField]: { width: 152, height: 77 },
    [Elements_1.NodeType.TakePicture]: { width: 156, height: 77 },
    [Elements_1.NodeType.AutoFillData]: { width: 156, height: 44 },
    [Elements_1.NodeType.Conditional]: { width: 156, height: 48 },
    [Elements_1.NodeType.LogicalBlock]: { width: 174, height: 140 },
    [Elements_1.NodeType.CustomMarker]: { width: 203, height: 140 },
    [Elements_1.NodeType.End]: { width: 118, height: 44 }
};
/****************************************
 **         PARENT NODE DATA           **
 **                                    **
 **  These are used both on front      **
 **  end, defining widget positioning  **
 **  and back end settings migration   **
 ****************************************/
exports.PARENT_TITLE_HEIGHT_PX = 24;
exports.PARENT_VERTICAL_SPACING = 16;
exports.PARENT_VERTICAL_START = exports.PARENT_TITLE_HEIGHT_PX + exports.PARENT_VERTICAL_SPACING;
exports.PARENT_HORIZONTAL_PADDING = 24;
const getNewChildPosition = (newNodeSize, index, parentWidth, outputSiblingNode) => {
    const sibWidth = outputSiblingNode?.measured?.width ?? outputSiblingNode?.width;
    const newNodeWidth = newNodeSize?.measured?.width ?? newNodeSize.width;
    const newNodeHeight = newNodeSize?.measured?.height ?? newNodeSize.height;
    const contentParentWidth = parentWidth - (outputSiblingNode ? 2 * exports.PARENT_HORIZONTAL_PADDING + (sibWidth ?? 130) / 3 : 0);
    return {
        x: (contentParentWidth - (newNodeWidth ?? 190)) / 2,
        y: exports.PARENT_VERTICAL_START + index * ((newNodeHeight ?? 77) + exports.PARENT_VERTICAL_SPACING)
    };
};
exports.getNewChildPosition = getNewChildPosition;
const getOutputNodePosition = (node, parentWidth, parentHeight) => {
    const nodeWidth = node.measured?.width ?? node.width;
    const nodeHeight = node.measured?.height ?? node.height;
    const pos = {
        x: parentWidth - (nodeWidth ?? 240) / 3,
        y: (parentHeight + exports.PARENT_TITLE_HEIGHT_PX - (nodeHeight ?? 100)) / 2
    };
    return pos;
};
exports.getOutputNodePosition = getOutputNodePosition;
const calculateParentWidth = (contentChildren, outputChild) => {
    let width = 0;
    contentChildren.forEach((n) => {
        width = Math.max(n.measured?.width ?? n.width ?? 100, width);
    });
    // If there's an output node, add some padding as space for new edges
    if (outputChild) {
        const outChildWidth = outputChild.measured?.width ?? outputChild.width;
        width += 1 * exports.PARENT_HORIZONTAL_PADDING + (outChildWidth ?? 100) / 3;
    }
    width += 2 * exports.PARENT_HORIZONTAL_PADDING;
    return width;
};
const calculateParentHeight = (contentChildren, _outputChild) => {
    let height = exports.PARENT_VERTICAL_START;
    contentChildren.forEach((n) => {
        height += (n.measured?.height ?? n.height ?? 30) + exports.PARENT_VERTICAL_SPACING;
    });
    return height;
};
const getCurrentParentDimensions = (parentRef) => {
    if (parentRef?.current?.clientWidth && parentRef.current.clientHeight) {
        return { width: parentRef?.current?.clientWidth, height: parentRef.current.clientHeight };
    }
    return undefined;
};
exports.getCurrentParentDimensions = getCurrentParentDimensions;
const calculateParentDimensions = (contentChildren, outputChild) => {
    if (!contentChildren.length)
        return undefined;
    return {
        width: calculateParentWidth(contentChildren, outputChild),
        height: calculateParentHeight(contentChildren, outputChild)
    };
};
exports.calculateParentDimensions = calculateParentDimensions;
