import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, Firestore, initializeFirestore } from 'firebase/firestore';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import environment, { Environment, getDevConfig } from 'src/environment';
import { FirebaseConfig, productionConfig, stagingConfig } from '../util/config';

function getFirebaseConfig(env: Environment) {
  let config: FirebaseConfig = productionConfig;
  if (!env.isProduction) {
    if (!env.isDevelopment) {
      // Using staging project
      config = stagingConfig;
    } else {
      // Using development project
      config = getDevConfig();
    }
  }
  return config;
}

const config = getFirebaseConfig(environment);
// Initialize firebase instance
const firebaseApp: FirebaseApp = initializeApp(config);
const auth: Auth = getAuth(firebaseApp);
const firestore: Firestore = initializeFirestore(firebaseApp, {
  experimentalForceLongPolling: true
});
const defaultBucket: FirebaseStorage = getStorage(firebaseApp);
const profilePicsBucket: FirebaseStorage = getStorage(firebaseApp, config.profilePicsBucket);

if (environment.usingEmulators) {
  connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
}

// Get firebase references for store
const initFirebase = () => ({ firebaseApp, auth, firestore, defaultBucket, profilePicsBucket });

export { auth, defaultBucket, firebaseApp, firestore, initFirebase, profilePicsBucket };

