import FlagIcon from '@mui/icons-material/Flag';
import SearchIcon from '@mui/icons-material/Search';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import { Box, IconButton } from '@mui/material';
import { AcquisitionDataFlags, ResultFields, StandardFlags } from 'flyid-core/dist/Database/Models';
import { getSessionDoc } from 'flyid-core/dist/Util/database';
import { getStoredOrDefault } from 'flyid-core/dist/Util/web';
import { useMemo, useState } from 'react';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';
import { buildDocumentRef } from 'src/firebase/firestore';
import { useAppSelector } from 'src/hooks/reduxHooks';
import { selectSettings } from 'src/redux/selectors/dataSelectors';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import { selectCurrentUserProfile } from 'src/redux/selectors/userSelectors';
import { appMakeStyles } from 'src/theme/theme';
import { typedKeysOf } from 'src/util/helpers/other';
import {
  HeaderCellObject,
  PICTURES_COLUMN,
  STANDARD_FLAGS_COLUMN,
  TableRow
} from 'src/util/helpers/table';
import { useSessionDataParser } from 'src/workers/dataWorkerApi';
import LoadingCircle from '../widgets/LoadingCircle';
import AccuracyReportTable from './AccuracyReportTable';
import DivergencyTable from './DivergencyTable';
import { isTranslatableError } from 'flyid-core/dist/Util/exceptions';

type BarcodeFieldsToCheck = { field: string; checkField: string; isRequired: boolean }[];

const showInputDataStorageKey = 'sessionShowInputData';
const showBaseFieldsStorageKey = 'sessionShowBaseFields';

const useStyles = appMakeStyles((theme) => ({
  container: { ...theme.resizableContainer(2), marginLeft: 0 },
  tableContainer: {
    marginBottom: '20px'
  }
}));

const AccuracyReport: React.FC = () => {
  const classes = useStyles();
  const intl = useIntl();
  const $t = intl.$t;

  const { domain, session } = useParams<SessionMatchParams>();
  // Fallback to home if domain or session is missing
  if (!domain || !session) return <Navigate replace to="/" />;

  const { profile, settings, locale, company } = useAppSelector((s) => ({
    company: selectTargetCompany(s),
    profile: selectCurrentUserProfile(s),
    settings: selectSettings(s, domain),
    locale: s.locale.locale
  }));

  const [sessionData, loadingSessionData] = useDocumentDataOnce(
    profile && company ? buildDocumentRef(getSessionDoc(company, domain, session)) : undefined
  );

  const wasTask = (sessionData && sessionData.wasTask) || false;

  const [showInputData] = useState(wasTask && getStoredOrDefault(showInputDataStorageKey, false));

  const [showBaseFields] = useState(getStoredOrDefault(showBaseFieldsStorageKey, false));

  const { rowsAndColumnsOrError: parsedDataOrError } = useSessionDataParser(
    sessionData,
    settings,
    showInputData,
    showBaseFields
  );

  const rowsAndColumnsOrError = useMemo(() => {
    if (isTranslatableError(parsedDataOrError) || !parsedDataOrError)
      return { columns: [], rows: [] };

    const picturesColumn: HeaderCellObject = {
      id: PICTURES_COLUMN,
      tooltip: $t({ id: 'session.associatedPictures' }),
      export: false,
      icon: {
        iconElement: WallpaperIcon,
        props: {
          fontSize: 'large'
        }
      },
      headerCellProps: {
        padding: 'none',
        align: 'center'
      },
      dataCellProps: {
        align: 'center'
      },
      dataContentGetter: (row: TableRow) =>
        !!row[AcquisitionDataFlags.HasPictures] ? (
          <IconButton aria-label="viewImage" size="large">
            <SearchIcon fontSize="medium" />
          </IconButton>
        ) : null
    };
    const flagsColumn: HeaderCellObject = {
      id: STANDARD_FLAGS_COLUMN,
      export: true,
      tooltip: $t({ id: 'session.acquisitionFlags' }),
      icon: {
        iconElement: FlagIcon,
        props: {
          fontSize: 'large'
        }
      },
      headerCellProps: {
        padding: 'none',
        align: 'center'
      },
      dataCellProps: {
        align: 'center'
      },
      dataContentGetter: (row: TableRow) =>
        Object.entries(StandardFlags)
          .map(([resultKey, dataFlagKey]) =>
            row[dataFlagKey]
              ? (settings?.fieldSettings.resultFields[resultKey as keyof ResultFields] ?? resultKey)
              : null
          )
          // There should never be more than one standard flag in a row, but treat it by precaution
          .filter(Boolean)
          .join('\n') ?? null
    };

    return {
      columns: [picturesColumn, flagsColumn, ...parsedDataOrError.columns],
      rows: parsedDataOrError.rows
    };
  }, [parsedDataOrError, locale]);

  const barcodeFieldsToCheck: BarcodeFieldsToCheck = [];

  // Getting barcode fields that need to be check
  if (settings?.processFlow.labelDesigns) {
    Object.values(settings?.processFlow.labelDesigns).forEach((ld) => {
      const dataFields = ld.barcodePatterns[0].dataFields;
      dataFields
        .filter((df) => Boolean(df.useCheckField))
        .forEach((df) => {
          barcodeFieldsToCheck.push({
            field: df.checkField,
            checkField: df.name,
            isRequired: ld.barcodePatterns[0].isRequired
          });
        });
    });
  }

  // Getting manual input fields that need to be check
  if (settings?.processFlow.manualInputFields) {
    Object.values(settings?.processFlow.manualInputFields)
      .filter((mifs) => Boolean(mifs.useCheckField))
      .forEach((mif) => {
        barcodeFieldsToCheck.push({
          field: mif.checkField,
          checkField: mif.field,
          isRequired: mif.mandatory
        });
      });
  }

  // Filtering barcode fileds to check
  const filteredData = useMemo(() => {
    const filteredArray: BarcodeFieldsToCheck = [];
    const checkArray: string[] = [];

    //eslint-disable-next-line
    barcodeFieldsToCheck?.map((bfc) => {
      if (checkArray.length && filteredArray.length) {
        if (!checkArray.some((v) => v === bfc.field)) {
          checkArray.push(bfc.field);
          filteredArray.push(bfc);
        } else {
          if (bfc.isRequired === true) {
            filteredArray.splice(filteredArray.length - 1, 1, bfc);
          }
        }
      } else {
        checkArray.push(bfc.field);
        filteredArray.push(bfc);
      }
    });

    return filteredArray;
  }, [barcodeFieldsToCheck]);

  //eslint-disable-next-line
  const id = settings?.fieldSettings.baseFields.address;

  const { accuracyData, divergentData } = useMemo(() => {
    const divergentData: TableRow[] = [];

    //Datable Columns and Rows
    const columnDataRows = [
      [
        $t({ id: 'report.convergent' }),
        $t({ id: 'report.divergent' }),
        $t({ id: 'report.empty' }),
        $t({ id: 'report.invalid' }),
        $t({ id: 'report.accuracy' })
      ]
    ];
    const columnDataColumns: string[] = [];
    const positionDataRows: string[] = [];
    const positionDataColumns = [
      $t({ id: 'report.convergent' }),
      $t({ id: 'report.divergent' }),
      $t({ id: 'report.invalid' }),
      $t({ id: 'report.total' }),
      $t({ id: 'report.accuracy' })
    ];

    //Per Columns Related
    if (filteredData.length) {
      columnDataColumns.push('');

      filteredData.map((fields) => {
        let convergentColumns = 0;
        let divergentColumns = 0;
        let invalidColumns = 0;
        let emptyColumns = 0;

        columnDataColumns.push(fields.field);

        rowsAndColumnsOrError?.rows.map((row) => {
          switch (row[fields.checkField]) {
            case row[fields.field]:
              convergentColumns += 1;
              break;
            case settings?.fieldSettings.resultFields.empty:
            case settings?.fieldSettings.resultFields.null:
              convergentColumns += 1;
              emptyColumns += 1;
              break;
            case settings?.fieldSettings.resultFields.invalid:
              invalidColumns += 1;
              break;
            default:
              divergentColumns += 1;
              divergentData.push(row);
          }
        });

        columnDataRows.push([
          `${convergentColumns}`,
          `${divergentColumns}`,
          `${emptyColumns}`,
          `${invalidColumns}`,
          //eslint-disable-next-line
          `${((convergentColumns * 100) / rowsAndColumnsOrError?.rows.length).toFixed(2)}%`
        ]);
      });

      //Per Position Related
      const fieldState = {
        INVALID: 'inválido',
        CONVERGENT: 'convergente',
        DIVERGENT: 'divergente'
      };

      const positionsCount = {
        convergentPositions: 0,
        divergentPositions: 0,
        invalidPositions: 0,
        totalPositions: 0,
        positionsAccuracy: ''
      };

      //eslint-disable-next-line
      rowsAndColumnsOrError?.rows.map((row) => {
        positionsCount.totalPositions += 1;

        const rowData = filteredData.map((fields) => {
          if (
            row[fields.checkField] === row[fields.field] ||
            row[fields.checkField] === settings?.fieldSettings.resultFields.empty ||
            row[fields.checkField] === settings?.fieldSettings.resultFields.null
          ) {
            return fieldState.CONVERGENT;
          } else if (row[fields.checkField] === settings?.fieldSettings.resultFields.invalid) {
            return fieldState.INVALID;
          } else {
            return fieldState.DIVERGENT;
          }
        });

        if (rowData[0] === fieldState.DIVERGENT || rowData[1] === fieldState.DIVERGENT) {
          positionsCount.divergentPositions += 1;
        } else if (rowData[0] === fieldState.INVALID || rowData[1] === fieldState.INVALID) {
          positionsCount.invalidPositions += 1;
        } else {
          positionsCount.convergentPositions += 1;
        }
      });

      positionsCount.positionsAccuracy = `${(
        (positionsCount.convergentPositions * 100) /
        positionsCount.totalPositions
      ).toFixed(2)}%`;

      typedKeysOf(positionsCount).forEach((key) => {
        positionDataRows.push(String(positionsCount[key]));
      });
    }

    return {
      accuracyData: { columnDataRows, columnDataColumns, positionDataRows, positionDataColumns },
      divergentData
    };
  }, [filteredData]);

  return (
    <Box className={classes.container}>
      {loadingSessionData ? (
        <LoadingCircle />
      ) : (
        <Box>
          <Box className={classes.tableContainer}>
            <AccuracyReportTable
              title={$t({ id: 'report.accuracyReport' })}
              subtitle={$t({ id: 'report.positionStatistic' })}
              data={accuracyData}
              type={1}
            />
          </Box>
          <Box className={classes.tableContainer}>
            <AccuracyReportTable
              subtitle={$t({ id: 'report.columnStatistic' })}
              data={accuracyData}
              type={0}
            />
          </Box>
          {divergentData.length ? (
            <Box>
              <DivergencyTable
                title={$t({ id: 'report.divergentPositions' })}
                data={{
                  divergentRows: divergentData,
                  checkFields: filteredData,
                  idField: id ? id : ''
                }}
              />
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default AccuracyReport;
