"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Rotation = void 0;
class Rotation {
    constructor(state) {
        this.state = state ?? Rotation.State.NONE;
    }
    rotateClockwise(times = 1) {
        this.state = (this.state + times) % Rotation.StateSize;
    }
    rotateCounterClockwise(times = 1) {
        this.state = (this.state - times) % Rotation.StateSize;
        if (this.state < 0)
            this.state = Rotation.StateSize + this.state;
    }
    reset() {
        this.state = Rotation.State.NONE;
    }
}
exports.Rotation = Rotation;
// eslint-disable-next-line @typescript-eslint/no-namespace, no-redeclare
(function (Rotation) {
    let State;
    (function (State) {
        State[State["NONE"] = 0] = "NONE";
        State[State["ONE_QUARTER_CW"] = 1] = "ONE_QUARTER_CW";
        State[State["TWO_QUARTERS_CW"] = 2] = "TWO_QUARTERS_CW";
        State[State["THREE_QUARTERS_CW"] = 3] = "THREE_QUARTERS_CW";
    })(State = Rotation.State || (Rotation.State = {}));
    Rotation.StateSize = Object.keys(Rotation.State).filter((e) => !isNaN(parseInt(e))).length;
})(Rotation || (exports.Rotation = Rotation = {}));
