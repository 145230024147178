"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.toTranslatableError = exports.isTranslatableError = exports.TranslatableError = void 0;
class TranslatableError extends Error {
    constructor(msgCode, args, err) {
        super(msgCode);
        this.msgCode = super.message;
        this.args = args;
        this.err = err;
    }
}
exports.TranslatableError = TranslatableError;
const isTranslatableError = (v) => typeof v?.msgCode === 'string';
exports.isTranslatableError = isTranslatableError;
const toTranslatableError = (err) => (0, exports.isTranslatableError)(err)
    ? err
    : new TranslatableError('unkownErrorMsg', { message: err.message }, err);
exports.toTranslatableError = toTranslatableError;
