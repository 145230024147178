import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Button,
  Container,
  Fab,
  IconButton,
  lighten,
  MenuItem,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import {
  AutoFillData,
  AutofillSourceTypes,
  isAutoFillDataComplete
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/AutoFillData';
import {
  AddressesMapKey,
  TabularData
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/TabularData';
import React, { ChangeEvent, FormEvent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import useStateReducer from 'src/hooks/useStateReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';

const useStyles = appMakeStyles(({ spacing, palette, other }) => ({
  root: { padding: spacing(1) },
  gridContainer: {
    minWidth: '500px'
  },
  title: {
    color: other.grey.dark,
    marginBottom: spacing(2)
  },
  header: {
    color: other.grey.dark
  },
  fieldModifierTop: {
    backgroundColor: lighten(palette.primary.dark, 0.85),
    borderRadius: '15px 15px 0 0'
  },
  fieldItemLight: {
    backgroundColor: lighten(palette.primary.dark, 0.96)
  },
  fieldItemDark: {
    backgroundColor: lighten(palette.primary.dark, 0.93)
  },
  fieldModifier: {
    backgroundColor: lighten(palette.primary.dark, 0.85)
  },
  fieldModifierBottom: {
    backgroundColor: lighten(palette.primary.dark, 0.85),
    borderRadius: '0 0 15px 15px',
    padding: spacing(1)
  },
  addFieldGridItem: {
    paddingLeft: spacing(1),
    minHeight: spacing(6)
  }
}));

type OptionData = {
  sourceType: AutofillSourceTypes;
  tableName: string;
  keyColumn: string;
  valueColumn: string;
};

class State extends AutoFillData {
  newAutoFillData: AutoFillData = new AutoFillData();
}

type Props = {
  autoFillableFields: string[];
  autoFillData: AutoFillData[];
  tabularData: TabularData;
  extraTaskFields: string[];
  onAutoFillDataChange: (afd: AutoFillData[]) => void;
  handleSubmit: (e: FormEvent) => void;
};

const AutoFillDataEditor: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { other, palette } = useAppTheme();
  const { $t } = useIntl();

  const [state, setState] = useStateReducer(new State());

  const existingFields = props.autoFillData.map((afd) => afd.target);
  const selectableFields = props.autoFillableFields.filter((f) => !existingFields.includes(f));
  const hasSomeAutoFillData = Boolean(existingFields.length);

  const optionDataPerId = useMemo(() => {
    const options = Object.entries(props.tabularData.dataTables)
      .filter(([tableName]) => tableName !== AddressesMapKey)
      .reduce(
        (obj, [tableName, tableData]) => {
          tableData.valueColumns.forEach((valueColumn) => {
            obj[`${tableName}: ${valueColumn}`] = {
              sourceType: AutofillSourceTypes.TABLE,
              tableName,
              valueColumn,
              keyColumn: tableData.keyColumn
            };
          });
          return obj;
        },
        {} as { [id: string]: OptionData }
      );
    props.extraTaskFields.forEach((field) => {
      options[`taskInput: ${field}`] = {
        sourceType: AutofillSourceTypes.TASK_INPUT,
        valueColumn: field
      } as OptionData;
    });
    return options;
  }, [props.extraTaskFields, props.tabularData.dataTables]);

  const handleTargetSelectionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const autoFillData = { ...state.newAutoFillData };
    autoFillData[e.target.name] = e.target.value;
    setState({ newAutoFillData: autoFillData });
  };

  const handleOutputColumnChange = (id: string) => {
    const autoFillData = { ...state.newAutoFillData };
    const optionData = optionDataPerId[id];

    const isSourceTable = optionData.sourceType === AutofillSourceTypes.TABLE;
    autoFillData.sourceType = optionData.sourceType;
    autoFillData.tableName = isSourceTable ? optionData.tableName : '';
    autoFillData.inputColumn = isSourceTable ? optionData.keyColumn : '';
    autoFillData.outputColumn = optionData.valueColumn;

    setState({
      outputColumn: id,
      inputColumn: `${optionData.tableName}: ${optionData.keyColumn}`,
      newAutoFillData: autoFillData
    });
  };

  const handleAddCondition = () => {
    if (isAutoFillDataComplete(state.newAutoFillData))
      props.onAutoFillDataChange([...props.autoFillData, state.newAutoFillData]);
    setState(new State());
  };

  const handleRemove = (index) => {
    props.onAutoFillDataChange(props.autoFillData.filter((_, idx) => idx !== index));
  };

  const onSubmit = (e: FormEvent) => {
    props.handleSubmit(e);
  };

  const renderExistingFieldsHeader = () => (
    <Grid xs="auto" container className={classes.fieldModifierTop} alignItems="center">
      <Grid xs={1} className={classes.header}>
        <Typography align="center">#</Typography>
      </Grid>
      <Grid xs className={classes.header}>
        <Typography>{$t({ id: 'autoFillDataEditor.fillAction' })}</Typography>
      </Grid>
      <Grid xs />
    </Grid>
  );

  const renderExisting = () =>
    props.autoFillData.map((afd, index) => {
      return (
        <Grid
          key={`afd${index}`}
          xs="auto"
          container
          alignItems="center"
          className={index % 2 === 0 ? classes.fieldItemDark : classes.fieldItemLight}
          flexWrap="nowrap"
        >
          <Grid xs={1} p={1}>
            <Typography align="center">{index + 1}</Typography>
          </Grid>
          <Grid xs p={1}>
            <Typography>
              {$t(
                { id: 'autoFillDataEditor.autoFillDataDescription' },
                {
                  target: <b>{afd.target}</b>,
                  inCol: <b>{afd.inputColumn}</b>,
                  outCol: <b>{afd.outputColumn}</b>
                }
              )}
            </Typography>
          </Grid>
          <Grid xs="auto" sx={{ m: 1 }}>
            <Tooltip title={$t({ id: 'remove' })}>
              <IconButton
                edge="end"
                aria-label={$t({ id: 'remove' })}
                sx={{ color: 'error.main', m: 0, p: 0 }}
                onClick={() => handleRemove(index)}
                size="large"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      );
    });

  const renderAddCase = () => (
    <>
      {/* Add case title */}
      <Grid
        xs="auto"
        container
        className={hasSomeAutoFillData ? classes.fieldModifier : classes.fieldModifierTop}
      >
        <Grid xs px={1}>
          <Typography
            variant="subtitle1"
            className={classes.addFieldGridItem}
            sx={{
              color: other.grey.dark,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {$t({ id: 'caseEditor.addNewCases' })}
          </Typography>
        </Grid>
      </Grid>

      {/* Add case widget */}
      <Grid container xs={12} className={classes.fieldModifierBottom} alignItems="center">
        {selectableFields.length && Object.values(optionDataPerId).length ? (
          <>
            <Grid xs="auto" px={1}>
              <Typography>{$t({ id: 'autoFillDataEditor.fill' })}</Typography>
            </Grid>
            <Grid xs="auto" pr={1}>
              <TextField
                fullWidth
                select
                variant="standard"
                name="target"
                id="target"
                value={state.newAutoFillData.target || ''}
                onChange={handleTargetSelectionChange}
              >
                {selectableFields.map((name, i) => (
                  <MenuItem value={name} key={`item${i}`}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid xs="auto" pr={1}>
              <Typography>{$t({ id: 'autoFillDataEditor.with' })}</Typography>
            </Grid>
            <Grid xs="auto" pr={1}>
              <TextField
                fullWidth
                select
                variant="standard"
                name="outputColumn"
                id="outputColumn"
                value={state.outputColumn}
                onChange={(e) => handleOutputColumnChange(e.target.value)}
              >
                {Object.entries(optionDataPerId).map(([id], i) => (
                  <MenuItem value={id} key={`itac${i}`}>
                    {id}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {state.inputColumn &&
            state.outputColumn &&
            optionDataPerId[state.outputColumn].sourceType === AutofillSourceTypes.TABLE ? (
              <>
                <Grid xs="auto" pr={1}>
                  <Typography>{$t({ id: 'autoFillDataEditor.given' })}</Typography>
                </Grid>
                <Grid xs="auto" pr={1}>
                  <Typography>
                    <b>{state.inputColumn || '...'}</b>
                  </Typography>
                </Grid>
              </>
            ) : null}
          </>
        ) : (
          <Grid xs>
            <Typography color={palette.error.main}>
              {$t({
                id: selectableFields.length
                  ? 'autoFillEditor.missingFillableFields'
                  : 'autoFillDataEditor.noTabularDataAvailable'
              })}
            </Typography>
          </Grid>
        )}

        {/* Add button */}
        <Grid xs />
        <Grid xs="auto" sx={{ textAlign: 'center', m: 1 }}>
          <Tooltip title={$t({ id: 'add' })}>
            <span>
              <Fab
                onClick={handleAddCondition}
                size="small"
                color="secondary"
                aria-label={$t({ id: 'add' })}
                disabled={!isAutoFillDataComplete(state.newAutoFillData)}
              >
                <AddIcon fontSize="large" />
              </Fab>
            </span>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );

  return (
    <Container className={classes.root}>
      <form onSubmit={onSubmit} className={classes.gridContainer}>
        <Grid container direction="column" spacing={1}>
          {/* Existing fields */}
          {hasSomeAutoFillData ? (
            <>
              {/* Field Descriptions */}
              {renderExistingFieldsHeader()}
              {/* Field Items */}
              {renderExisting()}
            </>
          ) : (
            /* If there are no fields added yet */
            <Grid xs={12} sx={{ mb: 1 }}>
              <Typography variant="body1">
                {$t({ id: 'autoFillDataEditor.noActionsCreated' })}
              </Typography>
            </Grid>
          )}

          {/* Adding new fields */}
          {renderAddCase()}
        </Grid>

        {/* Save button */}
        <Grid container sx={{ mt: 2 }}>
          <Grid xs />
          <Grid xs="auto">
            <Button variant="contained" color="secondary" type="submit">
              {$t({ id: 'saveChanges' })}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AutoFillDataEditor;
