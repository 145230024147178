import React from 'react';
import { Icon, Tooltip, Typography } from '@mui/material';
import clsx from 'clsx';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';

const useStyles = appMakeStyles(({ spacing }) => ({
  iconRoot: {
    padding: spacing(1.5),
    borderRadius: spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    width: spacing(2),
    height: spacing(2)
  }
}));

type Props = {
  tooltip?: string;
  iconRootClass?: string;
  logo?: string;
};

const LogoTooltip: React.FC<Props> = (props) => {
  const theme = useAppTheme();
  const classes = useStyles();

  return (
    <Tooltip
      disableTouchListener
      style={{ marginLeft: theme.spacing(1), alignSelf: 'center' }}
      title={props.tooltip && <Typography variant="subtitle2">{props.tooltip}</Typography>}
    >
      <Icon classes={{ root: clsx(classes.iconRoot, props.iconRootClass) }}>
        <img className={classes.logo} src={props.logo} alt={props.tooltip} />
      </Icon>
    </Tooltip>
  );
};

export default LogoTooltip;
