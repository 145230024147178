import { cx } from '@emotion/css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CropFreeIcon from '@mui/icons-material/CropFree';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import {
  alpha,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { ref, UploadMetadata, uploadString } from 'firebase/storage';
import { BarcodePattern } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/BarcodePattern';
import { getPureId } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { LabelDesign } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/LabelDesign';
import { getLabelImageBackupPath } from 'flyid-core/dist/Util/database';
import { decodeText } from 'flyid-core/dist/Util/web';
import { useOnceEffect, useWindowDimensions } from 'flyid-ui-components/dist/hooks';
import ConditionalWrapper from 'flyid-ui-components/dist/utils/ConditionalWrapper';
import { cloneDeep, isEmpty } from 'lodash';
import React, { ChangeEvent, FormEvent, useEffect, useMemo } from 'react';
import { MessageDescriptor, useIntl } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';
import uploadImage from 'src/assets/images/uploadImage.jpg';
import LoadingCircle from 'src/components/widgets/LoadingCircle';
import { defaultBucket } from 'src/firebase/firebase';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { fetchLabelImage } from 'src/redux/actions/managementActions';
import { updateLabelImageState } from 'src/redux/reducers/labelImagesReducer';
import { updateUi } from 'src/redux/reducers/uiReducer';
import { labelImageSelector } from 'src/redux/selectors/dataSelectors';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import { selectCurrentUserProfile } from 'src/redux/selectors/userSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { limitDimenKeepRatio, Rect, vertexToRect } from 'src/util/helpers/geometry';
import { EditorProps } from 'src/util/processFlow/types';
import { urlDataReader } from 'src/workers/fileWorkerApi';
import BarcodePatternEditor from './BarcodePatternEditor';
import LabelDesignStage, { Modes } from './LabelDesignStage';

const useStyles = appMakeStyles(({ spacing, palette, resizableContainer }) => ({
  container: { ...resizableContainer(1), marginLeft: 0 },
  title: {
    marginBottom: spacing(2),
    maxWidth: spacing(75)
  },
  button: {
    margin: spacing(1, 0, 1, 0),
    alignSelf: 'flex-start'
  },
  input: {
    marginBottom: spacing(2)
  },
  linearHorizontal: {
    display: 'flex',
    flexDirection: 'row'
  },
  linearVertical: {
    display: 'flex',
    flexDirection: 'column'
  },
  spaceEvenly: { justifyContent: 'space-evenly' },
  center: { justifyContent: 'center' },
  redButton: {
    color: palette.error.main,
    borderColor: palette.error.main
  },
  blueButton: {
    color: palette.info.main,
    borderColor: palette.info.main
  },
  superImposed: {
    position: 'absolute',
    left: 0,
    top: 0
  },
  tooltip: {
    alignSelf: 'center'
  }
}));

class StageData {
  label: { x: number; y: number; width: number; height: number } = {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  };
  barcodes: Rect[] = [];
  selectedIndex = -1;
  name = '';
  nameError?: string = undefined;
  scaleX = 1;
  scaleY = 1;
}

type State = StageData & {
  mode: Modes;
  loadedNewImage: boolean;
  barcodePatterns: BarcodePattern[];
  // Used to calculate label image's natural width and height
  labelImage?: HTMLImageElement;
  // Used to size label image respecting screen limits while keeping W/H ratio
  limitedLabelWidth: number;
  limitedLabelHeight: number;
  mayRepeatInSession: boolean;
  isBackup: boolean;
  multiplePerPosition: boolean;
};

const initialMaxImageWidth = 800;
const initialMaxImageHeight = 600;
const getMaxImageDimen = (winWidth: number, winHeight: number) => {
  const maxWidth = winWidth > initialMaxImageWidth + 400 ? initialMaxImageWidth : winWidth - 400;
  const maxHeight =
    winWidth > initialMaxImageHeight + 300 ? initialMaxImageHeight : winHeight - 300;
  return [maxWidth, maxHeight];
};

const getInitState = (props: EditorProps<LabelDesign>) => {
  const labelDesign: LabelDesign = props.data.name ? props.data : new LabelDesign();

  const barcodePatterns = cloneDeep(labelDesign.barcodePatterns);

  const barcodes = barcodePatterns.map((bp) => vertexToRect(bp.firstVertex, bp.secondVertex));

  const stageData = new StageData();
  stageData.barcodes = barcodes;
  stageData.name = labelDesign.name;
  stageData.label = vertexToRect(labelDesign.firstVertex, labelDesign.secondVertex);

  const state: State = {
    mode: Modes.NONE,
    loadedNewImage: props.data.copiedFromId ? true : false,
    multiplePerPosition: labelDesign.multiplePerPosition,
    mayRepeatInSession: labelDesign.mayRepeatInSession,
    barcodePatterns,
    ...stageData,
    labelImage: undefined,
    limitedLabelWidth: 0,
    limitedLabelHeight: 0,
    isBackup: false
  };
  return state;
};

/**
 * Describes possible label states
 */
enum LabelState {
  /** Still loading picture data, we don't know about picture sources yet. */
  LOADING,
  /** Loaded picture locally, the user just uploaded it to the browser. */
  LOCAL_PICTURE_READY,
  /** Loaded picture from server, it may be backup or final picture. */
  CLOUD_PICTURE_READY,
  /** Picture is done loading and does not exist. Show placeholder. */
  NO_PICTURE
}

const LabelDesignEditor: React.FC<EditorProps<LabelDesign>> = (props) => {
  const [state, setState] = useStateReducer<State>(getInitState(props));
  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const [maxImageWidth, maxImageHeight] = getMaxImageDimen(windowWidth, windowHeight);

  const dispatch = useAppDispatch();
  const { $t } = useIntl();
  const classes = useStyles();
  const { spacing, other } = useAppTheme();

  const { domain } = useParams<DomainMatchParams>();
  // Fallback to home if domain is missing
  if (!domain) return <Navigate replace to="/" />;

  const copiedFromId = props.data.copiedFromId;
  const { globalLabelState, profile, labelId, nodeId, company } = useAppSelector((appState) => {
    const nodeId = getPureId(props.nodeId);
    // If node has backup picture, use the backup (original node id).
    // Otherwise, if is a copy from another node, use it's label.
    // Otherwise, just look for the node's picture.
    const labelId = props.data.isBackup ? nodeId : (copiedFromId ?? nodeId);
    return {
      company: selectTargetCompany(appState),
      globalLabelState: labelImageSelector(domain, labelId, appState),
      profile: selectCurrentUserProfile(appState),
      labelId,
      nodeId,
      copiedFromId
    };
  });

  const isEditPatternMode = state.mode === Modes.PATTERN;

  // Simplified label state
  const [labelState, labelSrc] = useMemo(
    () =>
      globalLabelState?.isLoaded
        ? globalLabelState?.localSrc
          ? [LabelState.LOCAL_PICTURE_READY, globalLabelState?.localSrc]
          : globalLabelState?.src
            ? [LabelState.CLOUD_PICTURE_READY, globalLabelState?.src]
            : [LabelState.NO_PICTURE, undefined]
        : [LabelState.LOADING, undefined],

    [globalLabelState, labelId, state.loadedNewImage]
  );
  const labelImageReadyToShow =
    labelState === LabelState.LOCAL_PICTURE_READY || labelState === LabelState.CLOUD_PICTURE_READY;

  // Recalculate image size whenever it changes or screen size changes
  useEffect(() => {
    if (labelSrc) {
      const isPattern = state.mode === Modes.PATTERN;
      const labelImage = new Image();
      labelImage.onload = () => {
        const { width: limitedLabelWidth, height: limitedLabelHeight } = limitDimenKeepRatio(
          labelImage.naturalWidth,
          labelImage.naturalHeight,
          isPattern ? 200 : maxImageWidth,
          isPattern ? 200 : maxImageHeight
        );

        setState({
          labelImage,
          limitedLabelWidth,
          limitedLabelHeight,
          scaleX: limitedLabelWidth / labelImage.naturalWidth,
          scaleY: limitedLabelHeight / labelImage.naturalHeight
        });
      };
      labelImage.src = labelSrc;
    }
  }, [labelSrc, state.loadedNewImage, maxImageWidth, maxImageHeight, state.mode]);

  // Fetch label image, if not yet
  useOnceEffect(() => {
    if (
      !globalLabelState?.isTriggered &&
      !globalLabelState?.isLoaded &&
      profile &&
      labelId &&
      company
    ) {
      dispatch(fetchLabelImage({ company, domain, labelId }));
      return [true];
    }
    return [false];
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const errorField = /(name)/g.exec(e.target.name);
    const nextState: Partial<State> = {};

    if (errorField) {
      nextState[errorField[1].concat('Error')] = '';
    }

    nextState[e.target.name] = e.target.value;
    setState(nextState);
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files?.item(0);
    if (file) {
      urlDataReader(file, {
        onload: (result: string | ArrayBuffer | null) => {
          setState({
            // Used to send new image file to server only if a new image is uploaded by the user
            loadedNewImage: true,
            // Reset stage data and barcodes: can't expect the same patterns to fit a different image
            ...new StageData(),
            barcodePatterns: []
          });

          // Convert result to string
          const localSrc = result
            ? typeof result === 'string'
              ? result
              : decodeText(result)
            : undefined;

          dispatch(
            updateLabelImageState({
              domain,
              labelId: nodeId,
              labelState: {
                localSrc,
                isLoaded: true
              }
            })
          );
        }
      });
    }
  };

  const setMode = (mode: Modes) => {
    // Setting mode to 'pattern' requires resizing image to smaller stage
    const labelImage = state.labelImage;
    if (mode === Modes.PATTERN) {
      if (labelImage) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const { width, height } = limitDimenKeepRatio(
          labelImage.naturalWidth,
          labelImage.naturalHeight,
          200,
          200
        );

        setState({
          mode,
          scaleX: width / state.limitedLabelWidth,
          scaleY: height / state.limitedLabelHeight,
          limitedLabelWidth: width,
          limitedLabelHeight: height
        });
      }
      // Changing back from pattern, resize image back to bigger stage
    } else if (state.mode === Modes.PATTERN) {
      if (labelImage) {
        const { width: limitedLabelWidth, height: limitedLabelHeight } = limitDimenKeepRatio(
          labelImage.naturalWidth,
          labelImage.naturalHeight,
          maxImageWidth,
          maxImageHeight
        );

        setState({
          mode,
          limitedLabelWidth,
          limitedLabelHeight
        });
      }
    } else {
      // Just change mode
      setState({ mode: mode === state.mode ? Modes.NONE : mode });
    }
  };

  const saveBackupLabels = () => {
    if (globalLabelState && profile && company) {
      const { src, localSrc } = globalLabelState;

      if (!localSrc || src === localSrc) {
        console.log('Label image did not change');
      } else {
        const path = getLabelImageBackupPath(company, domain, nodeId);

        const imageTypeRegex = /^data:(image\/(png|jpeg|jpg)+);base64,/;
        const res = localSrc.match(imageTypeRegex);
        if (!res) {
          console.error('Unsupported image format');
          return;
        }

        const metadata = { contentType: res[1] };
        const base64Data = localSrc.replace(/^data:image\/\w+;base64,/, '');

        uploadString(ref(defaultBucket, path), base64Data, 'base64', metadata as UploadMetadata)
          .then(() => {
            console.log('Label design successfully saved');
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { label, name, loadedNewImage, multiplePerPosition, mayRepeatInSession } = state;
    if (!name) {
      alert('Missing name!');
      return;
    }
    const barcodes = cloneDeep(state.barcodes);
    const barcodePatterns = cloneDeep(state.barcodePatterns);

    // Sanity checks
    let err: MessageDescriptor | null = null;
    if (!barcodes || !barcodePatterns || barcodes.length < 1) {
      err = { id: 'errMissingBarcodes' };
    } else if (barcodePatterns.length !== barcodes.length) {
      err = { id: 'barcodesMustHavePattern' };
    }

    if (err) {
      dispatch(
        updateUi({
          snackbar: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            message: $t({ id: `labelDesign.${err.id!}` }),
            severity: 'error',
            show: true
          }
        })
      );
      return;
    }

    // Build database format data
    barcodes.forEach((barcode: Rect, index: number) => {
      barcodePatterns[index].firstVertex = {
        x: barcode.x,
        y: barcode.y
      };

      barcodePatterns[index].secondVertex = {
        x: barcode.x + barcode.width,
        y: barcode.y + barcode.height
      };
    });

    const data: LabelDesign & { imageFile?: string } = {
      name,
      firstVertex: { x: label.x, y: label.y },
      secondVertex: { x: label.x + label.width, y: label.y + label.height },
      barcodePatterns,
      mayRepeatInSession,
      multiplePerPosition,
      isBackup: loadedNewImage
    };

    props.onSave(data, saveBackupLabels);
  };

  const onLabelDesignCheckboxChange = (name: string) => (e: ChangeEvent<HTMLInputElement>) =>
    setState({ [name]: e.target.checked });

  const onBarcodePatternChange = (index: number, barcodePattern: BarcodePattern) => {
    // Change index barcode data
    const barcodePatterns = [...state.barcodePatterns];
    barcodePatterns[index] = barcodePattern;
    setState({ barcodePatterns });
  };

  const addBarcode = (barcode: Rect) => {
    const barcodes = [...state.barcodes, barcode];
    setState({ barcodes });
  };

  const remBarcode = (index: number) => {
    const barcodes = [...state.barcodes];
    barcodes.splice(index, 1);

    const barcodePatterns = [...state.barcodePatterns];
    barcodePatterns.splice(index, 1);
    setState({ barcodes, barcodePatterns, selectedIndex: -1 });
  };

  const setLabel = (label: Rect) => {
    const indexesToKeep: number[] = [];
    if (label) {
      const labelX = Math.min(label.x, label.x + label.width);
      const labelY = Math.min(label.y, label.y + label.height);
      const labelXMax = Math.abs(label.width) + labelX;
      const labelYMax = Math.abs(label.height) + labelY;

      state.barcodes.forEach((bc: Rect, index: number) => {
        const x = Math.min(bc.x, bc.x + bc.width);
        const y = Math.min(bc.y, bc.y + bc.height);
        const bcXMax = Math.abs(bc.width) + x;
        const bcYMax = Math.abs(bc.height) + y;

        if (x > labelX && y > labelY && bcXMax < labelXMax && bcYMax < labelYMax)
          indexesToKeep.push(index);
      });
    }

    setState({
      label,
      barcodes: state.barcodes.filter((_r, idx) => indexesToKeep.includes(idx)),
      barcodePatterns: state.barcodePatterns.filter((_bp, idx) => indexesToKeep.includes(idx))
    });
  };

  const renderFileButton = (marginTop: string) => (
    <ConditionalWrapper
      condition={!!copiedFromId}
      wrapper={(children) => (
        <Tooltip title={$t({ id: 'labelDesign.cannotChangeCopiedPic' })}>{children!}</Tooltip>
      )}
    >
      <Box className={classes.button} sx={{ marginTop }}>
        <Box
          component="input"
          accept="image/jpeg, image/png"
          sx={{ display: 'none' }}
          id="profile-image-btn"
          type={copiedFromId ? undefined : 'file'}
          onChange={handleFileChange}
        />
        <label htmlFor="profile-image-btn">
          <Button variant="contained" component="span" color="primary" disabled={!!copiedFromId}>
            <PublishRoundedIcon sx={{ mr: 1 }} />
            {$t({ id: 'labelDesign.uploadPicture' })}
          </Button>
        </label>
      </Box>
    </ConditionalWrapper>
  );

  const labelDesignStage =
    labelState === LabelState.LOADING ? (
      <Box className={cx(classes.linearVertical)} sx={{ justifyContent: 'flex-start' }}>
        <LoadingCircle />
      </Box>
    ) : labelState !== LabelState.NO_PICTURE ? (
      <Box className={classes.linearVertical}>
        {!isEditPatternMode && (
          <Grid container direction={'row'} justifyContent="flex-end">
            <Grid item xs={12} sx={{ pr: 1 }} justifySelf="end">
              <TextField
                error={!isEmpty(state.nameError)}
                name="name"
                id="name"
                type="text"
                fullWidth
                label={`${$t({ id: 'labelDesign.name' })} *`}
                value={state.name}
                onChange={handleChange}
                helperText={state.nameError}
              />
            </Grid>
            <Grid item xs={6} sx={{ py: 1, px: 1 }} justifySelf="end">
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={state.mayRepeatInSession}
                    onChange={onLabelDesignCheckboxChange('mayRepeatInSession')}
                  />
                }
                label={
                  <Typography variant="body2">
                    {$t({ id: 'processFlow.mayRepeatInSession' })}
                  </Typography>
                }
                labelPlacement="end"
              />
            </Grid>
            <Grid item xs={6} sx={{ py: 1, px: 1 }} justifySelf="end">
              <FormControlLabel
                control={
                  <Checkbox
                    color="secondary"
                    checked={state.multiplePerPosition}
                    onChange={onLabelDesignCheckboxChange('multiplePerPosition')}
                  />
                }
                label={
                  <Typography variant="body2">
                    {$t({ id: 'processFlow.multipleLabelsPerPosition' })}
                  </Typography>
                }
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        )}
        <Box
          sx={{
            position: 'relative',
            alignSelf: 'flex-start',
            width: state.limitedLabelWidth,
            height: state.limitedLabelHeight,
            m: 1
          }}
        >
          <Box
            component="img"
            alt=""
            src={labelSrc}
            className={cx(classes.superImposed)}
            sx={{
              width: state.limitedLabelWidth,
              height: state.limitedLabelHeight
            }}
          />
          <Box className={classes.superImposed}>
            <LabelDesignStage
              mode={state.mode}
              width={state.limitedLabelWidth}
              height={state.limitedLabelHeight}
              scaleX={state.scaleX}
              scaleY={state.scaleY}
              label={state.label}
              barcodes={state.barcodes}
              setLabel={setLabel}
              addBarcode={addBarcode}
              remBarcode={remBarcode}
              selectedIndex={state.selectedIndex}
              setSelectedIndex={(selectedIndex: number) => setState({ selectedIndex })}
            />
          </Box>
        </Box>
      </Box>
    ) : (
      //  labelState === LabelState.NO_PICTURE
      <Box className={cx(classes.linearVertical, classes.spaceEvenly)}>
        <img
          src={uploadImage}
          style={{
            objectFit: 'contain',
            maxWidth: maxImageWidth * 0.3,
            maxHeight: maxImageHeight * 0.3,
            boxShadow: `0 30px 40px ${alpha(other.white, 0.9)}`
          }}
          alt=""
        />
        <Box className={cx(classes.linearHorizontal, classes.spaceEvenly)}>
          {renderFileButton(spacing(0.5))}
        </Box>
      </Box>
    );

  const renderSaveButton = () => (
    <form onSubmit={onSubmit}>
      <Button variant="contained" color="secondary" type="submit">
        {$t({ id: 'saveChanges' })}
      </Button>
    </form>
  );

  const renderControlButtons = () => (
    <Box className={classes.linearVertical} sx={{ maxWidth: 220, ml: 1 }}>
      {isEditPatternMode ? undefined : (
        <>
          {renderFileButton(spacing(1))}

          <Button
            aria-label={$t({
              id: 'labelDesign.defineLabelRegion'
            })}
            variant={state.mode === Modes.DEFINE_LABEL ? 'outlined' : 'text'}
            className={cx(classes.button, classes.blueButton)}
            onClick={() => setMode(Modes.DEFINE_LABEL)}
          >
            <CropFreeIcon sx={{ mr: 1, ml: 0 }} />
            {$t({ id: 'labelDesign.defineLabelRegion' })}
          </Button>

          <Button
            color="secondary"
            aria-label={$t({ id: 'labelDesign.addBarcodes' })}
            variant={state.mode === Modes.ADD_BARCODE ? 'outlined' : 'text'}
            className={classes.button}
            onClick={() => setMode(Modes.ADD_BARCODE)}
          >
            <AddCircleIcon sx={{ mr: 1 }} />
            {$t({ id: 'labelDesign.addBarcodes' })}
          </Button>

          <Button
            aria-label={$t({
              id: 'labelDesign.removeBarcodes'
            })}
            variant={state.mode === Modes.DELETE ? 'outlined' : 'text'}
            className={cx(classes.button, classes.redButton)}
            onClick={() => setMode(Modes.DELETE)}
          >
            <RemoveCircleIcon sx={{ mr: 1 }} />
            {$t({ id: 'labelDesign.removeBarcodes' })}
          </Button>

          <Button
            aria-label={$t({ id: 'labelDesign.editBarcode' }, { index: 0 })}
            variant="text"
            className={cx(classes.button, classes.blueButton)}
            onClick={() => setMode(Modes.PATTERN)}
            disabled={!state.barcodes || state.barcodes.length < 1 || isEmpty(state.name)}
          >
            <EditRoundedIcon sx={{ mr: 1 }} />
            {$t({ id: 'labelDesign.editBarcode' })}
          </Button>

          <Grid
            container
            direction={'column'}
            justifyContent={'space-between'}
            alignContent={'flex-end'}
            flexGrow={1}
          >
            <Grid item />
            <Grid item>{renderSaveButton()}</Grid>
          </Grid>
        </>
      )}
    </Box>
  );

  return (
    <Container className={classes.container}>
      <Typography variant="h4" className={classes.title} noWrap>
        {$t({ id: 'labelDesign.title' }, { action: $t({ id: 'edit' }) })}
        <Tooltip
          disableTouchListener
          className={classes.tooltip}
          title={
            <Typography variant="subtitle2">{$t({ id: 'dSett.labelDesignsInfo' })}</Typography>
          }
        >
          <HelpOutlineIcon fontSize="small" sx={{ color: 'info.dark' }} />
        </Tooltip>
      </Typography>

      <Typography variant="subtitle1" className={classes.title}>
        {$t({ id: 'labelDesign.subtitle' })}
      </Typography>

      <Box className={cx(classes.linearHorizontal, classes.center)}>
        {!isEditPatternMode && labelDesignStage}

        {labelImageReadyToShow ? (
          <>
            {/* Control buttons */}
            {renderControlButtons()}

            {/* Pattern editor */}
            {isEditPatternMode && (
              <BarcodePatternEditor
                index={state.selectedIndex}
                barcodePatterns={state.barcodePatterns}
                domain={domain}
                labelName={state.name}
                stageWidget={labelDesignStage}
                saveButton={renderSaveButton()}
                onBackToLabelEditor={() => setMode(Modes.NONE)}
                onBarcodePatternChange={onBarcodePatternChange}
                onLabelDesignCheckboxChange={onLabelDesignCheckboxChange}
                onSubmit={onSubmit}
              />
            )}
          </>
        ) : null}
      </Box>
    </Container>
  );
};

export default LabelDesignEditor;
