import { isServerMessageJSON } from 'flyid-core/dist/Common/messages';
import { DomainSettings, TaskParserResult } from 'flyid-core/dist/Database/Models';
import { useEffect, useState } from 'react';
import { auth } from 'src/firebase/firebase';
import { AppState } from 'src/redux/store';
import authAxios from 'src/util/axios';
import { DecodedFile } from 'src/util/helpers/files';
import { parseServerResponse } from 'src/util/helpers/server';
import { Nilable } from 'tsdef';

class TaskInputFileState {
  isWorking = false;
  parsedTaskOrError?: TaskParserResult | string = undefined;
  settingsTimestamp?: number;
}
export function useTaskInputFileParser(
  domain: string,
  state: AppState,
  inputFile: Nilable<DecodedFile>,
  settings: Nilable<DomainSettings>
) {
  const [data, setData] = useState(new TaskInputFileState());

  useEffect(() => {
    const canWork = !!inputFile && !!settings;
    setData({ isWorking: canWork, parsedTaskOrError: undefined, settingsTimestamp: undefined });

    if (canWork) {
      authAxios(auth, state, {
        url: `/checkTaskData`,
        method: 'post',
        params: { domain, name },
        data: inputFile.arrayBuffer,
        headers: {
          ['Content-Type']: inputFile.type
        }
      })
        .then((res) => {
          const { parsedData, settingsTimestamp } = res.data as {
            parsedData: TaskParserResult;
            settingsTimestamp: number;
          };
          setData({ isWorking: false, parsedTaskOrError: parsedData, settingsTimestamp });
        })
        .catch((err: Error) => {
          const msg = parseServerResponse(err);
          setData({
            isWorking: false,
            parsedTaskOrError: isServerMessageJSON(msg) ? msg.msg : msg
          });
        });
    }
  }, [setData, inputFile, settings]);

  return data;
}
