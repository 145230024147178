"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.migrateSettings = exports.isV1 = void 0;
const processFlow_1 = require("../../Util/processFlow");
const types_1 = require("../../Util/types");
const DomainSettings_1 = require("../Models/Settings/DomainSettings");
const Case_1 = require("../Models/Settings/ProcessFlow/Case");
const Elements_1 = require("../Models/Settings/ProcessFlow/Elements");
const LogicalOperator_1 = require("../Models/Settings/ProcessFlow/LogicalOperator");
const ManualInputField_1 = require("../Models/Settings/ProcessFlow/ManualInputField");
const geometry_1 = require("../../Util/geometry");
const helpers_1 = require("../../Util/helpers");
const isV1 = (sett) => {
    const version = sett?.version;
    // eslint-disable-next-line eqeqeq
    return version == null || version < 2;
};
exports.isV1 = isV1;
const onDivergencyCases = (labelDesigns) => {
    const cases = [];
    Object.entries(labelDesigns).forEach(([nodeId, ld]) => ld.barcodePatterns.forEach((bp) => bp.dataFields.forEach((df) => {
        if (df.useCheckField) {
            cases.push((0, Case_1.buildCase)(df.name, nodeId, Case_1.CaseTypes.fieldEqualsExpected, true));
        }
    })));
    return cases;
};
// TODO
// Evaluate how to set multipleLabelsPerPos
/**
 * This method will parse {@link inSett} settings and migrate it properly.
 *
 * From v1 to v2, we have the following major differences:
 *
 * 1. ResultFields, BaseFields addrPattern and addrPatternUser have all been
 * encapsulated into FieldsSettings, which contains two new entries: autoFillableFields and extraTaskFields
 *
 * 2. LabelDesigns and ManualInputFields have been moved into ProcessFlowSettings,
 * which now contains many other entries for flow building:
 * picTaking, autoFillData, conditionals, logicalBlocks, nodes, edges
 *
 * 3. 'createdDate' is now always used as a Date, evne though it is threated as a timestmap by firestore.
 * The conversion to/from date to timestamp is handled on converters.
 *
 * 4. 'version' now tracks the settings version for sanity and migration sake.
 *
 * 5. New entries have been added:
 *    * tabularData: Responsible for keeping key-value pairs/tuples that may be useful
 *    for data filling or address mapping.
 *
 * Behavior description:
 *
 * 1. FieldsSettings and TabularData have their own modification routes and, therefore, can be isolatedly set.
 *
 * 2. ProcessFlowSettings have their own modification routes and, therefore, can be isolatedly set.
 * PS.: On the other hand, changes to FieldsSettings or TabularData may affect ProcessFlow since it depends
 *  on autoFillableFields, extraTaskFields and table data for intermediate actions building.
 *
 * @param inSett Settings to migrate if necessary
 * @returns Ready-to-use domain settings
 */
const migrateSettings = (inSett) => {
    const v1Sett = inSett;
    // Properly types input as v1 settings if so. Otherwise escapes migration.
    if (!(0, exports.isV1)(v1Sett)) {
        // TODO remove temporary adapter to migrate old v2 format
        const anySett = v1Sett.processFlow;
        if (anySett.processFlowNodes || anySett.processFlowEdges) {
            anySett.nodes = anySett.processFlowNodes;
            anySett.edges = anySett.processFlowEdges;
        }
        delete anySett.processFlowNodes;
        delete anySett.processFlowEdges;
        return v1Sett;
    }
    // v1 to v2 migration
    try {
        // Input settings may be v1 and have partial v2 data, here we type input with
        // v2 data to check for the existence of partial v2 data and keep it.
        const possibleV2Data = inSett;
        // Convert field settings to new structure
        const fieldSettings = possibleV2Data.fieldSettings ?? { ...new DomainSettings_1.FieldsSettings() };
        if (!possibleV2Data.fieldSettings) {
            fieldSettings.addrPattern = v1Sett.addrPattern;
            fieldSettings.addrPatternUser = v1Sett.addrPatternUser;
            fieldSettings.baseFields = v1Sett.baseFields;
            fieldSettings.resultFields = v1Sett.resultFields;
            fieldSettings.extraTaskFields = [];
            fieldSettings.autoFillableFields = [];
        }
        const processFlow = possibleV2Data.processFlow ?? { ...new DomainSettings_1.ProcessFlowSettings() };
        // Convert flow-related data
        if (!possibleV2Data.processFlow) {
            const initialHeight = 100;
            const columnWidth = 200;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
            const labelsCount = v1Sett.labelDesigns.length;
            const maxHeight = initialHeight + (labelsCount > 1 ? labelsCount * 100 : 200);
            const halfHeight = maxHeight / 2;
            let x = 100, y = halfHeight;
            const multipleLabels = labelsCount > 1;
            // Create start node
            const startNodeId = (0, processFlow_1.generateDoubleId)();
            const startNode = {
                type: Elements_1.NodeType.Start,
                position: { x, y: y - processFlow_1.ExpectedSizes.Start.height / 2 }
            };
            processFlow.nodes[startNodeId] = startNode;
            x += columnWidth - 30;
            // Create LogicalBlock Node to connect labels if more than one
            let logicalBlockId = undefined;
            const logicalBlockName = 'Etiquetas';
            if (multipleLabels) {
                logicalBlockId = (0, processFlow_1.generateDoubleId)();
                processFlow.nodes[logicalBlockId] = {
                    type: Elements_1.NodeType.LogicalBlock,
                    position: { x, y: 32 }
                };
                processFlow.logicalBlocks[logicalBlockId] = {
                    name: logicalBlockName,
                    operation: LogicalOperator_1.LogicalOperator.OR,
                    contentChildrenIds: []
                };
                // Connect start to LogicalBlock node
                processFlow.edges.push((0, processFlow_1.createEdgeBetween)(startNodeId, logicalBlockId));
            }
            // Pre-create node ids
            const firstCondNodeId = (0, processFlow_1.generateDoubleId)();
            const picTakingId = (0, processFlow_1.generateDoubleId)();
            const mifIds = v1Sett.manualInputFields.map(() => (0, processFlow_1.generateDoubleId)());
            const endNodeId = (0, processFlow_1.generateDoubleId)();
            // Create ConditionalNode to drive invalid labels into picture taking
            const conditionalNode = {
                type: Elements_1.NodeType.Conditional,
                position: { x: 0, y: 0 } // fill later
            };
            const conditionals = [[]];
            const childrenForCalculations = [];
            let parentDim = (0, processFlow_1.calculateParentDimensions)([
                processFlow_1.ExpectedSizes.LabelDesign
            ]);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            v1Sett.labelDesigns.forEach((label, i) => {
                label.multiplePerPosition = false;
                label.mayRepeatInSession = false;
                const id = (0, processFlow_1.generateDoubleId)();
                if (i === 0) {
                    // Prepare CaseData conditionals to handle pic taking or mif
                    conditionals[0].push({
                        target: multipleLabels ? logicalBlockName : label.name,
                        targetNodeId: multipleLabels ? logicalBlockId : id,
                        type: Case_1.CaseTypes.isInvalid,
                        isNot: false
                    });
                }
                label.barcodePatterns.forEach((bp) => {
                    bp.dataFields.forEach((df) => {
                        df.characterFilters = [];
                        df.isAutoFillable = false;
                    });
                });
                processFlow.labelDesigns[id] = { ...label };
                if (logicalBlockId && i > 0) {
                    // Recalculate parent size at every iteraction
                    parentDim = (0, processFlow_1.calculateParentDimensions)(childrenForCalculations);
                }
                const ldNode = {
                    type: Elements_1.NodeType.LabelDesign,
                    position: multipleLabels
                        ? (0, processFlow_1.getNewChildPosition)(processFlow_1.ExpectedSizes.LabelDesign, i, parentDim.width)
                        : { x, y: y - processFlow_1.ExpectedSizes.LabelDesign.height / 2 }
                };
                if (logicalBlockId)
                    ldNode.parent = logicalBlockId;
                processFlow.nodes[id] = ldNode;
                // Create edges with logic block and conditional
                if (!multipleLabels)
                    processFlow.edges.push((0, processFlow_1.createEdgeBetween)(startNodeId, id));
                processFlow.edges.push((0, processFlow_1.createEdgeBetween)(id, firstCondNodeId));
                // Add child to parent
                if (logicalBlockId) {
                    processFlow.logicalBlocks[logicalBlockId].contentChildrenIds.push((0, processFlow_1.getTypedElementId)(id, Elements_1.NodeType.LabelDesign));
                }
                childrenForCalculations.push(processFlow_1.ExpectedSizes.LabelDesign);
            });
            y = halfHeight;
            x += parentDim.width + 48;
            // Create else case (some data has been captured) to handle mifs
            conditionals.push([
                {
                    target: '',
                    targetNodeId: '',
                    type: Case_1.CaseTypes.else,
                    isNot: false
                }
            ]);
            processFlow.nodes[firstCondNodeId] = {
                ...conditionalNode,
                position: { x, y: y - processFlow_1.ExpectedSizes.Conditional.height / 2 }
            };
            x += columnWidth;
            y = halfHeight;
            const picTakingNode = {
                type: Elements_1.NodeType.TakePicture,
                position: { x, y: y - processFlow_1.ExpectedSizes.TakePicture.height }
            };
            processFlow.nodes[picTakingId] = picTakingNode;
            processFlow.picTaking[picTakingId] = { name: 'Inválido', isMandatory: false };
            processFlow.edges.push((0, processFlow_1.createEdgeBetween)(firstCondNodeId, picTakingId, `${Elements_1.HandleType.SINGLE}_0`));
            y += 32;
            let lastNodeId = undefined;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
            let lastOutputConditional = undefined;
            v1Sett.manualInputFields.forEach((mif, mifIdx) => {
                // Copy MIFs, transforming boolean actions into conditional
                const mifId = mifIds[mifIdx];
                let outputConditional = undefined;
                if (mif.inputType === ManualInputField_1.ManualInputTypes.binary) {
                    const cases = [];
                    switch (mif.negativeActionType) {
                        case 'picture': {
                            outputConditional = (0, processFlow_1.generateDoubleId)();
                            cases.push([(0, Case_1.buildCase)(mif.field, mifId, Case_1.CaseTypes.binaryMifIsTrue, true)]);
                            break;
                        }
                        case 'picture_on_divergence': {
                            const divCases = onDivergencyCases(processFlow.labelDesigns);
                            if (divCases.length) {
                                outputConditional = (0, processFlow_1.generateDoubleId)();
                                const thisCases = [];
                                thisCases.push((0, Case_1.buildCase)(mif.field, mifId, Case_1.CaseTypes.binaryMifIsTrue, true));
                                onDivergencyCases(processFlow.labelDesigns).forEach((c, idx) => {
                                    thisCases.push(idx !== 0 ? LogicalOperator_1.LogicalOperator.AND : LogicalOperator_1.LogicalOperator.OR);
                                    thisCases.push(c);
                                });
                                cases.push(thisCases);
                            }
                            break;
                        }
                        default:
                            break;
                    }
                    switch (mif.positiveActionType) {
                        case 'picture': {
                            outputConditional = (0, processFlow_1.generateDoubleId)();
                            cases.push([(0, Case_1.buildCase)(mif.field, mifId, Case_1.CaseTypes.binaryMifIsTrue, false)]);
                            break;
                        }
                        case 'picture_on_divergence': {
                            const divCases = onDivergencyCases(processFlow.labelDesigns);
                            if (divCases.length) {
                                outputConditional = (0, processFlow_1.generateDoubleId)();
                                const thisCases = [];
                                thisCases.push((0, Case_1.buildCase)(mif.field, mifId, Case_1.CaseTypes.binaryMifIsTrue, false));
                                onDivergencyCases(processFlow.labelDesigns).forEach((c, idx) => {
                                    thisCases.push(idx !== 0 ? LogicalOperator_1.LogicalOperator.AND : LogicalOperator_1.LogicalOperator.OR);
                                    thisCases.push(c);
                                });
                                cases.push(thisCases);
                            }
                            break;
                        }
                        default:
                            break;
                    }
                    if (outputConditional) {
                        processFlow.conditionals[outputConditional] = (0, types_1.convertListToMap)([
                            ...cases,
                            [(0, Case_1.buildCase)('', '', Case_1.CaseTypes.else)]
                        ]);
                    }
                }
                if ((0, helpers_1.isNulli)(mif.isSummable))
                    mif.isSummable = false;
                if ((0, helpers_1.isNulli)(mif.showHistory))
                    mif.showHistory = false;
                processFlow.manualInputFields[mifId] = {
                    ...mif,
                    isAutoFillable: false
                };
                const mifNode = {
                    type: Elements_1.NodeType.ManualInputField,
                    position: { x, y }
                };
                processFlow.nodes[mifId] = mifNode;
                // Connect last node with this mif
                processFlow.edges.push((0, processFlow_1.createEdgeBetween)(lastNodeId ?? firstCondNodeId, mifId, `${Elements_1.HandleType.SINGLE}_1`));
                // If there was an output conditional, connect it to this mif
                if (lastOutputConditional) {
                    processFlow.edges.push((0, processFlow_1.createEdgeBetween)(lastOutputConditional, mifId, `${Elements_1.HandleType.SINGLE}_${Object.keys(processFlow.conditionals[lastOutputConditional]).length - 1}`));
                }
                if (outputConditional) {
                    const mifPicTakingId = (0, processFlow_1.generateDoubleId)();
                    // Place a conditional in front of this mif
                    x += processFlow_1.ExpectedSizes.Conditional.width + 40;
                    processFlow.nodes[outputConditional] = {
                        type: Elements_1.NodeType.Conditional,
                        position: { x, y }
                    };
                    // Place a pic taking in front of this conditional
                    const mifPicTakingNode = {
                        type: Elements_1.NodeType.TakePicture,
                        position: {
                            x: x + processFlow_1.ExpectedSizes.TakePicture.width + 40,
                            y: y - processFlow_1.ExpectedSizes.TakePicture.height / 2
                        }
                    };
                    processFlow.nodes[mifPicTakingId] = mifPicTakingNode;
                    processFlow.picTaking[mifPicTakingId] = { name: 'Extra picture', isMandatory: false };
                    processFlow.edges.push((0, processFlow_1.createEdgeBetween)(mifId, outputConditional, `${Elements_1.HandleType.SINGLE}_1`));
                    Object.entries(processFlow.conditionals[outputConditional]).forEach((_, idx, l) => {
                        // Connect conditional to pic taking and else
                        if (idx < l.length - 1) {
                            processFlow.edges.push((0, processFlow_1.createEdgeBetween)(outputConditional, mifPicTakingId, `${Elements_1.HandleType.SINGLE}_${idx}`));
                        }
                    });
                    lastNodeId = mifPicTakingId;
                }
                else {
                    lastNodeId = mifId;
                }
                lastOutputConditional = outputConditional;
                y += processFlow_1.ExpectedSizes.ManualInputField.height + 32;
            });
            processFlow.conditionals[firstCondNodeId] = (0, types_1.convertListToMap)(conditionals);
            // If last node was a mif output conditional, then we have to connect its else state to end
            if (lastOutputConditional) {
                processFlow.edges.push((0, processFlow_1.createEdgeBetween)(lastOutputConditional, endNodeId, `${Elements_1.HandleType.SINGLE}_${Object.keys(processFlow.conditionals[lastOutputConditional]).length - 1}`));
            }
            // Compensate x when using MIF binary action conditionals
            const hasMifConditionals = Object.keys(processFlow.conditionals).length > 1;
            if (hasMifConditionals) {
                x += processFlow_1.ExpectedSizes.TakePicture.width + 40;
            }
            y = halfHeight;
            x += columnWidth;
            // Create end node
            const endNode = {
                type: Elements_1.NodeType.End,
                position: { x, y: y - 16 },
                rotation: hasMifConditionals ? geometry_1.Rotation.State.THREE_QUARTERS_CW : geometry_1.Rotation.State.NONE
            };
            processFlow.nodes[endNodeId] = endNode;
            // Connect pic taking and mif or conditional to LogicalBlock node
            processFlow.edges.push((0, processFlow_1.createEdgeBetween)(picTakingId, endNodeId));
            processFlow.edges.push((0, processFlow_1.createEdgeBetween)(lastNodeId ?? firstCondNodeId, endNodeId, `${Elements_1.HandleType.SINGLE}_1`));
        }
        const tabularData = possibleV2Data.tabularData ?? {
            hasAddressMapTable: false,
            dataTables: {}
        };
        if ((0, helpers_1.isNulli)(tabularData.hasAddressMapTable))
            tabularData.hasAddressMapTable = false;
        const outSett = {
            createdDate: possibleV2Data.createdDate,
            processFlow,
            fieldSettings,
            tabularData,
            version: 2,
            isMigrated: true
        };
        return outSett;
    }
    catch (err) {
        console.error(`Failed migrating settings: ${String(err)}`);
        return inSett;
    }
};
exports.migrateSettings = migrateSettings;
