import { HelpCenter } from '@mui/icons-material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupIcon from '@mui/icons-material/Group';
import ListAltIcon from '@mui/icons-material/ListAlt';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
// import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import NavBar, { NavGroups } from 'flyid-ui-components/dist/layout/NavBar';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { getNoProfileImg } from 'src/assets/assets';
import squareLogo from 'src/assets/images/logo/pid_logo_primary_m.png';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { useAuth } from 'src/hooks/useAuth';
import useGlobalState from 'src/hooks/useGlobalState';
import { Actions } from 'src/redux/actions/actionTypes';
import { fetchProfileImages } from 'src/redux/actions/userActions';
import { setLanguage as setLanguageAction } from 'src/redux/reducers/localeReducer';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { selectAvailableCompanies } from 'src/redux/selectors/dataSelectors';
import { selectAuthDomains } from 'src/redux/selectors/userSelectors';
import { isKeyUserProf, isModeratorProf } from 'src/util/helpers/user';
import { readableLocaleByLocale, SUPPORTED_LOCALES } from 'src/util/locale';
import logo from '../../assets/images/logo/logo_fly_id.png';

const NavBarImpl: React.FC = (props) => {
  const { user: authUser, loading: authLoading } = useAuth();
  const signedIn = !authLoading && Boolean(authUser);
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const { $t } = intl;

  const { userData, locale, availableCompanies, authDomains } = useAppSelector((s) => {
    const availableCompanies = selectAvailableCompanies(s);
    return {
      userData: s.user,
      locale: s.locale,
      availableCompanies,
      authDomains: selectAuthDomains(s)
    };
  });
  const [selectedCompany, setSelectedCompany] = useGlobalState('targetCompany');

  const { uid: selfUid, profile, isLoaded: profileDoneLoading, profileError } = userData;
  const isProfileLoaded = profileDoneLoading && !profileError;
  const profileImageData = selfUid ? userData.profilePics[selfUid] : undefined;

  const routes = useMemo(() => {
    const _routes: NavGroups = [];

    if (signedIn) {
      _routes.push({
        key: 'user',
        name: $t({ id: 'user' }),
        icon: <AccountBoxIcon />,
        children: [
          {
            name: $t({ id: 'nav.profile' }),
            path: '/profile',
            icon: <AccountCircleIcon />
          },
          ...(isModeratorProf(profile) || isKeyUserProf(profile)
            ? [
                {
                  name: $t({ id: 'nav.manageusers' }),
                  path: '/manageusers',
                  icon: <GroupIcon />
                },
                {
                  name: $t({ id: 'nav.managedomains' }),
                  path: '/managedomains',
                  icon: <ListAltIcon />
                },
                {
                  name: $t({ id: 'nav.managekeys' }),
                  path: '/managekeys',
                  icon: <VpnKeyIcon />
                },
                // TODO Consider adding a license management page instead of altering on domain settings
                // {
                //   name: $t({ id: 'nav.manageLicenses' }),
                //   path: '/managelicenses',
                //   icon: <WorkspacePremiumIcon />
                // },
                {
                  name: $t({ id: 'nav.help' }),
                  icon: <HelpCenter />,
                  listItemButtonProps: {
                    component: 'a',
                    rel: 'noopener noreferrer',
                    target: '_blank',
                    href: 'https://panoramaid.atlassian.net/servicedesk/customer/portals'
                  }
                }
              ]
            : [])
        ]
      });
    }

    if (signedIn && isProfileLoaded && authDomains) {
      _routes.push({
        key: 'domains',
        name: 'Dashboards',
        icon: <DashboardIcon />,
        children: authDomains.map((domain) => ({
          name: domain,
          path: `/domains/${domain}`
        }))
      });
    }

    return _routes;
  }, [signedIn, isProfileLoaded, profile, locale, authDomains]);

  const showLogoutDialogConfirmation = useCallback(() => {
    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'nav.logoutTitle' }),
          message: $t({ id: 'nav.logoutMsg' }),
          show: true
        }).setConfirmAction(Actions.LOGOUT)
      })
    );
  }, [dispatch]);

  // Fetch own profile picture
  useEffect(() => {
    // Only try to download profile images if properly authenticated
    if (signedIn && isProfileLoaded) {
      if (!profileImageData?.isLoading && !profileImageData?.isLoaded) {
        dispatch(
          fetchProfileImages({
            profileUids: [selfUid],
            company: profile.company,
            isThumb: false
          })
        );
      }
    }
  }, [profile, profileImageData]);

  return (
    <NavBar
      userData={userData}
      signedIn={signedIn}
      routes={routes}
      logo={logo}
      noImg={getNoProfileImg()}
      squareLogo={squareLogo}
      logoutAction={showLogoutDialogConfirmation}
      companySelector={
        // availableCompanies are only defined when current user is key user
        availableCompanies
          ? {
              selectedCompany,
              availableCompanies,
              onCompanyChange: setSelectedCompany
            }
          : undefined
      }
      localesData={{
        onLocaleChange: (_locale) => dispatch(setLanguageAction(_locale)),
        supportedLocales: SUPPORTED_LOCALES,
        readableLocales: readableLocaleByLocale
      }}
    >
      {props.children}
    </NavBar>
  );
};

export default NavBarImpl;
