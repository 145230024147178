import { cx } from '@emotion/css';
import {
  Checkbox,
  Container,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import { isEmpty } from 'lodash';
import { FormEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useParams } from 'react-router-dom';
import LoadingButton from 'src/components/widgets/LoadingButton';
import LoadingCircle from 'src/components/widgets/LoadingCircle';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { editApiKeyAuthDomains } from 'src/redux/actions/managementActions';
import { selectApiKeys } from 'src/redux/selectors/dataSelectors';
import { selectAuthDomains } from 'src/redux/selectors/userSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';

const useStyles = appMakeStyles((theme) => ({
  container: {
    ...theme.resizableContainer(2),
    marginLeft: 0,
    maxWidth: '800px'
  },
  title: {
    color: theme.other.grey.dark,
    marginBottom: theme.spacing(2)
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2)
  }
}));

const EditApiKeyAuthDomains: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { $t } = useIntl();
  const theme = useAppTheme();

  const { apiKeyId } = useParams<ApiKeyMatchParams>();

  // Fallback to home if apiKeyId is missing
  if (!apiKeyId) return <Navigate replace to="/" />;

  const { apiKeysData, ui, availableDomains } = useAppSelector((s) => ({
    ui: s.ui,
    apiKeysData: selectApiKeys(s),
    availableDomains: selectAuthDomains(s)
  }));

  const apiKeyData = apiKeysData?.[apiKeyId];

  const [domains, setDomains] = useState<string[]>(apiKeyData?.authDomains ?? []);
  const [licenseError, setDomainError] = useState<string | undefined>(undefined);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (isEmpty(domains)) {
      setDomainError($t({ id: 'editApiKeyAuthDom.domainRequired' }));
      return;
    }
    setDomainError(undefined);

    dispatch(
      editApiKeyAuthDomains({
        parentUid: apiKeyData?.parentUid,
        data: { domains: domains, apiKeyId }
      })
    );
  };

  const handleDomainsChange = (e: SelectChangeEvent<string[]>) => {
    setDomains(e.target.value as string[]);
    setDomainError(undefined);
  };

  return (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" className={cx(classes.title)}>
          {$t(
            { id: 'editApiKeyAuthDom.title' },
            { licenseDescription: apiKeyData && apiKeyData.description }
          )}
        </Typography>

        {apiKeysData ? (
          availableDomains && !isEmpty(availableDomains) ? (
            <>
              <FormControl className={classes.formControl}>
                <InputLabel id="authDomains-select-label">
                  {$t({ id: 'editApiKeyAuthDom.authDomains' })}
                </InputLabel>
                <Select
                  labelId="authDomains-select-label"
                  id="authDomains-select"
                  data-testid="authdomains"
                  multiple
                  value={domains}
                  onChange={handleDomainsChange}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                  error={!!licenseError}
                  MenuProps={theme.select.getMenuProps()}
                >
                  {availableDomains.map((domain) => (
                    <MenuItem key={domain} value={domain}>
                      <Checkbox checked={domains.indexOf(domain) > -1} />
                      <ListItemText primary={domain} />
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error>{licenseError}</FormHelperText>
              </FormControl>
              <LoadingButton
                isLoading={ui.loadingButton.isEditApiKeyAuthDomainsLoading}
                content={$t({ id: 'saveChanges' })}
                disabled={!availableDomains || availableDomains.length === 0}
                data-testid="submit-btn"
                type="submit"
              />
            </>
          ) : (
            <Typography>{$t({ id: 'editApiKeyAuthDom.noDomainsAvailable' })}</Typography>
          )
        ) : (
          <LoadingCircle />
        )}
      </form>
    </Container>
  );
};

export default EditApiKeyAuthDomains;
