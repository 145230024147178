"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.readAndParseJsonFile = exports.debugFlyidEnvironment = exports.parseEnvToJSON = exports.sortObjectByValueSelector = exports.sortObjectByValues = exports.sortObjectByKeys = exports.waitSeconds = exports.emailByDomainThenNameComparator = exports.filterObjectByKeys = exports.filterObject = exports.randomGaussian = exports.randomNumber = exports.getLastKey = exports.getFirstKey = exports.getLast = exports.getFirst = exports.isNulli = exports.isNonNulli = void 0;
const fs_1 = require("fs");
const lodash_1 = require("lodash");
// eslint-disable-next-line eqeqeq
const isNonNulli = (tgt) => tgt != null;
exports.isNonNulli = isNonNulli;
// eslint-disable-next-line eqeqeq
const isNulli = (tgt) => tgt == null;
exports.isNulli = isNulli;
/**
 * @param object
 * @returns the first value of the given object
 */
const getFirst = (object) => ((0, lodash_1.isObject)(object) ? Object.values(object)[0] : null);
exports.getFirst = getFirst;
/**
 * @param object
 * @returns the last value of the given object
 */
const getLast = (object) => (0, lodash_1.isObject)(object) ? Object.values(object)[Object.keys(object).length - 1] : null;
exports.getLast = getLast;
/**
 * @param object
 * @returns the first key of the given object
 */
const getFirstKey = (object) => (0, lodash_1.isObject)(object) ? Object.keys(object)[0] : null;
exports.getFirstKey = getFirstKey;
/**
 * @param object
 * @returns the last key of the given object
 */
const getLastKey = (object) => {
    return (0, lodash_1.isObject)(object) ? Object.keys(object)[Object.keys(object).length - 1] : null;
};
exports.getLastKey = getLastKey;
const randomNumber = (length) => {
    if (length > 9) {
        throw new Error('Generated number length must be < 10');
    }
    return Math.round(Math.random() * Math.pow(10, length));
};
exports.randomNumber = randomNumber;
// Box–Muller transform from https://stackoverflow.com/questions/25582882/javascript-math-random-normal-distribution-gaussian-bell-curve
// This implementation will return  values between 0 and 1, with mean (mi) of 0.5
// and standard deviation (sigma) of 0.1
const randomGaussian = () => {
    let u = 0;
    let v = 0;
    while (u === 0)
        u = Math.random(); //Converting [0,1) to (0,1)
    while (v === 0)
        v = Math.random();
    let num = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
    num = num / 10.0 + 0.5; // Translate to 0 -> 1
    if (num > 1 || num < 0)
        return (0, exports.randomGaussian)(); // resample between 0 and 1
    return num;
};
exports.randomGaussian = randomGaussian;
/**
 * Filters the given object, returning a new object with members filtered by the
 * condition established by {@link predicate}
 */
const filterObject = (obj, predicate) => {
    const result = {};
    Object.entries(obj).forEach(([key, value]) => {
        if (predicate(key, value)) {
            result[key] = value;
        }
    });
    return result;
};
exports.filterObject = filterObject;
/**
 * Filters the given object, returning a new object with members filtered by the
 * keys provided in {@link allowedKeys}
 */
const filterObjectByKeys = (raw, allowedKeys) => {
    return Object.keys(raw)
        .filter((key) => allowedKeys.includes(key))
        .reduce((obj, key) => {
        obj[key] = raw[key];
        return obj;
    }, {});
};
exports.filterObjectByKeys = filterObjectByKeys;
const emailByDomainThenNameComparator = (email1, email2) => {
    let resDomain;
    if (!!email1 && !!email2) {
        // Compare by domain
        resDomain = email1.match(/@([^.]+)\./)[1].localeCompare(email2.match(/@([^.]+)\./)[1]);
        // Compare by email, if domains are equal
        if (resDomain === 0)
            return email1.localeCompare(email2);
    }
    else if (email1 === email2) {
        resDomain = 0;
    }
    else if (email1) {
        resDomain = -1;
    }
    else {
        resDomain = 1;
    }
    return resDomain;
};
exports.emailByDomainThenNameComparator = emailByDomainThenNameComparator;
const waitSeconds = (seconds) => new Promise((resolve) => setTimeout(() => resolve(true), seconds * 1000));
exports.waitSeconds = waitSeconds;
const sortObjectByKeys = (obj, order = 'asc') => Object.keys(obj)
    .sort(order === 'desc' ? (k1, k2) => k2.localeCompare(k1) : undefined)
    .reduce((o, key) => {
    o[key] = obj[key];
    return o;
}, {});
exports.sortObjectByKeys = sortObjectByKeys;
const sortObjectByValues = (obj, order = 'asc') => Object.entries(obj)
    .sort(([_k1, v1], [_k2, v2]) => (order === 'asc' ? v1.localeCompare(v2) : v2.localeCompare(v1)))
    .reduce((o, [key, value]) => {
    o[key] = value;
    return o;
}, {});
exports.sortObjectByValues = sortObjectByValues;
const sortObjectByValueSelector = (obj, selector, order = 'asc') => Object.entries(obj)
    .sort(([_k1, v1], [_k2, v2]) => {
    const f = (a, b) => String(selector(a)).localeCompare(String(selector(b)));
    return order === 'asc' ? f(v1, v2) : f(v2, v1);
})
    .reduce((o, [key, value]) => {
    o[key] = value;
    return o;
}, {});
exports.sortObjectByValueSelector = sortObjectByValueSelector;
const parseEnvToJSON = (name) => {
    if (!name)
        throw new Error('Missing environment variable name!');
    const envVariable = process.env[name];
    if (!envVariable)
        throw new Error(`Missing ${name}`);
    try {
        return JSON.parse(envVariable);
    }
    catch (err) {
        throw new Error(`Failed parsing ${envVariable}! (${err.message})`);
    }
};
exports.parseEnvToJSON = parseEnvToJSON;
const debugFlyidEnvironment = (...extras) => {
    console.log((0, exports.filterObject)(process.env, (k) => k.includes('FLYID') ||
        k.startsWith('REACT_APP') ||
        k.includes('FIREBASE') ||
        k.includes('FIRESTORE') ||
        k.includes('FUNCTIONS_EMULATOR') ||
        k.includes('GCLOUD')), ...extras);
};
exports.debugFlyidEnvironment = debugFlyidEnvironment;
/**
 * This method reads and parses a json file.
 * Useful when we don't want to hardcode the file into an import.
 * This method cannot be used on browsers, only on node environment.
 */
const readAndParseJsonFile = (absolutePath) => {
    if (!absolutePath)
        throw new Error(`Missing absolute path to file!`);
    try {
        return JSON.parse((0, fs_1.readFileSync)(absolutePath).toString());
    }
    catch (err) {
        throw new Error(`Failed parsing ${absolutePath}! (${err.message})`);
    }
};
exports.readAndParseJsonFile = readAndParseJsonFile;
