"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.filterArray = void 0;
exports.removeDuplicates = removeDuplicates;
exports.removeDuplicatesBy = removeDuplicatesBy;
/**
 * This method will filter array data based on the given predicate and returns
 * two arrays: for predicate returning true, and predicate returning false.
 * This is useful when both predicate responses are valuable.
 */
const filterArray = (arr, predicate) => {
    const falsePredicates = [];
    const truePredicates = arr.filter((v) => {
        if (predicate(v))
            return true;
        falsePredicates.push(v);
        return false;
    });
    return [truePredicates, falsePredicates];
};
exports.filterArray = filterArray;
function removeDuplicates(array) {
    return Array.from(new Set(array));
}
function removeDuplicatesBy(array, getKey) {
    const seen = new Set();
    return array.filter((item) => {
        const key = getKey(item);
        if (seen.has(key)) {
            return false;
        }
        else {
            seen.add(key);
            return true;
        }
    });
}
