import { useEffect, useState } from 'react';

/**
 * A hook that will run only on state updates, simulating the deprecated
 * componentDidUpdate method. It is useful when the effect is not desired to run
 * at component mounting.
 *
 * The effect will keep running at any {@link dependencyList} state changes
 * or any state change at all if no {@link dependencyList} has been given.
 *
 * @param callback The effect to be executed, optionally returning a destructor.
 * @param dependencyList Self explanatory.
 */
function useUpdateEffect(
  callback: () => (() => void) | void,
  dependencyList?: React.DependencyList,
  initConditions?: boolean[]
) {
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (!init && (initConditions?.every(Boolean) ?? true)) setInit(true);
    else if (init) return callback();
  }, dependencyList);
}

export default useUpdateEffect;
