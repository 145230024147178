export interface StoredStateConverter<T> {
  toStorage(data: T | undefined): string | undefined;
  fromStorage(data: string | undefined): T | undefined;
}

export function getJSONConverter<T>() {
  return {
    toStorage: (data) => (data ? JSON.stringify(data) : undefined),
    fromStorage: (data) => {
      try {
        return data ? (JSON.parse(data) as T) : undefined;
      } catch {
        return undefined;
      }
    }
  } as StoredStateConverter<T>;
}
