"use strict";
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _TwoWayMap_map, _TwoWayMap_reverseMap, _TwoWayMap_mixedMap, _TwoWayMap_useSingleMap;
Object.defineProperty(exports, "__esModule", { value: true });
exports.TwoWayMap = void 0;
/**
 * Helper class to create a bidirectional map, either using a single map or
 * a map and reverseMap to record data internally
 */
class TwoWayMap {
    constructor(map, useSingleMap = true) {
        _TwoWayMap_map.set(this, void 0);
        _TwoWayMap_reverseMap.set(this, void 0);
        _TwoWayMap_mixedMap.set(this, void 0);
        _TwoWayMap_useSingleMap.set(this, void 0);
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        __classPrivateFieldSet(this, _TwoWayMap_useSingleMap, useSingleMap, "f");
        if (map) {
            if (!useSingleMap) {
                __classPrivateFieldSet(this, _TwoWayMap_map, { ...map }, "f");
                __classPrivateFieldSet(this, _TwoWayMap_reverseMap, new Map(), "f");
                map.forEach((value, key) => {
                    __classPrivateFieldGet(this, _TwoWayMap_reverseMap, "f")?.set(value, key);
                });
            }
            else {
                __classPrivateFieldSet(this, _TwoWayMap_mixedMap, new Map(map), "f");
                map.forEach((value, key) => {
                    __classPrivateFieldGet(this, _TwoWayMap_mixedMap, "f")?.set(value, key);
                });
            }
        }
        else {
            if (!useSingleMap) {
                __classPrivateFieldSet(this, _TwoWayMap_map, new Map(), "f");
                __classPrivateFieldSet(this, _TwoWayMap_reverseMap, new Map(), "f");
            }
            else {
                __classPrivateFieldSet(this, _TwoWayMap_mixedMap, new Map(map), "f");
            }
        }
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
    }
    /**
     * Retrieve corresponding value for this key
     * @param key key
     * @returns value or undefined
     */
    get(key) {
        return __classPrivateFieldGet(this, _TwoWayMap_map, "f")?.get(key);
    }
    /**
     * Returns the reverse mapping
     * @param key value
     * @returns corresponding key or undefined
     */
    revGet(key) {
        return __classPrivateFieldGet(this, _TwoWayMap_reverseMap, "f")?.get(key);
    }
    /**
     * Returns any extising correspondency to the given key
     * @param key key
     * @returns corresponding value if any, undefined otherwise
     */
    getAny(key) {
        return __classPrivateFieldGet(this, _TwoWayMap_mixedMap, "f")?.get(key);
    }
    addData(map) {
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        if (!__classPrivateFieldGet(this, _TwoWayMap_useSingleMap, "f")) {
            map.forEach((value, key) => {
                __classPrivateFieldGet(this, _TwoWayMap_reverseMap, "f")?.set(value, key);
                __classPrivateFieldGet(this, _TwoWayMap_map, "f")?.set(key, value);
            });
        }
        else {
            map.forEach((value, key) => {
                __classPrivateFieldGet(this, _TwoWayMap_mixedMap, "f")?.set(value, key);
                __classPrivateFieldGet(this, _TwoWayMap_mixedMap, "f")?.set(key, value);
            });
        }
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
    }
}
exports.TwoWayMap = TwoWayMap;
_TwoWayMap_map = new WeakMap(), _TwoWayMap_reverseMap = new WeakMap(), _TwoWayMap_mixedMap = new WeakMap(), _TwoWayMap_useSingleMap = new WeakMap();
