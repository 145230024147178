import { Query, getCountFromServer } from 'firebase/firestore';
import { useEffect, useState } from 'react';

const useCountDataTotal = <T>(dataQuery: Query<T> | null): number | null => {
  const [totalCount, setTotalCount] = useState<number | null>(null);

  const getTotalCount = async () => {
    if (dataQuery) {
      const count = await getCountFromServer(dataQuery);
      setTotalCount(count.data().count);
    }
  };

  useEffect(() => {
    getTotalCount().catch((err) => console.error(`Failed fetching data count!`, err));
  }, [dataQuery]);

  return totalCount;
};

export default useCountDataTotal;
