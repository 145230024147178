import { cx } from '@emotion/css';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import {
  MaybeKeyUserPublic,
  Permissions,
  PermissionsKeys,
  PermissionsProps,
  UserPublic
} from 'flyid-core/dist/Database/Models/User';
import { decodeText } from 'flyid-core/dist/Util/web';
import useUpdateEffect from 'flyid-ui-components/dist/hooks/useUpdateEffect';
import { cloneDeep, isEmpty, isNull } from 'lodash';
import { ChangeEvent, JSX, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, Navigate, useParams } from 'react-router-dom';
import { getNoProfileImg } from 'src/assets/assets';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { useAuth } from 'src/hooks/useAuth';
import useStateReducer from 'src/hooks/useStateReducer';
import { ParameterMap } from 'src/redux/actions/actionTypes';
import { fetchProfileImages } from 'src/redux/actions/userActions';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { selectLicenses } from 'src/redux/selectors/dataSelectors';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import {
  selectAuthDomains,
  selectCurrentUserProfile,
  selectUserProfilePics,
  selectUserProfiles
} from 'src/redux/selectors/userSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { Nilable } from 'tsdef';
import cidLogo from '../../assets/images/logo/monograma_cid.png';
import fidLogo from '../../assets/images/logo/monograma_flyid.png';
import { Actions } from '../../redux/actions/actionsHandler';
import { buildLicenseDescription, buildShortLicenseDescription } from '../../util/helpers/other';
import { isKeyUserProf, isModeratorProf } from '../../util/helpers/user';
import { urlDataReader } from '../../workers/fileWorkerApi';
import LoadingButton from '../widgets/LoadingButton';
import LogoTooltip from '../widgets/LogoTooltip';

const useStyles = appMakeStyles(({ palette, resizableContainer, spacing, other, select }) => ({
  container: { ...resizableContainer(2), marginLeft: 0 },
  innerContainer: {
    maxWidth: '850px',
    marginLeft: spacing(2)
  },
  titleContainer: {
    maxWidth: '850px',
    marginBottom: spacing(4)
  },
  marginBottom: {
    marginBottom: spacing(2)
  },
  profileImage: {
    width: spacing(20),
    height: spacing(20)
  },
  mainGrid: {
    minWidth: '650px'
  },
  sessionMargin: {
    marginTop: spacing(2)
  },
  selectFormControl: {
    ...select.inFormControl,
    minWidth: 120
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  authLicensesTitle: {
    color: other.grey.main,
    paddingLeft: spacing(1)
  },
  countidIconRootClass: {
    backgroundColor: palette.primary.main
  },
  flyidIconRootClass: {
    backgroundColor: palette.primary.dark
  }
}));

type State = {
  company: string;
  parentUid?: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  permissions: PermissionsProps;
  authDomains: string[];
  authLicenses: string[];
  email: string;
  profilePicFile: string | undefined;
  // UI Flags
  showConfirmation: false;
  originalData: MaybeKeyUserPublic | undefined;
};

const initialState: State = {
  company: '',
  parentUid: undefined,
  firstName: '',
  lastName: '',
  employeeId: '',
  permissions: [] as PermissionsProps,
  authDomains: [] as string[],
  authLicenses: [] as string[],
  email: '',
  profilePicFile: undefined as string | undefined,
  // UI Flags
  showConfirmation: false,
  originalData: undefined as UserPublic | undefined
};

const readPermissionsIntoArray = (profile: MaybeKeyUserPublic) => {
  const permissions: PermissionsProps = [];
  if (!isEmpty(profile)) {
    PermissionsKeys.forEach((perm) => {
      const permIsGiven = profile[perm];
      if (permIsGiven) {
        permissions.push(perm);
      }
    });
  }
  return permissions;
};

/**
 * Some lines have the comment "istanbul ignore next",
 * this is related to the Instanbul library for testing
 * support. It doesn't affect the code itself, only the
 * test coverage for this component.
 */

const UserProfile: React.FC = () => {
  const { uid: paramUid } = useParams<UserMatchParams>();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { text, spacing, select } = useAppTheme();
  const intl = useIntl();
  const { $t } = intl;
  const noProfileImage = getNoProfileImg();

  const [state, setState] = useStateReducer<State>(initialState);
  const { user, usingSSO } = useAuth();

  const myUid = user!.uid;
  const targetUid = paramUid ?? myUid;
  const isOwnProfile = !paramUid;

  const {
    ui,
    myProfile,
    targetProfile,
    parentProfile,
    profilePics,
    licenses,
    targetCompany,
    allAuthDomains
  } = useAppSelector((s) => {
    const userProfiles = selectUserProfiles(s);
    const myProfile = selectCurrentUserProfile(s);

    let targetProfile: Nilable<MaybeKeyUserPublic>;
    if (isOwnProfile) {
      targetProfile = myProfile;
    } else {
      targetProfile = isNull(userProfiles) ? null : userProfiles?.[paramUid];
    }

    return {
      ui: s.ui,
      myProfile,
      targetProfile,
      parentProfile:
        !isOwnProfile && targetProfile?.parent
          ? !isNull(userProfiles)
            ? userProfiles?.[targetProfile.parent]
            : null
          : undefined,
      profilePics: selectUserProfilePics(s),
      licenses: selectLicenses(s, undefined, isOwnProfile ? s.user.uid : paramUid, isOwnProfile),
      targetCompany: selectTargetCompany(s),
      allAuthDomains: selectAuthDomains(
        s,
        isOwnProfile
          ? undefined
          : {
              company: selectTargetCompany(s),
              targetUser: targetProfile?.parent
            }
      )
    };
  });

  // If targetCompany changes, reroute to domain settings.
  const [redirectDest, setRedirectTo] = useState<string | null>(null);
  useUpdateEffect(() => {
    /* istanbul ignore next */
    if (!isOwnProfile) setRedirectTo('/manageusers');
  }, [targetCompany, isOwnProfile]);

  const areProfilesLoaded = myProfile && targetProfile !== null && parentProfile !== null;
  const isPin =
    usingSSO && !!(targetProfile?.pilot || /* istanbul ignore next */ targetProfile?.checker);
  const profileImageData = profilePics[targetUid];

  // Setup initial state values as soon as profile is ready
  useEffect(() => {
    if (targetProfile) {
      setState({
        company: targetCompany!,
        parentUid: targetProfile.parent,
        firstName: targetProfile.firstName,
        lastName: targetProfile.lastName,
        email: targetProfile.email,
        employeeId: targetProfile.employeeId,
        permissions: readPermissionsIntoArray(targetProfile),
        authDomains: targetProfile.authDomains,
        originalData: cloneDeep(targetProfile)
      });
    }
  }, [targetProfile]);

  // Fetch profile picture if not yet
  useEffect(() => {
    if (
      myProfile &&
      !!targetProfile &&
      !profileImageData?.isLoading &&
      !profileImageData?.isLoaded
    ) {
      dispatch(
        fetchProfileImages({
          profileUids: [targetUid],
          company: targetProfile.company,
          isThumb: false
        })
      );
    }
  }, [targetUid, myProfile, profileImageData, targetProfile]);

  const showPasswordResetConfirmation = () => {
    const { firstName, lastName } = state.originalData ?? {};
    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: `prof.${isPin ? 'pin' : 'pw'}ResetTitle` }),
          message: $t(
            { id: `prof.${isPin ? 'pin' : 'pw'}ResetMsg` },
            {
              name: (
                <b key="uob0">
                  {firstName} {lastName}
                </b>
              ),
              nl: <br key="upnl0" />
            }
          ),
          useCheckbox: false,
          show: true
        }).setConfirmAction(Actions.RESET_USER_PASSWORD, { uid: targetUid, isPin })
      })
    );
  };

  /* istanbul ignore next */
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files?.[0];

    if (file) {
      urlDataReader(file, {
        onload: (result) => {
          const src = result
            ? typeof result === 'string'
              ? result
              : decodeText(result)
            : undefined;
          setState({ profilePicFile: src });
        }
      });
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const { firstName: origFirstName, lastName: origLastName } =
      state.originalData ?? /* istanbul ignore next */ {};
    const { permissions, authDomains } = state;

    const isOwn = !paramUid;

    const missingDomains = isEmpty(authDomains);
    const missingPermissions = isEmpty(permissions);
    if (missingDomains || missingPermissions) /* istanbul ignore next */ return;

    const outPermissions = {} as Permissions;
    PermissionsKeys.forEach((perm) => {
      outPermissions[perm] = permissions.includes(perm);
    });

    const data: ParameterMap[Actions.EDIT_USER] = {
      data: {
        uid: targetUid,
        userData: {
          company: state.company,
          firstName: state.firstName,
          lastName: state.lastName,
          employeeId: state.employeeId,
          email: state.email,
          profilePicFile: state.profilePicFile,
          authDomains,
          ...outPermissions
        }
      }
    };

    // Only fill parent if non null, otherwise firestore may fill { parentUid: "undefined" }
    if (!isOwn && state.parentUid) {
      data.parentUid = state.parentUid;
    }

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'prof.profChgTitle' }),
          message: $t(
            { id: isOwn ? 'prof.profChgMsgOwn' : 'prof.profChgMsg' },
            {
              name: (
                <b key="upb1">
                  {origFirstName} {origLastName}
                </b>
              )
            }
          ) as string | JSX.Element,
          useCheckbox: false,
          show: true
        }).setConfirmAction(Actions.EDIT_USER, data)
      })
    );
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleMultipleSelectChange = (e: SelectChangeEvent<string[]>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const userIsMod = isModeratorProf(myProfile);
  const userIsKu = isKeyUserProf(myProfile);
  const targetProfileLoaded = !!targetProfile;

  const waitOnSkeletonGridRow = (content: JSX.Element, ...extraData) =>
    areProfilesLoaded && (!extraData || extraData.every(Boolean)) ? (
      content
    ) : (
      <Grid item xs={12}>
        <Skeleton
          data-testid="user-profile-skeleton-data-field"
          variant="rectangular"
          height={spacing(5)}
          animation="wave"
        />
      </Grid>
    );

  const profileImage =
    state.profilePicFile || (profileImageData && profileImageData.src) || noProfileImage;

  const userProfileView = {
    keyUserOwnProfileView: userIsKu && isOwnProfile,
    keyUserViewOnChildren:
      userIsKu &&
      !isOwnProfile &&
      (targetProfile?.pilot || targetProfile?.assistant || targetProfile?.checker),
    keyUserViewOnMod: userIsKu && !isOwnProfile && targetProfile?.moderator,
    moderatorOwnProfileView: userIsMod && isOwnProfile,
    moderatorView: userIsMod && !isOwnProfile,
    childrenView: !userIsKu && !userIsMod && isOwnProfile
  };

  return redirectDest ? (
    <Navigate replace to={redirectDest} />
  ) : (
    <Container className={classes.container}>
      <div className={classes.titleContainer}>
        {areProfilesLoaded ? (
          targetProfileLoaded ? (
            <>
              <Typography data-testid="user-profile-title" variant="h4" sx={text.title}>
                {isOwnProfile
                  ? $t({ id: 'prof.myTitle' })
                  : $t(
                      { id: 'prof.title' },
                      {
                        name: `${targetProfile.firstName} ${targetProfile.lastName}`
                      }
                    )}
              </Typography>
              <Typography
                data-testid="user-profile-subtitle"
                variant="subtitle1"
                sx={text.subtitle}
              >
                {myProfile?.keyUser
                  ? $t({ id: 'prof.subtitleKu' })
                  : isOwnProfile
                    ? $t({ id: 'prof.subtitleOwn' })
                    : $t({ id: 'prof.subtitleMod' })}
              </Typography>
            </>
          ) : (
            <Typography>Failed loading user profile</Typography>
          )
        ) : (
          <>
            <Skeleton
              data-testid="user-profile-skeleton-title"
              variant="rectangular"
              height={spacing(5)}
              animation="wave"
              className={classes.marginBottom}
            />
            <Skeleton
              data-testid="user-profile-skeleton-subtitle"
              variant="rectangular"
              height={spacing(3)}
              animation="wave"
              className={classes.marginBottom}
            />
          </>
        )}
      </div>
      <Container className={classes.innerContainer}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} className={classes.mainGrid}>
            {/* Profile picture container */}
            <Grid container item spacing={2} direction="column" xs={4} alignItems="center">
              <Grid item>
                {profileImageData?.isLoaded ? (
                  <Avatar
                    data-testid="user-profile-user-picture"
                    src={profileImage}
                    className={classes.profileImage}
                    alt={$t({ id: 'altUserProfileImage' })}
                  />
                ) : (
                  <Skeleton
                    data-testid="user-profile-skeleton-picture"
                    variant="circular"
                    className={cx(classes.profileImage, classes.marginBottom)}
                    animation="wave"
                  />
                )}
              </Grid>
              <Grid item>
                {targetProfileLoaded ? (
                  <>
                    <Box
                      component="input"
                      accept="image/jpeg, image/png"
                      sx={{ display: 'none' }}
                      id="profile-image-btn"
                      type="file"
                      onChange={handleFileChange}
                    />
                    <label htmlFor="profile-image-btn">
                      <Button
                        data-testid="user-profile-change-picture-btn"
                        variant="contained"
                        component="span"
                        disabled={ui.loadingButton.isEditUserLoading}
                      >
                        {$t({ id: 'prof.chgPicture' })}
                      </Button>
                    </label>
                  </>
                ) : (
                  <Skeleton
                    data-testid="user-profile-skeleton-change-pic-btn"
                    variant="rectangular"
                    height={spacing(5)}
                    width={spacing(20)}
                    animation="wave"
                  />
                )}
              </Grid>
            </Grid>
            {/* Profile data */}
            <Grid container item spacing={2} direction="row" xs={8} alignContent="space-between">
              {/* company and moderator */}
              {waitOnSkeletonGridRow(
                <>
                  {userProfileView.keyUserOwnProfileView ? null : (
                    <Grid
                      item
                      xs={
                        userProfileView.moderatorOwnProfileView ||
                        userProfileView.moderatorView ||
                        userProfileView.keyUserViewOnMod ||
                        userProfileView.childrenView
                          ? 12
                          : 6
                      }
                    >
                      <TextField
                        disabled
                        required
                        fullWidth
                        variant="outlined"
                        id="company"
                        data-testid="user-profile-company"
                        name="company"
                        type="text"
                        label={$t({ id: 'company' })}
                        value={state.company}
                        onChange={handleChange}
                      />
                    </Grid>
                  )}

                  {userProfileView.keyUserOwnProfileView ||
                  userProfileView.moderatorOwnProfileView ||
                  userProfileView.moderatorView ||
                  userProfileView.keyUserViewOnMod ||
                  userProfileView.childrenView ? null : (
                    <Grid item xs={6}>
                      <TextField
                        disabled
                        required
                        fullWidth
                        variant="outlined"
                        id="moderator"
                        data-testid="user-profile-moderator"
                        name="moderator"
                        type="text"
                        label={$t({ id: 'moderator' })}
                        value={
                          parentProfile
                            ? `${parentProfile.firstName} ${parentProfile.lastName} (${state.parentUid!})`
                            : ''
                        }
                        onChange={handleChange}
                      />
                    </Grid>
                  )}
                </>
              )}
              {/* first and last names */}
              {waitOnSkeletonGridRow(
                <>
                  <Grid item xs={userProfileView.keyUserOwnProfileView ? 12 : 6}>
                    <TextField
                      disabled={
                        userProfileView.keyUserOwnProfileView || userProfileView.childrenView
                      }
                      required
                      fullWidth
                      variant="outlined"
                      id="firstName"
                      data-testid="user-profile-first-name"
                      name="firstName"
                      type="text"
                      label={$t({ id: 'firstName' })}
                      value={state.firstName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={userProfileView.keyUserOwnProfileView ? 12 : 6}>
                    <TextField
                      disabled={
                        userProfileView.keyUserOwnProfileView || userProfileView.childrenView
                          ? true
                          : false
                      }
                      required
                      fullWidth
                      variant="outlined"
                      id="lastName"
                      data-testid="user-profile-last-name"
                      name="lastName"
                      type="text"
                      label={$t({ id: 'lastName' })}
                      value={state.lastName}
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              )}
              {/* id and email */}
              {waitOnSkeletonGridRow(
                <>
                  <Grid item xs={4}>
                    <TextField
                      disabled={
                        userProfileView.keyUserOwnProfileView || userProfileView.childrenView
                      }
                      required
                      fullWidth
                      variant="outlined"
                      id="employeeId"
                      data-testid="user-profile-employee-id"
                      name="employeeId"
                      type="text"
                      label={$t({ id: 'employeeId' })}
                      value={state.employeeId}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      disabled
                      required
                      fullWidth
                      variant="outlined"
                      id="email"
                      data-testid="user-profile-email"
                      name="email"
                      type="email"
                      label={$t({ id: 'email' })}
                      value={state.email}
                      onChange={handleChange}
                    />
                  </Grid>
                </>
              )}

              {/* permissions */}
              {userProfileView.keyUserOwnProfileView
                ? null
                : waitOnSkeletonGridRow(
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        error={isEmpty(state.permissions)}
                      >
                        <InputLabel id="permissions-label">{$t({ id: 'permissions' })}</InputLabel>
                        <Select
                          labelId="permissions-label"
                          id="permissions"
                          data-testid="user-profile-permissions"
                          name="permissions"
                          multiple
                          disabled={
                            userProfileView.keyUserOwnProfileView || userProfileView.childrenView
                          }
                          value={state.permissions ?? []}
                          onChange={handleMultipleSelectChange}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip
                                  key={value}
                                  label={$t({ id: value })}
                                  className={classes.chip}
                                  disabled={!userIsMod && !userIsKu}
                                />
                              ))}
                            </div>
                          )}
                          MenuProps={select.getMenuProps()}
                          input={<OutlinedInput label={$t({ id: 'permissions' })} />}
                          sx={{ pl: 1 }}
                        >
                          {PermissionsKeys.map((perm) => (
                            <MenuItem key={perm} value={perm} disabled={perm === 'moderator'}>
                              <Checkbox checked={state.permissions.indexOf(perm) > -1} />
                              <ListItemText primary={$t({ id: perm })} />
                              {perm === 'checker' && (
                                <LogoTooltip
                                  tooltip={intl.formatMessage({ id: 'grantsCountidAccess' })}
                                  logo={cidLogo}
                                  iconRootClass={classes.countidIconRootClass}
                                />
                              )}
                              {perm === 'pilot' && (
                                <LogoTooltip
                                  tooltip={intl.formatMessage({ id: 'grantsFlyidAccess' })}
                                  logo={fidLogo}
                                  iconRootClass={classes.flyidIconRootClass}
                                />
                              )}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  )}
            </Grid>

            {/* Auth domains */}
            {userProfileView.keyUserOwnProfileView
              ? null
              : waitOnSkeletonGridRow(
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                      error={isEmpty(state.authDomains)}
                    >
                      <InputLabel id="authdomains-label">{$t({ id: 'authDomains' })}</InputLabel>
                      <Select
                        fullWidth
                        labelId="authdomains-label"
                        id="authdomains"
                        data-testid="user-profile-auth-domains"
                        name="authDomains"
                        multiple
                        disabled={
                          userProfileView.moderatorView || userProfileView.keyUserViewOnChildren
                            ? false
                            : true
                        }
                        value={state.authDomains ?? []}
                        onChange={handleMultipleSelectChange}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((domain) => (
                              <Chip key={`rend${domain}`} label={domain} className={classes.chip} />
                            ))}
                          </div>
                        )}
                        input={<OutlinedInput label={$t({ id: 'authDomains' })} />}
                        MenuProps={select.getMenuProps()}
                      >
                        {allAuthDomains?.map((domain) => (
                          <MenuItem key={`men${domain}`} value={domain}>
                            <Checkbox checked={state.authDomains.indexOf(domain) > -1} />
                            <ListItemText primary={domain} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
            {/* Licenses */}
            {(userProfileView.keyUserViewOnMod || userProfileView.moderatorOwnProfileView) && (
              <>
                <Grid item xs={12} sx={{ pr: 5 }}>
                  <Typography className={classes.authLicensesTitle} variant="caption">
                    {$t({ id: 'authLicenses' })}
                  </Typography>
                </Grid>
                {waitOnSkeletonGridRow(
                  <Grid item xs={12} sx={{ pr: 5 }}>
                    <div>
                      {Object.entries(licenses ?? {}).map(
                        ([licKey, licData]) =>
                          licData && (
                            <Tooltip
                              data-testid="user-profile-auth-licenses"
                              key={licKey}
                              disableTouchListener
                              title={
                                <Typography variant="h6">
                                  {buildLicenseDescription(licData, licKey, intl)}
                                </Typography>
                              }
                            >
                              <Chip
                                label={buildShortLicenseDescription(licData, licKey)}
                                className={classes.chip}
                              />
                            </Tooltip>
                          )
                      )}
                    </div>
                  </Grid>,
                  licenses !== null // null == loading
                )}
              </>
            )}

            {/* Divider */}
            <Grid item xs={12} className={classes.sessionMargin}>
              <Divider />
            </Grid>
            {/* Submit button */}
            <Grid container item direction="row" xs={12} justifyContent="space-between">
              <Grid item>
                {(!usingSSO || isPin) && (
                  <Button
                    data-testid="user-profile-change-password-btn"
                    component={Link}
                    to={isOwnProfile ? `/profile/${isPin ? 'changepin' : 'changepw'}` : '#'}
                    onClick={isOwnProfile ? undefined : showPasswordResetConfirmation}
                    disabled={ui.loadingButton.isEditUserLoading}
                    variant="contained"
                    color="primary"
                  >
                    {$t({ id: `prof.${isOwnProfile ? 'chg' : 'rst'}${isPin ? 'Pin' : 'Pw'}` })}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <LoadingButton
                  data-testid="user-profile-save-changes-btn"
                  isLoading={ui.loadingButton.isEditUserLoading}
                  content={$t({ id: 'saveChanges' })}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Container>
  );
};

export default UserProfile;
