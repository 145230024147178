import { UserPublic } from 'flyid-core/dist/Database/Models';

export function groupByCompany<T extends { company: string } | undefined>(data: {
  [id: string]: T;
}) {
  const dataByCompany: { [company: string]: { [id: string]: T } | undefined } = {};
  Object.entries(data).forEach(([key, obj]) => {
    if (obj) {
      const company: string = obj.company;
      if (dataByCompany[company]) {
        dataByCompany[company][key] = obj;
      } else {
        dataByCompany[company] = { [key]: obj };
      }
    }
  });

  return dataByCompany;
}

export function groupUsersByParent(
  usersData: { [uid: string]: UserPublic | undefined } | undefined
) {
  if (!usersData) return;

  const userProfiles = usersData;

  const usersByParents: { [moderators: string]: [string, UserPublic][] } = {
    moderators: []
  };

  Object.entries(userProfiles).forEach(([uid, userData]) => {
    const parent = userData?.parent;
    if (!parent && userData?.moderator) {
      usersByParents.moderators.push([uid, userData]);
    } else if (parent) {
      if (Array.isArray(usersByParents[parent])) {
        usersByParents[parent].push([uid, userData]);
      } else {
        usersByParents[parent] = [[uid, userData]];
      }
    } else {
      console.log('failed selecting user:', userData);
    }
  });

  usersByParents.moderators.sort(([, userData0], [, userData1]) =>
    `${userData0.firstName} ${userData0.lastName}`.localeCompare(
      `${userData1.firstName} ${userData1.lastName}`
    )
  );

  usersByParents.moderators.forEach(([parentUid]) => {
    const parentsUsers = usersByParents[parentUid];
    if (Array.isArray(parentsUsers)) {
      parentsUsers.sort(([, userData0], [, userData1]) =>
        `${userData0.firstName} ${userData0.lastName}`.localeCompare(
          `${userData1.firstName} ${userData1.lastName}`
        )
      );
    }
  });

  return usersByParents;
}
