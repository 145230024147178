import { cx } from '@emotion/css';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LanguageIcon from '@mui/icons-material/Language';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MenuIcon from '@mui/icons-material/Menu';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import {
  AppBar,
  Avatar,
  Box,
  CardMedia,
  Collapse,
  Container,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Skeleton,
  Theme,
  Toolbar,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { UserState } from 'flyid-core/dist/Redux';
import { RecordKey } from 'flyid-core/dist/Util/types';
import React, { Fragment, PropsWithChildren, ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, LinkProps } from 'react-router-dom';
import useMuiBreakpoints from '../hooks/useMuiDimensions';

const useStyles = <T extends Theme = Theme>(drawerWidth = '280px') =>
  makeStyles((theme: T) => ({
    root: {
      display: 'flex',
      height: '100vh'
    },
    grow: {
      flexGrow: 1
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0
      }
    },
    drawerSpacing: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth})`
      }
    },
    toolbar: {
      padding: theme.spacing(0, 2)
    },
    toolbarContent: {
      ...theme.mixins.toolbar,
      marginTop: theme.spacing(1)
    },
    drawerPaper: {
      width: drawerWidth,
      borderRight: `1px solid ${theme.palette.primary.dark}`
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      padding: theme.spacing(1)
    },
    mainContent: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexGrow: 1
    },
    logoContainer: {
      padding: theme.spacing(1, 0, 1, 0)
    },
    logo: {
      width: 136,
      maxHeight: 60
    },
    userBtnsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      position: 'absolute',
      right: theme.spacing(1),
      width: 'auto',
      padding: 0
    },
    userProfileContainer: {
      padding: theme.spacing(2, 4),
      height: 185,
      backgroundColor: theme.palette.primary.dark
    },
    userProfileContainerExtended: {
      padding: theme.spacing(2, 4),
      height: 240,
      backgroundColor: theme.palette.primary.dark
    },
    userProfile: {
      marginTop: theme.spacing(0.75)
    },
    avatar: {
      width: 100,
      height: 100
    },
    greyColor: {
      color: theme.palette.grey['300']
    },
    squareLogo: {
      position: 'absolute',
      width: 90,
      height: 100,
      right: theme.spacing(2),
      top: theme.spacing(2),
      borderRadius: 10
    },
    nestedItems: {
      paddingLeft: theme.spacing(4)
    },
    progressCircle: {
      margin: theme.spacing(4)
    },
    languageText: { marginLeft: theme.spacing(1) },
    notLoggedMargin: { marginTop: theme.spacing(2) },
    companySelectContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    companySelect: {
      backgroundColor: 'white',
      minWidth: 120
    }
  }))();

export type NavGroups = {
  key: string;
  name: string;
  icon: ReactElement;
  children: NavRoute[];
}[];

export type NavRoute = {
  name: string;
  path?: string;
  action?: () => void;
  icon?: ReactElement;
  listItemButtonProps?: Omit<ListItemButtonProps, 'component'> &
    Partial<Omit<LinkProps, 'component'>> & { component?: JSX.Element | string; href?: string };
};

type SupportedLocales<SupportedLocalesList extends readonly string[]> = {
  supportedLocales: SupportedLocalesList;
  readableLocales: Record<SupportedLocalesList[number], string>;
  onLocaleChange: (locale: SupportedLocalesList[number]) => void;
};

export type CompanySelector = {
  selectedCompany: string;
  availableCompanies: Record<string, string>;
  onCompanyChange: (company: string) => void;
};

function NavBar<
  /* eslint-disable @typescript-eslint/no-explicit-any */
  UserProfile extends Record<RecordKey, any>,
  Claims extends Record<RecordKey, any>,
  SupportedLocalesList extends readonly string[]
  /* eslint-enable @typescript-eslint/no-explicit-any */
>(
  props: PropsWithChildren<{
    userData: UserState<UserProfile, Claims>;
    routes: NavGroups;
    signedIn: boolean;
    logoutAction: () => void;
    logo: string;
    noImg: string;
    squareLogo: string;
    localesData?: SupportedLocales<SupportedLocalesList>;
    selectedCompany?: string;
    companySelector?: CompanySelector;
  }>
) {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [openedGroup, setOpenedGroup] = useState<string | null>(null);
  const { $t, locale } = useIntl();

  const { signedIn, children, localesData, userData, companySelector } = props;
  const useCompanySelector = !!companySelector;

  const classes = useStyles();

  const { uid: selfUid, profile, isLoaded: isProfileDoneLoading, profileError } = userData;
  const isProfileLoaded = isProfileDoneLoading && !profileError;
  const profileImageData = selfUid ? userData.profilePics[selfUid] : undefined;
  const hideSideNav = !signedIn || !isProfileDoneLoading || !!profileError;

  const handleDrawerToggle = () => setMobileOpen((prevState) => !prevState);

  const handleOpenGroupChange = (group: string) =>
    setOpenedGroup((prevState) => {
      if (prevState !== null && group === prevState) {
        return null;
      }
      return group;
    });

  const handleLanguageMenu = (e: React.MouseEvent) => setAnchorEl(e.currentTarget);

  const handleLanguageMenuClose = (lang?: string) => {
    setAnchorEl(null);
    if (lang) localesData?.onLocaleChange(lang);
  };

  const toolbar = (
    <Toolbar className={classes.toolbar}>
      <IconButton
        sx={{ display: { md: 'none', xs: 'block' } }}
        color="inherit"
        aria-label="open drawer"
        data-testid="drawer-btn"
        edge="start"
        onClick={handleDrawerToggle}
        size="large"
      >
        <MenuIcon />
      </IconButton>

      {/* Spacing */}
      <div className={classes.grow} />

      {/* Company logo */}
      <MenuItem component={Link} to="/" className={classes.logoContainer}>
        <CardMedia
          component="img"
          alt={$t({ id: 'altCompanyLogo' })}
          className={classes.logo}
          image={props.logo}
          data-testid="company-logo"
          title="Panorama.id - Fly.id"
        />
      </MenuItem>

      {/* Spacing */}
      <div className={classes.grow} />

      {/* Translation and login/logout buttons */}
      <Grid
        container
        direction="row"
        wrap="nowrap"
        sx={{ width: '250px', mr: 0 }}
        alignItems="center"
        justifyContent="end"
      >
        {(localesData?.supportedLocales.length ?? 0) > 1 && (
          <Grid item xs="auto">
            <IconButton
              aria-label="Language change"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleLanguageMenu}
              color="inherit"
              size="large"
              data-testid="change-language"
            >
              <LanguageIcon />
              <Typography variant="body2" className={classes.languageText}>
                {locale.toUpperCase()}
              </Typography>
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={Boolean(anchorEl)}
              onClose={() => handleLanguageMenuClose()}
            >
              {localesData?.supportedLocales.map((_locale: SupportedLocalesList[number]) => (
                <MenuItem key={_locale} onClick={() => handleLanguageMenuClose(_locale)}>
                  {localesData?.readableLocales[_locale]}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
        )}
        <Grid item xs="auto" sx={{ display: { xs: 'none', md: 'block' } }}>
          {signedIn && (
            <MenuItem
              color="inherit"
              onClick={props.logoutAction}
              key={`userBtns-logout`}
              data-testid="userBtns-logout"
            >
              {$t({ id: 'logout' })}
            </MenuItem>
          )}
        </Grid>
      </Grid>
    </Toolbar>
  );

  const windowSize = useMuiBreakpoints();
  const isWindowSizeSmall = windowSize === 'sm' || windowSize === 'xs';
  const renderSidebarNav = () => (
    <Drawer
      classes={{
        paper: classes.drawerPaper
      }}
      variant={isWindowSizeSmall ? 'temporary' : 'permanent'}
      open={!isWindowSizeSmall || mobileOpen}
      onClose={isWindowSizeSmall ? handleDrawerToggle : undefined}
      ModalProps={{
        keepMounted: isWindowSizeSmall // Better open performance on mobile.
      }}
    >
      <Container
        className={
          useCompanySelector ? classes.userProfileContainerExtended : classes.userProfileContainer
        }
      >
        {isProfileLoaded ? (
          <>
            {!selfUid || (profileImageData && !profileImageData.isLoading) ? (
              <Avatar
                data-testid="navbar-profile-picture"
                alt={$t({ id: 'altUserProfileImage' })}
                src={profileImageData?.src ?? props.noImg}
                className={classes.avatar}
              />
            ) : (
              <Skeleton
                variant="circular"
                className={cx(classes.greyColor, classes.avatar)}
                animation="wave"
                data-testid="skeleton-profile-img"
              />
            )}
            {signedIn ? (
              <>
                <Typography
                  color="secondary"
                  aria-label="userName"
                  className={classes.userProfile}
                  data-testid="username"
                  noWrap
                >
                  {profile.firstName} {profile.lastName}
                </Typography>
                <Typography
                  aria-label="email"
                  data-testid="email"
                  className={cx(classes.greyColor, classes.userProfile)}
                  noWrap
                >
                  {profile?.email}
                </Typography>
                {useCompanySelector ? (
                  <FormControl className={classes.companySelectContainer} size="small">
                    <Select
                      id="nav-key-user-company-select"
                      data-testid="company-select"
                      className={classes.companySelect}
                      value={companySelector.selectedCompany}
                      onChange={(e) => companySelector.onCompanyChange(e.target.value)}
                    >
                      {Object.entries(companySelector.availableCompanies).map(
                        ([company, exhibitionName], index) => {
                          return (
                            <MenuItem key={`${company}${index}`} value={company}>
                              {exhibitionName}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </FormControl>
                ) : null}
              </>
            ) : (
              <Typography
                color="secondary"
                className={cx(classes.userProfile, classes.notLoggedMargin)}
                data-testid="notsigned"
              >
                {$t({ id: 'notSigned' })}
              </Typography>
            )}
            <CardMedia component="img" src={props.squareLogo} className={classes.squareLogo} />
          </>
        ) : (
          <>
            <Skeleton
              variant="circular"
              className={cx(classes.greyColor, classes.avatar)}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              className={cx(classes.greyColor, classes.userProfile)}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              className={cx(classes.greyColor, classes.userProfile)}
              animation="wave"
            />
            <Skeleton
              variant="rectangular"
              className={cx(classes.greyColor, classes.squareLogo)}
              animation="wave"
            />
          </>
        )}
      </Container>

      {/* Routes */}
      {signedIn ? (
        <>
          <List>
            {props.routes.map((group) => (
              <Fragment key={group.key}>
                {/* Group */}
                <ListItemButton onClick={() => handleOpenGroupChange(group.key)}>
                  <ListItemIcon>{group.icon}</ListItemIcon>
                  <ListItemText primary={group.name} />
                  {group.key === openedGroup ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                {/* Children */}
                <Collapse in={group.key === openedGroup} timeout="auto" unmountOnExit>
                  {group.children.map((route, index) => (
                    <ListItemButton
                      key={index}
                      component={route.path ? Link : 'div'}
                      to={route.path}
                      color="inherit"
                      className={classes.nestedItems}
                      onClick={() => {
                        route.action?.();
                      }}
                      {...((route.listItemButtonProps as unknown) ?? {})}
                    >
                      <ListItemIcon>{route.icon}</ListItemIcon>
                      <ListItemText primary={route.name} />
                    </ListItemButton>
                  ))}
                </Collapse>
              </Fragment>
            ))}

            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <>
                <ListItemButton color="inherit" onClick={props.logoutAction}>
                  <ListItemIcon>
                    <MeetingRoomIcon />
                  </ListItemIcon>
                  <ListItemText primary={$t({ id: 'logout' })} />
                </ListItemButton>
              </>
            </Box>
          </List>
        </>
      ) : (
        <ListItemButton
          key={0}
          component={Link}
          to="/login"
          className={classes.nestedItems}
          onClick={isWindowSizeSmall ? handleDrawerToggle : undefined}
        >
          <ListItemIcon>
            <VpnKeyIcon />
          </ListItemIcon>
          <ListItemText primary={$t({ id: 'login' })} />
        </ListItemButton>
      )}
    </Drawer>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={!hideSideNav ? classes.drawerSpacing : undefined}>
        {toolbar}
      </AppBar>

      {!hideSideNav && (
        <Box component="nav" className={classes.drawer}>
          {renderSidebarNav()}
        </Box>
      )}

      <Box
        component="main"
        className={cx(classes.mainContainer, !hideSideNav ? classes.drawerSpacing : undefined)}
      >
        <div className={classes.toolbarContent} />
        <div className={classes.mainContent}>{children}</div>
      </Box>
    </div>
  );
}
export default NavBar;
