import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  TableRow as MuiTableRow,
  Switch,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  Toolbar,
  Typography
} from '@mui/material';
import { debounce } from 'debounce';
import { parseDateFromTimestamp, TimestampLike } from 'flyid-core/dist/Util/time';
import { storeEventCheck } from 'flyid-core/dist/Util/web';
import useStoredState from 'flyid-ui-components/dist/hooks/useStoredState';
import ConditionalWrapper from 'flyid-ui-components/dist/utils/ConditionalWrapper';
import React, {
  ChangeEvent,
  Fragment,
  JSX,
  useCallback,
  useEffect,
  useMemo,
  useState,
  DependencyList
} from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { searchData, stableSort } from 'src/util/helpers/sorting';
import { formatTimeDate } from 'src/util/helpers/string';
import { HeaderCellObject, Order, TableColumns, TableRow, TableRows } from 'src/util/helpers/table';
import BadRequest from '../../widgets/BadRequest';
import LoadingCircle from '../../widgets/LoadingCircle';
import EnhancedTableHead from './EnhancedTableHead';
import EnhancedTableToolbar, { EnhancedTableToolbarOptions } from './EnhancedTableToolbar';
import { isTranslatableError, TranslatableError } from 'flyid-core/dist/Util/exceptions';

const useStyles = appMakeStyles(({ spacing, resizableContainer }) => ({
  root: {
    width: '100%'
  },
  container: { ...resizableContainer(1), maxWidth: '100%' },
  table: {
    minWidth: 710
  },
  tableWrapper: {
    overflowX: 'auto',
    borderRadius: 10,
    height: '100%'
  },
  feedbackMargin: {
    marginTop: spacing(4)
  },
  bottomToolbar: {
    padding: spacing(0, 1, 0, 1)
  },
  grow: {
    flexGrow: 1
  },
  extraSelectorsFormGroup: {
    display: 'flex',
    flexDirection: 'row',
    margin: spacing(2)
  },
  inputDataCell: {
    width: 100
  }
}));

export type Selector = {
  label: string;
  control: JSX.Element;
};

type RowColumnCallback<T> = (row: TableRow, column: HeaderCellObject) => T;
type LinkData = {
  linkPath: string;
};
type ButtonData = {
  onClick: RowColumnCallback<void>;
};
export type OrderChangeCallback = (order: Order, orderBy: string) => void;
export type OrderData = { order: Order; orderBy: string | null };

type Props<RowType = TableRow, ColumnType = HeaderCellObject<RowType>> = {
  tableId: string;
  selectionColumn: string;
  dataLoaded: boolean;
  data: { rows: RowType[]; columns: ColumnType[] } | TranslatableError | undefined;
  title?: string;
  toolbarOptions?: EnhancedTableToolbarOptions;
  bottomToolbarOptions?: {
    extraSelectors?: Selector[];
  };
  clickAction?: RowColumnCallback<LinkData | ButtonData | undefined>;
  missingDataAlternative?: () => JSX.Element;
  doNotContainerize?: boolean;
  orderData?: OrderData;
  onOrderChange?: OrderChangeCallback;
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  totalCount?: number;
  forceTableResetChange?: DependencyList;
};

const clickActionIsLink = (data: LinkData | ButtonData | undefined): data is LinkData =>
  Boolean((data as LinkData | undefined)?.linkPath);

const EnhancedTable: React.FC<Props> = React.memo((props) => {
  const classes = useStyles();
  const theme = useAppTheme();
  const intl = useIntl();
  const $t = intl.$t;

  // Configs
  const { tableId, orderData, selectionColumn, onOrderChange } = props;
  if (orderData && !onOrderChange) {
    throw Error(
      "When 'orderData' is provided (order is controlled), the panret must implement and provide " +
        "'onOrderChange' in order to control the state"
    );
  }
  const [order, setOrder] = orderData
    ? [orderData.order, undefined]
    : useStoredState<Order>(`${tableId}order`, 'asc');
  const [orderBy, setOrderBy] = orderData
    ? [orderData.orderBy, undefined]
    : useStoredState<string | undefined>(`${tableId}orderBy`, undefined);
  const [dense, setDense] = useStoredState(`${tableId}dense`, false);
  const [rowsPerPage, setRowsPerPage] = useStoredState<number>(`${tableId}rowsPerPage`, 10);
  // States
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState<string[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [searchFilteredRows, setSearchFilteredRows] = useState<TableRows>([]);

  // Whenever input data changes, update table data
  const { dataIsEmpty, rows, columns, dataError } = useMemo(() => {
    if (isTranslatableError(props.data)) {
      return { dataIsEmpty: true, rows: [], columns: [], dataError: props.data };
    }

    const { rows: inRows, columns: inColumns } = props.data ?? {};
    const dataIsEmpty = !(inRows?.length && inColumns?.length);
    return { dataIsEmpty, rows: inRows ?? [], columns: inColumns ?? [] };
  }, [props.data]);

  const handleRequestSort = (property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    const newOrder = isDesc ? 'asc' : 'desc';

    // If uncontrolled, handle order internally
    if (!orderData) {
      setOrder!(newOrder);
      setOrderBy!(property);
    }
    // If controlled, leave it for parent
    onOrderChange?.(newOrder, property);
  };

  const onSearchChange = (inSearchText: string) => {
    setSearchText(inSearchText);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    const _rows = searchText ? searchFilteredRows : rows;

    let newSelected: string[] = [];
    if (event.target.checked && !dataIsEmpty) {
      newSelected = _rows.map((n) => n[selectionColumn] as string);
    }

    setSelected(newSelected);
  };

  const handleSelectClick = (columnId: string) => {
    const selectedIndex = selected.indexOf(columnId);
    let newSelected: string[] = [];

    if (selectedIndex === -1) {
      // Not selected yet, add to selection
      newSelected = newSelected.concat(selected, columnId);
    } else {
      // Already selected, filter it out
      newSelected = selected.filter((_, idx) => idx !== selectedIndex);
    }
    setSelected(newSelected);
  };

  const renderBottomToolbarExtraSelectors = () =>
    props.bottomToolbarOptions?.extraSelectors?.map((selector) => (
      <FormControlLabel
        control={selector.control}
        label={selector.label}
        key={`selector${selector.label}`}
      />
    )) ?? null;

  const isSelected = (column: string) => selected.indexOf(column) !== -1;

  const debouncedSearch = useCallback(
    debounce((_searchText: string, _dataIsEmpty: boolean) => {
      if (_searchText && !_dataIsEmpty) {
        setSearchFilteredRows(searchData(rows, columns, _searchText, intl));
        if (page !== 0) {
          console.log('resetting page');
          setPage(0);
          if (props.onPageChange) props.onPageChange(0);
        }
      }
    }, 300),
    [props.onPageChange, setPage, setSearchFilteredRows, searchData, page, rows, columns, intl]
  );

  // Debounce search changes
  useEffect(() => {
    debouncedSearch(searchText, dataIsEmpty);
    return () => debouncedSearch.clear();
  }, [searchText, page, rows, columns, dataIsEmpty]);

  // Clear selection whenever data changes (mainly from session removal)
  useEffect(() => setSelected([]), [props.data]);

  // Optional prop can be passed to force a reset of table's default state
  useEffect(() => {
    setPage(0);
    setRowsPerPage(rowsPerPage);
    if (props.onPageChange) props.onPageChange(0);
    if (props.onPageSizeChange) props.onPageSizeChange(rowsPerPage);
  }, props.forceTableResetChange);

  // Prepare data for plotting
  const filteredRows = searchText ? searchFilteredRows : rows;
  const sortedRows = filteredRows
    ? orderBy
      ? stableSort(filteredRows, order, orderBy)
      : filteredRows
    : [];

  const renderRowDataCells = (row: TableRow, inColumns: TableColumns, labelId: string) =>
    inColumns.map((column, index) => {
      const { clickAction } = props;
      // Click action that may be associated with this column
      const clickActionData = clickAction?.(row, column);
      // There is a clickAction associated with this column
      const clickableColumn = !!clickActionData;
      // Whether the clickAction is a link or a button
      const actionIsLink = clickableColumn && clickActionIsLink(clickActionData);

      const columnValue = column.isDate
        ? formatTimeDate(
            parseDateFromTimestamp(row[column.id] as TimestampLike),
            intl,
            column.dateFormat
          )
        : row[column.id];

      const hasCustomContent = !!column.dataContentGetter;
      const tableCellContent = (
        <Box
          component={clickableColumn && !hasCustomContent ? (actionIsLink ? Link : Button) : 'span'}
          to={actionIsLink ? clickActionData.linkPath : undefined}
          onClick={
            clickableColumn && !actionIsLink
              ? () => clickActionData.onClick(row, column)
              : undefined
          }
        >
          {column.dataContentGetter?.(row) ?? columnValue}
        </Box>
      );

      return (row[column.id] || hasCustomContent) && column.id.length ? (
        index === 0 ? (
          <TableCell
            component="th"
            align="center"
            id={labelId}
            scope="row"
            padding="none"
            key={`${column.id}${index}`}
            {...column.dataCellProps}
          >
            {tableCellContent}
          </TableCell>
        ) : (
          <TableCell align="center" key={`${column.id}${index}`} {...column.dataCellProps}>
            {tableCellContent}
          </TableCell>
        )
      ) : (
        <TableCell key={`${column.id}${index}`} />
      );
    });

  const renderTableBody = () => (
    <TableBody>
      {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
        const selectionColumnId = row[selectionColumn] as string;
        // First column value should never be Date!
        const isItemSelected = isSelected(selectionColumnId);
        const labelId = `etcb${index}`;

        return (
          <MuiTableRow
            hover
            role="checkbox"
            data-testid="checkbox-item"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={`${selectionColumnId}${index}`}
            selected={isItemSelected}
          >
            {/* Checkbox cell */}
            <TableCell padding="checkbox" onClick={() => handleSelectClick(selectionColumnId)}>
              <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
            </TableCell>
            {/* Other cells */}
            {renderRowDataCells(row, columns, labelId)}
          </MuiTableRow>
        );
      })}
    </TableBody>
  );

  const renderBottomToolbar = () => (
    <Toolbar className={classes.bottomToolbar}>
      <FormControl component="fieldset">
        <FormGroup className={classes.extraSelectorsFormGroup}>
          <FormControlLabel
            control={
              <Switch checked={dense} onChange={storeEventCheck(`${tableId}dense`, setDense)} />
            }
            data-testid="dense-switch"
            label={$t({ id: 'et.dense' })}
          />

          {renderBottomToolbarExtraSelectors()}
        </FormGroup>
      </FormControl>

      <div className={classes.grow} />

      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 25, 50]}
        component="div"
        count={props.totalCount ? props.totalCount : filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_e, newPage) => {
          setPage(newPage);

          if (props.onPageChange) props.onPageChange(newPage);
        }}
        onRowsPerPageChange={(e) => {
          setRowsPerPage(parseInt(e.target.value, 10));
          if (props.onPageSizeChange) props.onPageSizeChange(parseInt(e.target.value, 10));
          setPage(0);
          if (props.onPageChange) props.onPageChange(0);
        }}
      />
    </Toolbar>
  );

  return (
    <div className={classes.root}>
      <ConditionalWrapper
        key={'ET-CW'}
        condition={!props.doNotContainerize}
        wrapper={(children) => (
          <Container key={'ET-CW-Child'} className={classes.container}>
            {children}
          </Container>
        )}
      >
        <Fragment key={'ET-CW-Fragment'}>
          {props.title && (
            <Typography variant="h4" sx={theme.text.title} data-testid="domain-title">
              {props.title}
            </Typography>
          )}
          <EnhancedTableToolbar
            key="EnhancedTableToolbar"
            {...props.toolbarOptions}
            selected={selected}
            rows={sortedRows}
            columns={columns}
            search={{
              searchText,
              onSearchChange
            }}
          />
          {props.dataLoaded ? (
            !dataIsEmpty && !dataError ? (
              <>
                <div className={classes.tableWrapper}>
                  <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    data-testid="table-title"
                    size={dense ? 'small' : 'medium'}
                    aria-label="enhanced table"
                  >
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy ?? undefined}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      selectedCount={selected.length}
                      rowsCount={filteredRows.length}
                      columns={columns}
                    />
                    {renderTableBody()}
                  </Table>
                </div>
                {renderBottomToolbar()}
              </>
            ) : (
              <div className={classes.feedbackMargin}>
                <BadRequest
                  text={$t(
                    { id: dataError ? 'dataLoadingError' : 'noData' },
                    dataError && { error: $t({ id: dataError.msgCode }, dataError.args) }
                  )}
                  extraView={props.missingDataAlternative}
                />
              </div>
            )
          ) : (
            <div className={classes.feedbackMargin}>
              <LoadingCircle />
            </div>
          )}
        </Fragment>
      </ConditionalWrapper>
    </div>
  );
});

export default EnhancedTable;
