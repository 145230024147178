import { cx } from '@emotion/css';
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import { isNonNulli } from 'flyid-core/dist/Util/helpers';
import { debounce, isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { useAuth } from 'src/hooks/useAuth';
import useGlobalState from 'src/hooks/useGlobalState';
import useStateReducer from 'src/hooks/useStateReducer';
import { addUser, AddUserParams } from 'src/redux/actions/userActions';
import { updateUi } from 'src/redux/reducers/uiReducer';
import { selectCompanyExhibitionName } from 'src/redux/selectors/dataSelectors';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import {
  selectAuthDomains,
  selectCurrentUserProfile,
  selectUserProfiles
} from 'src/redux/selectors/userSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { filterModerators } from 'src/util/helpers/user';
import cidLogo from '../../assets/images/logo/monograma_cid.png';
import fidLogo from '../../assets/images/logo/monograma_flyid.png';
import { groupByCompany } from '../utils/GroupBy';
import LoadingButton from '../widgets/LoadingButton';
import LoadingCircle from '../widgets/LoadingCircle';
import LogoTooltip from '../widgets/LogoTooltip';

const useStyles = appMakeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0)
  },
  title: {
    color: theme.other.grey.dark
  },
  container: {
    ...theme.resizableContainer(2),
    marginLeft: 0,
    maxWidth: '800px'
  },
  hidden: {
    display: 'none'
  },
  countidIconRootClass: {
    backgroundColor: theme.palette.primary.main
  },
  flyidIconRootClass: {
    backgroundColor: theme.palette.primary.dark
  }
}));

/* istanbul ignore next */
const isAllowedEmailCharacter = (str: string) => /^[a-zA-Z0-9+_.-]*$/.test(str);
const getFakeEmailDomain = (company: string) =>
  `@${company?.replace(new RegExp('_', 'g'), '.')}.flyid.com`;

type UserFormData = {
  company: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  permissions: string[];
  authDomains: string[];
  email: string;
  password: string;
  confirmation: string;
  lacksInstitutionalEmail: boolean;
};

const initialUserData: UserFormData = {
  company: '',
  firstName: '',
  lastName: '',
  employeeId: '',
  permissions: [],
  authDomains: [],
  email: '',
  password: '',
  confirmation: '',
  lacksInstitutionalEmail: false
};
const initialPermissions = {
  missingPermissions: false,
  missingDomains: false,
  showConfirmation: false
};

const KEY_PILOT = 'pilot';
const KEY_CHECKER = 'checker';
const KEY_ASSISTANT = 'assistant';
const KEY_MODERATOR = 'moderator';

type UserPermissionsData = typeof initialPermissions;
type State = UserFormData & UserPermissionsData;

const getEmailSuggestion = (firstName: string, lastName: string) =>
  firstName && lastName ? `${firstName.toLowerCase()}.${lastName.toLowerCase()}` : '';

/**
 * Some lines have the comment "istanbul ignore next",
 * this is related to the Instanbul library for testing
 * support. It doesn't affect the code itself, only the
 * test coverage for this component.
 */

const AddUser: React.FC = () => {
  const [state, _setState] = useStateReducer<State>(
    Object.assign({}, initialUserData, initialPermissions)
  );

  const { $t } = useIntl();
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const { usingSSO } = useAuth();

  /* Key user configuration */
  const [targetParentUid, _setTargetParentUid] = useGlobalState('targetParentUid');
  const setTargetParentUid = (targetParent: string) => {
    // On parent uid change, reset auth domains
    setState({ authDomains: [] });
    _setTargetParentUid(targetParent);
  };

  const { profile, ui, userProfiles, targetCompany, authDomains, companyName } = useAppSelector(
    (s) => {
      const targetCompany = selectTargetCompany(s);
      const profile = selectCurrentUserProfile(s);
      const isKeyUser = !!profile?.keyUser;
      return {
        targetCompany,
        profile,
        ui: s.ui,
        companyName: selectCompanyExhibitionName(targetCompany, s),
        userProfiles: selectUserProfiles(s),
        authDomains: selectAuthDomains(s, { targetUser: isKeyUser ? targetParentUid : undefined })
      };
    }
  );

  /** Apply effects to state setting */
  /* istanbul ignore next */
  const setState = (data: Partial<State>) => {
    const withEffects = { ...data };
    if (data.permissions?.includes(KEY_MODERATOR)) {
      // Make sure instituiconal email is mandatory
      if (state.lacksInstitutionalEmail) withEffects.lacksInstitutionalEmail = false;
      // Clear authDomains
      if (state.authDomains?.length) withEffects.authDomains = [];
    }
    _setState(withEffects);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!profile || !targetCompany) /* istanbul ignore next */ return;

    const missingDomains = !state.authDomains.length && !state.permissions?.includes(KEY_MODERATOR);
    const missingPermissions = !state.permissions.length;
    /* istanbul ignore next */
    if (missingDomains || missingPermissions) {
      setState({ missingPermissions, missingDomains });
      return;
    }

    // 6 digit password check if pilot
    if (!usingSSO) {
      const userPerm = state.permissions;
      const isPilotOrChecker = userPerm.includes(KEY_PILOT) || userPerm.includes(KEY_CHECKER);
      if (state.password) {
        if (isPilotOrChecker && !/^\d{6}$/.test(state.password)) {
          dispatch(
            updateUi({
              snackbar: {
                message: $t({ id: 'err.pilotPassword' }),
                severity: 'error',
                show: true
              }
            })
          );
          return;
        }
        if (state.password !== state.confirmation) {
          dispatch(
            updateUi({
              snackbar: {
                message: $t({ id: 'err.pwNoMatch' }),
                severity: 'error',
                show: true
              }
            })
          );
          return;
        }
      }
    }

    const userData: AddUserParams = {
      company: targetCompany,
      email: state.email + (state.lacksInstitutionalEmail ? getFakeEmailDomain(targetCompany) : ''),
      employeeId: state.employeeId,
      firstName: state.firstName,
      lastName: state.lastName,
      authDomains: state.authDomains,
      usesAuthProvider: usingSSO,
      //Permissions
      assistant: state.permissions.includes(KEY_ASSISTANT),
      pilot: state.permissions.includes(KEY_PILOT),
      checker: state.permissions.includes(KEY_CHECKER),
      moderator: state.permissions.includes(KEY_MODERATOR),
      lacksInstitutionalEmail: state.lacksInstitutionalEmail
    };

    if (!usingSSO) {
      userData.password = state.password;
      userData.confirmation = state.confirmation;
    }

    dispatch(addUser(userData));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { firstName, lastName } = state;
    const data: Partial<State> = { [event.target.name]: event.target.checked };
    if (event.target.name === 'lacksInstitutionalEmail') {
      data.email = Boolean(event.target.checked && firstName && lastName)
        ? getEmailSuggestion(firstName, lastName)
        : '';
    }
    setState(data);
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const showConfirmation =
      e.target.name === 'password'
        ? e.target.value.length > 0
          ? true
          : false
        : state.showConfirmation;

    const sideEffects: Partial<State> = {};
    if (state.lacksInstitutionalEmail) {
      /* istanbul ignore next */
      switch (e.target.name as keyof State) {
        case 'email': {
          if (!isAllowedEmailCharacter(e.target.value)) {
            updateUi({
              snackbar: {
                message: $t({ id: 'err.invalidEmailCharacter' }),
                severity: 'error',
                show: true
              }
            });
            return;
          }
          break;
        }
        case 'firstName':
          if (isAllowedEmailCharacter(e.target.value)) {
            sideEffects.email = getEmailSuggestion(e.target.value, state.lastName);
          }
          break;
        case 'lastName':
          if (isAllowedEmailCharacter(e.target.value)) {
            sideEffects.email = getEmailSuggestion(state.firstName, e.target.value);
          }
          break;
        default:
          break;
      }
    }

    setState({
      [e.target.name]: e.target.value,
      ...sideEffects,
      showConfirmation
    });
  };

  const handleMultipleSelectChange = (e: SelectChangeEvent<string[]>) => {
    setState({ [e.target.name]: e.target.value });
  };

  /* istanbul ignore next */
  const companyModerators = useMemo(() => {
    if (userProfiles && targetCompany) {
      const companyProfiles = groupByCompany(userProfiles)[targetCompany];
      return filterModerators(companyProfiles);
    }
    // Company moderators should only be defined when user profiles are done loading.
    return undefined;
  }, [targetCompany, userProfiles]);

  const isKeyUserMissingMods =
    !!profile?.keyUser && isNonNulli(companyModerators) && isEmpty(companyModerators);

  // Debounce the effect of forcing usage of a moderator to give some time for data fetching.
  // This way we don't have to change the whole firestore redux structure, caveat is that
  // timing behaves differently in different network conditions.
  /* istanbul ignore next */
  const debouncedStateChangeEffect = useCallback(
    debounce((_isKeyUserMissingMods) => {
      if (_isKeyUserMissingMods) {
        setState({
          lacksInstitutionalEmail: false,
          authDomains: [],
          permissions: [KEY_MODERATOR]
        });
      }
    }, 300),
    [setState]
  );
  useEffect(() => {
    debouncedStateChangeEffect(isKeyUserMissingMods);
  }, [isKeyUserMissingMods]);

  const isModSelected = state.permissions.includes(KEY_MODERATOR);
  const permissionsRef = profile?.keyUser
    ? [KEY_PILOT, KEY_CHECKER, KEY_ASSISTANT, KEY_MODERATOR]
    : [KEY_PILOT, KEY_CHECKER, KEY_ASSISTANT];

  return (
    <Container className={classes.container}>
      {isNonNulli(companyModerators) ? (
        <form onSubmit={handleSubmit}>
          <Typography
            data-testid="title"
            variant="h4"
            className={cx(classes.margin, classes.title)}
          >
            {profile?.keyUser
              ? $t({ id: 'addUsr.kuTitle' }, { companyName })
              : $t({ id: 'addUsr.title' })}
          </Typography>
          {profile?.keyUser && !targetCompany ? (
            /* istanbul ignore next */
            <Typography
              data-testid="add-user-subt-select-company"
              variant="subtitle1"
              sx={theme.text.subtitle}
            >
              {$t({ id: 'keyUserCompany.warning' })}
            </Typography>
          ) : (
            <>
              {profile?.keyUser && !isKeyUserMissingMods && !isModSelected ? (
                <FormControl required fullWidth className={classes.margin}>
                  <InputLabel id="parentUid-label">{$t({ id: 'moderator' })}</InputLabel>
                  <Select
                    data-testid="moderator-select"
                    disabled={!targetCompany}
                    labelId="parentUid-label"
                    id="parentUid"
                    name="parentUid"
                    value={targetParentUid ?? ''}
                    onChange={(e) => setTargetParentUid(e.target.value)}
                    input={<OutlinedInput label={$t({ id: 'parentUid' })} />}
                    MenuProps={theme.select.getMenuProps()}
                  >
                    {companyModerators.map((mod) => {
                      return (
                        <MenuItem key={Object.keys(mod)[0]} value={Object.keys(mod)[0]}>
                          <ListItemText
                            primary={`${Object.values(mod)[0].firstName} ${Object.values(mod)[0].lastName}`}
                          />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              ) : null}
              <TextField
                data-testid="first-name-field"
                fullWidth
                required
                id="firstName"
                name="firstName"
                type="text"
                label={$t({ id: 'firstName' })}
                value={state.firstName}
                onChange={handleTextChange}
                className={classes.margin}
                autoComplete="given-name"
                autoFocus
              />
              <TextField
                data-testid="last-name-field"
                fullWidth
                required
                id="lastName"
                name="lastName"
                type="text"
                label={$t({ id: 'lastName' })}
                autoComplete="family-name"
                value={state.lastName}
                onChange={handleTextChange}
                className={classes.margin}
              />
              <TextField
                data-testid="employee-id-field"
                fullWidth
                required
                id="employeeId"
                name="employeeId"
                type="text"
                label={$t({ id: 'employeeId' })}
                value={state.employeeId}
                onChange={handleTextChange}
                autoComplete="off"
                className={classes.margin}
              />
              <FormControl
                required
                fullWidth
                className={classes.margin}
                error={state.missingPermissions}
              >
                <InputLabel id="permissions-label">{$t({ id: 'permissions' })}</InputLabel>
                <Select
                  data-testid="permissions-select"
                  labelId="permissions-label"
                  id="permissions"
                  name="permissions"
                  multiple
                  value={state.permissions}
                  onChange={handleMultipleSelectChange}
                  onClose={
                    /* istanbul ignore next */ () => {
                      if (state.missingPermissions) {
                        setState({
                          missingPermissions: !!state.permissions.length
                        });
                      }
                    }
                  }
                  input={<OutlinedInput label={$t({ id: 'permissions' })} />}
                  renderValue={(selected) => selected.map((perm) => $t({ id: perm })).join(', ')}
                  MenuProps={theme.select.getMenuProps()}
                >
                  {/* If is key user but no moderator is available, force creation of a moderator. */}
                  {permissionsRef.map((perm) => (
                    <MenuItem
                      key={perm}
                      value={perm}
                      disabled={isKeyUserMissingMods && perm !== KEY_MODERATOR}
                    >
                      <Checkbox checked={state.permissions.indexOf(perm) > -1} />
                      <ListItemText primary={$t({ id: perm })} />
                      {perm === KEY_CHECKER && (
                        <LogoTooltip
                          tooltip={$t({ id: 'grantsCountidAccess' })}
                          logo={cidLogo}
                          iconRootClass={classes.countidIconRootClass}
                        />
                      )}
                      {perm === KEY_PILOT && (
                        <LogoTooltip
                          tooltip={$t({ id: 'grantsFlyidAccess' })}
                          logo={fidLogo}
                          iconRootClass={classes.flyidIconRootClass}
                        />
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!isModSelected && (
                <FormControl
                  required
                  fullWidth
                  className={classes.margin}
                  error={state.missingDomains}
                >
                  <InputLabel id="authdomains-label">{$t({ id: 'authDomains' })}</InputLabel>
                  <Select
                    data-testid="auth-domains-select"
                    labelId="authdomains-label"
                    id="authdomains"
                    name="authDomains"
                    // If current user is key user, auth domains selection can only happen after
                    // proper filtering through target parent selection
                    disabled={!(!profile.keyUser || !!targetParentUid)}
                    multiple
                    value={state.authDomains}
                    onChange={handleMultipleSelectChange}
                    onClose={
                      /* istanbul ignore next */ () => {
                        if (state.missingDomains) {
                          setState({
                            missingDomains: !!state.authDomains.length
                          });
                        }
                      }
                    }
                    input={<OutlinedInput label={$t({ id: 'authDomains' })} />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={theme.select.getMenuProps()}
                  >
                    {(authDomains ?? []).map((domain) => (
                      <MenuItem key={domain} value={domain}>
                        <Checkbox checked={state.authDomains.indexOf(domain) > -1} />
                        <ListItemText primary={domain} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              {!usingSSO && !isKeyUserMissingMods && !isModSelected && (
                <FormControl fullWidth className={classes.margin}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          data-testid="checkbox-lacks-email"
                          name="lacksInstitutionalEmail"
                          checked={state.lacksInstitutionalEmail}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          {$t({ id: 'addUsr.lacksInstitutionalEmail' })}
                        </Typography>
                      }
                    />
                  </FormGroup>
                </FormControl>
              )}
              <TextField
                data-testid="email-field"
                fullWidth={!usingSSO || !state.lacksInstitutionalEmail}
                inputProps={{ autoComplete: 'off' }}
                required
                id="email"
                name="email"
                type={!usingSSO || state.lacksInstitutionalEmail ? 'text' : 'email'}
                label={$t({ id: 'addUsr.email' })}
                value={state.email}
                onChange={handleTextChange}
                className={classes.margin}
              />
              {!usingSSO && (
                <>
                  {!isModSelected && state.lacksInstitutionalEmail && targetCompany ? (
                    <TextField
                      data-testid="no-institutional-email-field"
                      disabled={true}
                      value={getFakeEmailDomain(targetCompany)}
                      sx={{ mb: 1 }}
                    />
                  ) : null}
                  <TextField
                    data-testid="password-field"
                    fullWidth
                    inputProps={{ autoComplete: 'new-password' }}
                    id="password"
                    name="password"
                    type="password"
                    label={$t({ id: 'addUsr.pw' })}
                    value={state.password}
                    onChange={handleTextChange}
                    className={classes.margin}
                  />
                  <TextField
                    data-testid="confirm-password-field"
                    fullWidth
                    inputProps={{ autoComplete: 'new-password' }}
                    id="confirmation"
                    name="confirmation"
                    type="password"
                    label={$t({ id: 'addUsr.pwconf' })}
                    value={state.confirmation}
                    onChange={handleTextChange}
                    required={state.showConfirmation}
                    className={cx(classes.margin, state.showConfirmation ? null : classes.hidden)}
                  />
                </>
              )}
              <LoadingButton
                data-testid="submit-button"
                isLoading={ui.loadingButton.isAddUserLoading}
                content={$t({ id: 'submit' })}
                type="submit"
              />
            </>
          )}
        </form>
      ) : (
        <LoadingCircle data-testid="loading-circle" />
      )}
    </Container>
  );
};

export default AddUser;
