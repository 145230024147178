import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableCell,
  TableBody,
  Typography
} from '@mui/material';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { useParams } from 'react-router-dom';
import { green } from '@mui/material/colors';
import { useIntl } from 'react-intl';
import { InflatedAddressesData } from 'flyid-core/dist/Database/Models';
import { ChangedData } from './SessionReviewLayout';

const useStyles = appMakeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(3),
    maxHeight: '375px'
  },
  headerCell: {
    fontWeight: 'bold'
  },
  alteredValue: {
    color: green[500],
    fontSize: 'inherit',
    fontFamily: 'inherit'
  }
}));

export type ReviewedData = {
  checkFields: string[][];
  originalData: InflatedAddressesData;
  changedData: ChangedData;
  checkboxState: { empty: boolean; invalid: boolean }[];
  multipleData: boolean;
};

const SessionPreview: React.FC<ReviewedData> = (props) => {
  const { session } = useParams<SessionMatchParams>();
  const classes = useStyles();
  const intl = useIntl();
  const $t = intl.$t;
  const theme = useAppTheme();

  const changedDataValues = Object.values(props.changedData);
  const originalDataValues = Object.values(props.originalData);

  return (
    <>
      <Box>
        <Typography variant="h4" sx={theme.text.title}>
          {`${$t({ id: 'domain.sessionReview' })} - ${session}`}
        </Typography>
        <Typography variant="subtitle1" sx={theme.text.subtitle}>
          {`${$t({ id: 'preview' })}`}
        </Typography>

        <TableContainer component={Paper} className={classes.container}>
          <Table
            sx={{ minWidth: 650 }}
            stickyHeader
            aria-label="preview-table"
            data-testid="preview-table"
          >
            <TableHead>
              <TableRow data-testid="table-row-header">
                <TableCell key={'address'} align="left" className={classes.headerCell}>
                  {$t({ id: 'sessionReview.address' })}
                </TableCell>
                {props.multipleData ? (
                  <TableCell
                    key={`labels`}
                    align="left"
                    className={classes.headerCell}
                    data-testid="table-cell-labels-header"
                  >
                    {$t({ id: 'label' })}
                  </TableCell>
                ) : null}
                {props.checkFields.map((fields, fieldsIndex) => {
                  return fields.map((field, fieldIndex) => (
                    <TableCell
                      key={`${fieldsIndex}-${fieldIndex}`}
                      align="left"
                      className={classes.headerCell}
                    >
                      {field}
                    </TableCell>
                  ));
                })}
                <TableCell key={'checkboxFlags'} align="left" className={classes.headerCell}>
                  {$t({ id: 'flags' })}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {Object.entries(props.changedData).map(([address, values], index) => {
                return values.map((value, idx) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      data-testid="table-row-body"
                    >
                      <TableCell align="left" key={`${address}${index}`}>
                        {address}
                      </TableCell>

                      {props.multipleData ? (
                        <TableCell
                          align="left"
                          key={`${address}${idx}`}
                          data-testid="table-cell-labels-body"
                        >
                          {values.length > 1 ? `${$t({ id: 'label' })} ${idx + 1}` : ''}
                        </TableCell>
                      ) : null}

                      {originalDataValues[index][idx] &&
                        props.checkFields.map((fields, fieldsIndex) => {
                          return fields.map((field, fieldIndex) => (
                            <TableCell key={`${fieldsIndex}-${fieldIndex}`} align="left">
                              {changedDataValues[index][idx][field] !== undefined ? (
                                <Typography className={classes.alteredValue}>
                                  {changedDataValues[index][idx][field]}
                                </Typography>
                              ) : originalDataValues[index][idx][field] === '' ? (
                                ''
                              ) : (
                                originalDataValues[index][idx][field]
                              )}
                            </TableCell>
                          ));
                        })}

                      <TableCell key={`checkboxState${index}${idx}`} align="left">
                        {props.checkboxState[index].empty === true
                          ? $t({ id: 'empty' })
                          : props.checkboxState[index].invalid === true
                            ? $t({ id: 'invalid' })
                            : ''}
                      </TableCell>
                    </TableRow>
                  );
                });
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

export default SessionPreview;
