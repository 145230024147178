import { isEqual, isObject, transform } from 'lodash';
import { ModifiableTarget } from 'src/redux/types/firestoreTypes';

// COR START turn flags off on release
export const USE_REDUX_LOGGER = false;
export const FORCE_REDUX_DEV_TOOLS = false;
export const DEBUG_LISTENERS = false;
export const DEBUG_WORKFLOW = false;
export const DEBUG_FIRESTORE_REDUCER: boolean | ModifiableTarget = false;
// COR END turn flags off on release

/** Use to debug immer draftables */
export const logSnapshot = (obj: unknown) => {
  const stack = new Error().stack;
  const callerLine = stack!.split('\n')[2];
  console.log(callerLine, JSON.parse(JSON.stringify(obj)));
};

export const traceSnapshot = (obj: object) => console.trace(JSON.parse(JSON.stringify(obj)));

export const dump = (obj: object) => {
  console.log(dumpStr(obj));
};

export const dumpStr = (obj: object) => JSON.stringify(obj);

export function sleepFor(sleepDuration: number) {
  const now = new Date().getTime();
  while (new Date().getTime() < now + sleepDuration) {
    /* do nothing */
  }
}

export function logPropDifferences(newProps: object, lastProps: object) {
  const allKeys = new Set([...Object.keys(newProps), ...Object.keys(lastProps)]);
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  allKeys.forEach((key) => {
    const newValue = newProps[key];
    const lastValue = lastProps[key];
    if (newValue !== lastValue) {
      console.log(`Changed ${key}:`);
      console.log('New Value: ', newValue);
      console.log('Last Value: ', lastValue);
      // console.log(`Difference: ${dumpStr(difference(newValue, lastValue))}`);
    }
  });
}
/* eslint-enable @typescript-eslint/no-unsafe-assignment */

export function changes(_obj: object, _base: object) {
  return transform(_obj, (result: object, value: never, key: string) => {
    if (!isEqual(value, _base[key])) {
      result[key] =
        isObject(value) && isObject(_base[key]) ? changes(value, _base[key]) : value;
    }
  });
}

// eslint-disable-next-line
export const difference = (obj: object, base: object) => {
  return changes(obj, base);
};
