import { parseEnvToJSON } from 'flyid-core/dist/Util/helpers';
import { FirebaseConfig } from './util/config';

export const getDevConfig = () => parseEnvToJSON<FirebaseConfig>('REACT_APP_FLYID_DEV_CONFIG');

const environment = {
  serverApi: 'https://us-central1-flyid-v2.cloudfunctions.net/api',
  isProduction: true,
  isDevelopment: false,
  usingEmulators: false
};
export type Environment = typeof environment;

const getEnvironment = () => {
  environment.serverApi = process.env.REACT_APP_API_ENTRY_POINT!;
  environment.usingEmulators = process.env.REACT_APP_EMU === 'true';
  environment.isProduction = process.env.REACT_APP_ENV?.includes('production') ?? false;

  if (process.env.REACT_APP_ENV === 'development') {
    const devConfig = getDevConfig();
    environment.serverApi = `http://127.0.0.1:5001/${devConfig.projectId}/us-central1/v2`;
    environment.isDevelopment = true;
  }

  return environment;
};

export default getEnvironment();
