import { CustomClaims } from 'flyid-core/dist/Database/Models/Settings/CustomClaims';
import { MaybeKeyUserPublic } from 'flyid-core/dist/Database/Models/User';
import { getUserSlice } from 'flyid-core/dist/Redux/reducers/userReducer';
import { UserState } from 'flyid-core/dist/Redux/types/userTypes';

const userSlice = getUserSlice<MaybeKeyUserPublic, CustomClaims>();
const { setUserProfile, setProfileImageData, setProfileImageThumb, setUserProfileError } =
  userSlice.actions;
const MyUserState = UserState<MaybeKeyUserPublic, CustomClaims>;

export {
  MyUserState,
  setProfileImageData,
  setProfileImageThumb,
  setUserProfile,
  setUserProfileError
};
export default userSlice.reducer;
export const userActions = userSlice.actions;
