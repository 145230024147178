import React, { memo } from 'react';
import BaseNode, { Content, Handles } from './BaseNode';
import TableViewIcon from '@mui/icons-material/TableView';
import { useIntl } from 'react-intl';
import { TypedNode } from 'src/util/processFlow/types';
import { isSpecificDataEqual } from 'src/util/processFlow/node';
import { AutoFillData } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/AutoFillData';
import { HandleType } from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { NodeProps } from '@xyflow/react';

export const AutoFillNode: React.FC<NodeProps<TypedNode<AutoFillData[]>>> = (props) => {
  const { $t } = useIntl();

  const content: Content = {
    iconStart: TableViewIcon,
    description: $t({ id: 'processFlow.AutoFillData' })
  };
  const handles: Handles = {
    inputHandles: [HandleType.MULTIPLE],
    outputHandles: [HandleType.SINGLE]
  };

  return (
    <div>
      {/* Content and basic handlers */}
      <BaseNode
        id={props.id}
        selected={props.selected}
        content={content}
        handles={handles}
        {...props.data.baseNodeData}
      />
    </div>
  );
};

export default memo(AutoFillNode, isSpecificDataEqual);
