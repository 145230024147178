import { cx } from '@emotion/css';
import { Backdrop, Box, Fade, Modal, ModalProps } from '@mui/material';
import React, { KeyboardEvent } from 'react';
import { appMakeStyles } from 'src/theme/theme';

const useStyles = appMakeStyles(({ palette, spacing }) => ({
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    maxHeight: `calc(100% - 32px)`,
    maxWidth: `calc(100% - 128px)`,
    backgroundColor: palette.background.paper,
    borderRadius: spacing(2),
    transform: 'translate(-50%, -50%)',
    overflow: 'auto',
    padding: spacing(2)
  }
}));

type Props = ModalProps & {
  containerClass?: string;
  timeout?: number;
};

const TransitionModal: React.FC<Props> = (props: Props) => {
  const classes = useStyles();

  return props.children ? (
    <Modal
      slots={{ backdrop: props.slots?.backdrop ?? Backdrop }}
      open={props.open}
      closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: props.timeout ?? 500
        }
      }}
      onClose={props.onClose}
      sx={{ overflow: 'hidden' }}
      onKeyDown={(e: KeyboardEvent) => {
        if (e.key === 'Escape') props.onClose?.(e, 'escapeKeyDown');
      }}
    >
      <Fade in={props.open} timeout={props.timeout || 500}>
        <Box className={cx(classes.container, props.containerClass || '')}>{props.children}</Box>
      </Fade>
    </Modal>
  ) : null;
};

export default TransitionModal;
