// Components
import { Outlet, Route, Routes, useLocation, useParams } from 'react-router-dom';

// Pages
import ProtectedRoute from './components/utils/ProtectedRoute';

import EmailActionsHandler from './components/utils/EmailActionsHandler';

import Main from './components/dashboard/Main';

import Domain from './components/dashboard/Domain';
import SessionView from './components/dashboard/Session';
import SessionReview from './components/dashboard/SessionReview/SessionReview';
import CreateInventoryTask from './components/dashboard/Tasks/CreateInventoryTask';
import InventoryTasks from './components/dashboard/Tasks/InventoryTasks';
// import AllAddresses from './components/dashboard/AllAddresses';

import AddUser from './components/user/AddUser';
import ChangePassword from './components/user/ChangePassword';
import ForgotPassword from './components/user/ForgotPassword';
import Login from './components/user/Login';
import SetPassword from './components/user/SetPassword';
import UserProfile from './components/user/UserProfile';

import CreateApiKey from './components/management/apikeys/CreateApiKey';
import EditApiKeyAuthDomains from './components/management/apikeys/EditApiKeyAuthDomains';
import AddDomain from './components/management/domainsettings/AddDomain';
import DomainSettings from './components/management/domainsettings/DomainSettings';
import LicensesEditor from './components/management/domainsettings/licenses/LicensesEditor';
import ManageApiKeys from './components/management/ManageApiKeys';
import ManageDomains from './components/management/ManageDomains';
import ManageUsers from './components/management/ManageUsers';

import AddOrUpdateTabularData from './components/management/domainsettings/tabulardata/AddOrUpdateTabularData';
import AccuracyReport from './components/reports/AccuracyReport';
import ErrorBoundary from './components/widgets/ErrorBoundary';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RouteDebug: React.FC = (props) => {
  const params = useParams();
  const location = useLocation();
  console.log({ params, location, props });
  return <Outlet />;
};

const routes = () => {
  return (
    <Routes>
      {/* <Route element={<RouteDebug />}> */}
      {/* Email actions handling routes */}
      <Route path="/emailActions" element={<EmailActionsHandler />} />
      {/* User related routes */}
      <Route path="/login" element={<Login />} errorElement={<ErrorBoundary />} />
      <Route path="/forgotpw" element={<ForgotPassword />} />
      <Route element={<ProtectedRoute />}>
        {/* Dashboard routes */}
        <Route path="/domains/:domain">
          <Route index element={<Domain />} />
          {/* <Route path="/domains/:domain/all" element={<AllAddresses/>} /> */}
          <Route path="sessions/:session/accuracyreport" element={<AccuracyReport />} />
          <Route path="sessions/:session/review" element={<SessionReview />} />
          <Route path="sessions/:session" element={<SessionView />} />
          <Route path="tasks/create" element={<CreateInventoryTask />} />
          <Route path="tasks" element={<InventoryTasks />} />
        </Route>

        {/* User related routes */}
        <Route path="/setpin" element={<SetPassword isPin={true} />} />
        <Route path="/setpw" element={<SetPassword isPin={false} />} />
        <Route path="/profile">
          <Route index element={<UserProfile />} />
          <Route path="changepw" element={<ChangePassword isPin={false} />} />
          <Route path="changepin" element={<ChangePassword isPin={true} />} />
        </Route>
      </Route>

      {/* Management routes (only for moderators */}
      <Route element={<ProtectedRoute modOnly />}>
        <Route path="/manageusers" element={<ManageUsers />} />
        <Route path="/managedomains" element={<ManageDomains />} />
        <Route path="/addDomain" element={<AddDomain />} />
        <Route path="/adduser" element={<AddUser />} />
        <Route path="/profile/:uid" element={<UserProfile />} />

        {/* API Key Management */}
        <Route path="/managekeys">
          <Route index element={<ManageApiKeys />} />
          <Route path="create" element={<CreateApiKey />} />
          <Route path=":apiKeyId/authDomains" element={<EditApiKeyAuthDomains />} />
        </Route>

        {/* Domain Settings */}
        <Route path="/domain/:domain">
          <Route path="settings" element={<DomainSettings />} />
          <Route path="editLicenses" element={<LicensesEditor />} />
          <Route path="addOrUpdateTable" element={<AddOrUpdateTabularData />} />
        </Route>
        {/* <Route path="/ft" element={<Test/>} /> */}

        {/* Fallback to main */}
        <Route path="*" element={<Main />} />
      </Route>
      {/*\/ Debug route */}
      {/* </Route> */}
    </Routes>
  );
};

export default routes;
