import {
  localeToUse,
  pickLocale,
  supportedMessages,
  SupportedLocalesType
} from '../../util/locale';
import { immerable } from 'immer';

// State
export class LocaleState {
  // To allow usage of class instance in redux
  [immerable]? = true;

  messages: { [msgKey: string]: string };
  locale: SupportedLocalesType;

  constructor() {
    const savedLocale = localStorage.getItem('locale');
    this.locale = savedLocale ? pickLocale([savedLocale]) : localeToUse;
    this.messages = supportedMessages[this.locale];
  }
}
