"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPictureLoading = exports.setPictureLoaded = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const pictureTypes_1 = require("../types/pictureTypes");
const PICTURE_GROUP_MAX_SIZE = 20;
const pictureSlice = (0, toolkit_1.createSlice)({
    name: 'picture',
    initialState: {},
    reducers: {
        setPictureLoading: (state, action) => {
            const picId = action.payload.id;
            if (Object.keys(state).length >= PICTURE_GROUP_MAX_SIZE) {
                // Reduce to half size when buffer is full, by deleting oldest pics first
                const oldestFirst = Object.entries(state).sort(([, picA], [, picB]) => picA.loadedAt - picB.loadedAt);
                while (oldestFirst.length > PICTURE_GROUP_MAX_SIZE / 2) {
                    delete state[oldestFirst[0][0]];
                    oldestFirst.shift();
                }
            }
            const imageData = state[picId] || new pictureTypes_1.ImageLoadWrapper();
            imageData.isLoading = true;
            state[picId] = imageData;
        },
        setPictureLoaded: (state, action) => {
            const { src, alt, id } = action.payload;
            const imageData = state[id] || new pictureTypes_1.ImageLoadWrapper();
            imageData.isLoading = false;
            imageData.isLoaded = true;
            imageData.src = src;
            imageData.alt = alt;
            imageData.loadedAt = new Date().getTime();
            state[id] = imageData;
        }
    }
});
_a = pictureSlice.actions, exports.setPictureLoaded = _a.setPictureLoaded, exports.setPictureLoading = _a.setPictureLoading;
exports.default = pictureSlice.reducer;
