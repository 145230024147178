import {
  Alert,
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Snackbar,
  SnackbarCloseReason,
  Theme,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ServerMessageJSON, isServerMessageJSON } from 'flyid-core/dist/Common/messages';
import { MessageType, PictureState, UiActionTypes, UiState } from 'flyid-core/dist/Redux';
import { RecordKey } from 'flyid-core/dist/Util/types';
import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import Viewer from 'react-viewer';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import { Dispatch } from 'redux';

const useStyles = <T extends Theme = Theme>() =>
  makeStyles((theme: T) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
      flexDirection: 'column'
    },
    imageViewer: {
      zIndex: theme.zIndex.drawer + 1,
      position: 'relative',
      width: '70vw',
      height: '70vh',
      left: 0,
      top: 0
    },
    marginBottom: { marginBottom: theme.spacing(2) },
    backdropText: { margin: theme.spacing(4) },
    alertMessage: {
      fontSize: theme.typography.fontSize * 1.2
    }
  }))();

function GlobalFeedback<
  Actions extends RecordKey,
  ParameterMap extends Record<Actions, unknown>,
  LoadingButtons
>(
  props: PropsWithChildren<{
    ui: UiState<Actions, ParameterMap, LoadingButtons>;
    uiActions: UiActionTypes<Actions, ParameterMap, LoadingButtons>;
    dispatch: Dispatch;
    pictures?: PictureState;
    handleAction: (d: Dispatch, a: Actions, data: ParameterMap[Actions]) => void;
    onDialogChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    imageUnavailable: string;
  }>
) {
  const { $t } = useIntl();
  const classes = useStyles();

  const { updateUi } = props.uiActions;

  const { dispatch, ui, pictures, handleAction, imageUnavailable } = props;

  const handleMessage = (message: MessageType | ServerMessageJSON): MessageType =>
    isServerMessageJSON(message)
      ? $t({ id: String(message.msgCode), defaultMessage: message.msg })
      : (message ?? $t({ id: 'unkownError' }));

  const handleSnackbarClose = (reason?: SnackbarCloseReason) => {
    if (reason !== 'clickaway') dispatch(updateUi(null));
  };

  const handleDialogClose = (reason?: string) => {
    if (reason === 'backdropClick') return;

    const dialog = ui.dialog;
    const cancelAction = dialog.getCancelAction();
    if (cancelAction) {
      const actionData = ui.dialog.getCancelActionData()!;
      handleAction(dispatch, cancelAction, actionData);
    } else {
      dispatch(updateUi(null));
    }
  };

  const handleDialogConfirmation = () => {
    const confirmAction = ui.dialog.getConfirmAction();
    if (confirmAction) {
      const actionData = ui.dialog.getConfirmActionData()!;
      handleAction(dispatch, confirmAction, actionData);
    } else {
      dispatch(updateUi(null));
    }
  };

  const getImages = (): ImageDecorator[] => {
    const { pictureFilenames: pfns } = ui.imageViewer;
    const pictureFilenames = pfns as string[];
    if (pictureFilenames?.some((pfn) => !!pictures?.[pfn])) {
      return pictureFilenames.map((pfn) =>
        pictures![pfn]
          ? {
              src: pictures![pfn].src ?? '',
              alt: pictures![pfn].alt
            }
          : { src: imageUnavailable, alt: $t({ id: 'img.unavailable' }) }
      );
    }
    return [{ src: imageUnavailable, alt: $t({ id: 'img.unavailable' }) }];
  };

  return (
    <>
      <Snackbar
        data-testid="custom-element"
        open={ui.snackbar.show}
        autoHideDuration={ui.snackbar.duration}
        onClose={(_, reason) => handleSnackbarClose(reason)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={ui.snackbar.severity}
          onClose={() => handleSnackbarClose()}
          classes={{
            message: classes.alertMessage
          }}
        >
          {ui.snackbar.show && handleMessage(ui.snackbar.message)}
        </Alert>
      </Snackbar>

      <Dialog
        open={ui.dialog.show}
        onClose={(_, reason) => handleDialogClose(reason)}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{ui.dialog.title}</DialogTitle>
        <DialogContent>
          <div className={classes.marginBottom}>{ui.dialog.message}</div>
          {ui.dialog.useCheckbox ? (
            <FormControlLabel
              control={
                <Checkbox checked={ui.dialog.checkboxState} onChange={props.onDialogChange} />
              }
              label={<Typography variant="body2">{ui.dialog.checkboxMessage}</Typography>}
            />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDialogClose()} color="primary" autoFocus>
            {$t({ id: 'cancel' })}
          </Button>
          <Button
            onClick={handleDialogConfirmation}
            color="primary"
            disabled={ui.dialog.useCheckbox && !ui.dialog.checkboxState}
          >
            {$t({ id: 'confirm' })}
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop className={classes.backdrop} open={ui.backdrop.show}>
        <CircularProgress color="inherit" size={60} thickness={4} />
        <Typography variant="h5" className={classes.backdropText}>
          {ui.backdrop.show && handleMessage(ui.backdrop.message)}
        </Typography>
      </Backdrop>

      <Viewer
        className={classes.imageViewer}
        visible={ui.imageViewer.show}
        onClose={() => dispatch(updateUi({ imageViewer: { show: false } }))}
        images={getImages()}
        activeIndex={ui.imageViewer.activeIndex}
        zoomSpeed={0.1}
        noNavbar
      />
    </>
  );
}

export default GlobalFeedback;
