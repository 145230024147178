import { enUS, esES, Localization, ptBR } from '@mui/material/locale';
import { createTheme, Theme } from '@mui/material/styles';
import { makeStyles, Styles, useTheme, WithStylesOptions } from '@mui/styles';
import { MenuProps } from '@mui/material';
import { pidPalette, pidExtraTheme } from 'flyid-core/dist/Theme';
import { SupportedLocalesType } from 'src/util/locale';

const extraTheme = {
  ...pidExtraTheme,
  form: {
    maxWidth: '768px'
  }
};
type ExtraThemeType = typeof extraTheme;

const theme = createTheme({
  // Add palette (overrides)
  palette: pidPalette,
  // Override component props
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Do not allow button to wrap text
          whiteSpace: 'nowrap',
          minWidth: 'min-content'
        }
      }
    }
  },
  // Add non-overriding themes
  ...extraTheme
}) as Theme & ExtraThemeType;

const resizableContainer = (horizontalPadding: number, verticalPadding = 2) => ({
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(
      2 * verticalPadding,
      7 * horizontalPadding,
      2 * verticalPadding,
      7 * horizontalPadding
    )
  },
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(
      1.5 * verticalPadding,
      5 * horizontalPadding,
      1.5 * verticalPadding,
      5 * horizontalPadding
    )
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(
      verticalPadding,
      2 * horizontalPadding,
      verticalPadding,
      2 * horizontalPadding
    )
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(verticalPadding, horizontalPadding, verticalPadding, horizontalPadding)
  }
});

const text = {
  title: {
    flex: '1 1 100%',
    color: theme.other.grey.dark,
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  },
  tableTitle: {
    flex: '1 1 100%',
    fontWeight: 'bolder',
    color: theme.other.grey.dark
  }
};

const reactFlow = {
  inputHandle: (size: number, activeMultiplier = 2) => {
    const activeSize = size * activeMultiplier;
    return {
      background: theme.palette.info.main,
      borderColor: theme.palette.info.dark,
      borderRadius: theme.spacing(size),
      width: theme.spacing(size),
      height: theme.spacing(size),
      '&:hover': {
        width: theme.spacing(activeSize),
        height: theme.spacing(activeSize)
      },
      '&.connectingto.valid': {
        background: theme.palette.success.main,
        borderColor: theme.palette.success.dark
      },
      '&.connectingto:not(valid)': {
        background: theme.palette.error.main,
        borderColor: theme.palette.error.dark
      }
    };
  },

  outputHandle: (size: number, activeMultiplier = 2) => {
    const activeSize = size * activeMultiplier;
    return {
      background: theme.palette.secondary.light,
      borderColor: theme.palette.secondary.main,
      borderRadius: theme.spacing(size),
      width: theme.spacing(size),
      height: theme.spacing(size),
      '&:hover': {
        width: theme.spacing(activeSize),
        height: theme.spacing(activeSize)
      },
      '&.connectingto.valid': {
        background: theme.palette.success.main,
        borderColor: theme.palette.success.dark
      },
      '&.connectingto:not(valid)': {
        background: theme.palette.error.main,
        borderColor: theme.palette.error.dark
      }
    };
  }
};

const SELECT_ITEM_HEIGHT = 48;
const SELECT_ITEM_PADDING_TOP = 8;
const SELECT_MAX_ITEMS = 10;
const select = {
  getMenuProps: (extra?: Partial<MenuProps>): Partial<MenuProps> => ({
    PaperProps: {
      style: {
        maxHeight: SELECT_ITEM_HEIGHT * SELECT_MAX_ITEMS + SELECT_ITEM_PADDING_TOP
      }
    },
    ...extra
  }),
  inFormControl: {
    margin: theme.spacing(1, 1, 1, 0)
  }
};

const ioSymbolStyle = (size: number) => ({
  backgroundColor: theme.palette.primary.dark,
  borderRadius: theme.spacing(size),
  margin: theme.spacing(size * 0.5),
  width: theme.spacing(size),
  height: theme.spacing(size)
});

const MuiLocales: Record<SupportedLocalesType, Localization> = {
  'en-GB': enUS,
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-ES': esES
};

const ThemeExtensions = {
  /** Spacing applied to correctly place content when drawer is showing */
  resizableContainer,
  /** Standardized styles for text */
  text,
  /** Standardized MUI Select styles */
  select,
  /** Standardized styles for ReactFlow handles */
  reactFlow,
  /** Standardized IO symbol styles*/
  ioSymbolStyle
};
export type ThemeExtensionsType = typeof ThemeExtensions;

// Common for all nodes
const getTheme = (locale: SupportedLocalesType) =>
  createTheme(theme, ThemeExtensions, MuiLocales[locale]);

export type AppTheme = Theme & ExtraThemeType & ThemeExtensionsType;

function appMakeStyles(
  styles: Styles<AppTheme, Record<string, unknown>>,
  options?: Omit<WithStylesOptions<AppTheme>, 'withTheme'>
) {
  return makeStyles<AppTheme>(styles, options);
}

const useAppTheme = useTheme<AppTheme>;

export { appMakeStyles, useAppTheme };

export default getTheme;
