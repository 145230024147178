import { cx } from '@emotion/css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ClearIcon from '@mui/icons-material/Clear';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import DeleteIcon from '@mui/icons-material/Delete';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import InfoIcon from '@mui/icons-material/Info';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReplayIcon from '@mui/icons-material/Replay';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  lighten,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Skeleton,
  SvgIconProps,
  Tooltip,
  Typography
} from '@mui/material';
import { documentId, DocumentSnapshot, query, where } from 'firebase/firestore';
import { Task, TaskState } from 'flyid-core/dist/Database/Models/Task';
import { getInventoryTasksCol } from 'flyid-core/dist/Util/database';
import { getJSONConverter } from 'flyid-ui-components/dist/converters/storedStateConverters';
import { useStoredState } from 'flyid-ui-components/dist/hooks';
import usePaginatedData from 'flyid-ui-components/dist/hooks/usePaginatedData';
import DropdownFilter from 'flyid-ui-components/dist/utils/DropdownFilter';
import SortSelector, { SortOption } from 'flyid-ui-components/dist/utils/SortSelector';
import { isEmpty } from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, Navigate, useParams } from 'react-router-dom';
import UnstyledLink from 'src/components/utils/UnstyledLink';
import { buildCollectionRef } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { selectTargetCompany } from 'src/redux/selectors/globalSelectors';
import { selectCurrentUserProfile } from 'src/redux/selectors/userSelectors';
import { appMakeStyles, AppTheme, useAppTheme } from 'src/theme/theme';
import { Actions } from '../../../redux/actions/actionsHandler';
import { getTaskExampleAddresses } from '../../../util/helpers/tasks';
import { isAssistantProf, isKeyUserProf, isModeratorProf } from '../../../util/helpers/user';

const useStyles = appMakeStyles((theme) => ({
  container: { ...theme.resizableContainer(2), marginLeft: 0 },
  subtitle: {
    margin: theme.spacing(1)
  },
  listContainer: {
    maxWidth: '800px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4)
  },
  listItem: {
    paddingRight: theme.spacing(25),
    margin: theme.spacing(2)
  },
  itemText: {
    margin: theme.spacing(1, 1)
  },
  stateDescriptionText: {
    textAlign: 'right'
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
    padding: theme.spacing(0.5)
  },
  skeletonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'flex-start'
  },
  skeletonItemText: {
    minWidth: theme.spacing(60),
    margin: theme.spacing(0, 1),
    height: theme.spacing(8)
  },
  tooltipRoot: {
    maxWidth: 500
  },
  paginationContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  pageSizeSelectorContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}));

export type PageState = {
  data: DocumentSnapshot<Task>[];
  total: number;
  page: number;
  pageSize: number;
};

const getTextColorByState = (theme: AppTheme, state: TaskState) => {
  switch (state) {
    case TaskState.PENDING:
      return theme.palette.warning.main;
    case TaskState.STARTED:
      return theme.palette.info.main;
    case TaskState.UPLOADING:
      return theme.palette.info.dark;
    case TaskState.COMPLETED:
      return theme.palette.success.main;
  }
};

const getTaskImageByState = (theme: AppTheme, state: TaskState) => {
  const style = {
    color: theme.palette.common.white,
    backgroundColor: getTextColorByState(theme, state)
  };

  let icon: (props: SvgIconProps) => JSX.Element | null;
  switch (state) {
    case TaskState.PENDING:
      icon = (props: SvgIconProps) => <HourglassEmptyIcon {...props} />;
      break;
    case TaskState.STARTED:
      icon = (props: SvgIconProps) => <PlayArrowIcon {...props} />;
      break;
    case TaskState.UPLOADING:
      icon = (props: SvgIconProps) => <CloudSyncIcon {...props} />;
      break;
    case TaskState.COMPLETED:
      icon = (props: SvgIconProps) => <DoneAllIcon {...props} />;
      break;
    default:
      icon = () => null;
      break;
  }
  return { style, icon };
};

const InventoryTasks: React.FC = () => {
  const classes = useStyles();
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const { $t } = useIntl();
  const { domain } = useParams<DomainMatchParams>();

  // Fallback to home if domain is missing
  if (!domain) return <Navigate replace to="/" />;

  const { targetCompany, profile, locale } = useAppSelector((s) => ({
    targetCompany: selectTargetCompany(s),
    profile: selectCurrentUserProfile(s),
    locale: s.locale.locale
  }));

  const [selection, setSelection] = useState<{ [taskId: string]: boolean }>({});
  const [filters, setFilters] = useStoredState<string[]>(
    `${targetCompany ?? ''}${domain}taskFilters`,
    [],
    getJSONConverter()
  );
  const [orderBy, setOrderBy] = useStoredState<number>(
    `${targetCompany ?? ''}${domain}orderTasksBy`,
    0
  );

  const [pageState, setPageState] = useStateReducer<PageState>({
    data: [],
    total: 0,
    page: 1,
    pageSize: 5
  });

  const resetPageState = () => {
    setPageState({
      data: [],
      total: 0,
      page: 1,
      pageSize: 5
    });
  };

  const taskStateFilters = useMemo(
    () =>
      Object.values(TaskState).reduce(
        (obj, key) => {
          obj[key] = $t({ id: `inventoryTasks.${key}` });
          return obj;
        },
        {} as Record<TaskState, string>
      ),
    [locale, $t]
  );

  const tasksOrderByOptions = useMemo(
    (): SortOption[] => [
      {
        field: 'createdDate',
        direction: 'desc',
        valueToShow: $t({ id: 'descCreatedDate' })
      },
      {
        field: 'createdDate',
        direction: 'asc',
        valueToShow: $t({ id: 'ascCreatedDate' })
      },
      {
        field: documentId(),
        direction: 'desc',
        valueToShow: $t({ id: 'descName' })
      },
      {
        field: documentId(),
        direction: 'asc',
        valueToShow: $t({ id: 'ascName' })
      }
    ],
    [locale, $t]
  );

  const dataQuery = useMemo(() => {
    if (!profile || !targetCompany) return null;
    else {
      resetPageState();
      let _query = query(buildCollectionRef(getInventoryTasksCol(targetCompany, domain)));
      if (filters.length) {
        _query = query(_query, where('state', 'in', filters));
      }
      return _query;
    }
  }, [profile, filters, targetCompany, domain]);

  const isTaskDeletable = (taskId: string) => {
    if (pageState.data) {
      const task = pageState.data.find((s) => s.id === taskId)?.data();
      return task?.state !== TaskState.STARTED;
    }
    return false;
  };

  const showRemoveTaskDialogConfirmation = () => {
    if (!hasSelection || !profile || !targetCompany) return;

    const taskIds = [
      ...Object.entries(selection)
        .filter(([, v]) => v)
        .map(([k]) => k)
    ];

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'inventoryTasks.removeTaskTitle' }),
          message: $t({ id: 'inventoryTasks.removeTasksMsg' }),
          useCheckbox: true,
          checkboxState: false,
          checkboxMessage: <b>{$t({ id: `inventoryTasks.taskRemovalCheck` })}</b>,
          show: true
        }).setConfirmAction(Actions.REMOVE_INVENTORY_TASK, {
          taskIds,
          domain,
          company: targetCompany
        })
      })
    );
  };

  const showResetTaskDialogConfirmation = (taskId: string) => {
    const task = pageState.data.find((s) => s.id === taskId)?.data();
    if (!task || !profile || !targetCompany) return;

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'inventoryTasks.restartTaskTitle' }),
          message: $t(
            { id: 'inventoryTasks.restartTaskMsg' },
            { name: <b key="mub0">{task.name}</b>, nl: <br key="munl0" /> }
          ),
          show: true
        }).setConfirmAction(Actions.RESTART_INVENTORY_TASK, {
          taskId,
          domain,
          company: targetCompany
        })
      })
    );
  };

  const showTaskNotCompletedSnack = () => {
    dispatch(
      updateUi({
        snackbar: {
          message: $t({ id: 'inventoryTasks.notCompleted' }),
          severity: 'error',
          duration: 1000,
          show: true
        }
      })
    );
  };

  const handleCheckboxChange = (taskId: string) => (event: ChangeEvent<HTMLInputElement>) => {
    if (isTaskDeletable(taskId)) {
      setSelection({ ...selection, [taskId]: event.target.checked });
    }
  };

  const hasSelection = Object.values(selection).some((e) => e === true);

  const canManageTasks =
    isAssistantProf(profile) || isModeratorProf(profile) || isKeyUserProf(profile);

  const selectAll = () => {
    if (pageState.data) {
      const newSelection = {};
      Object.keys(pageState.data).forEach((k) => (newSelection[k] = isTaskDeletable(k)));
      setSelection(newSelection);
    }
  };

  const clearSelection = () => setSelection({});

  const handlePageSizeChange = (event: SelectChangeEvent<typeof pageState.pageSize>) => {
    setPageState({ pageSize: event.target.value as number });
  };

  const handlePaginationChange = (event, value: number) => {
    setPageState({ page: value });
  };

  const onPageChange = useCallback((page: number) => setPageState({ page }), [setPageState]);

  const paginatedData = usePaginatedData(
    dataQuery,
    pageState.page,
    pageState.pageSize,
    tasksOrderByOptions[orderBy].field,
    tasksOrderByOptions[orderBy].direction,
    onPageChange,
    false,
    true
  );

  useEffect(() => {
    setSelection({});

    if (!paginatedData.data) return;

    setPageState({
      total: paginatedData.count,
      data: paginatedData.data.slice(
        pageState.pageSize * (pageState.page - 1),
        pageState.pageSize * pageState.page
      )
    });
  }, [paginatedData.data, paginatedData.count, pageState.page, pageState.pageSize]);

  return (
    <Container className={classes.container}>
      <Typography variant="h4" sx={theme.text.title}>
        {$t({ id: 'inventoryTasks.title' }, { domain })}
      </Typography>
      <Typography variant="subtitle1" sx={theme.text.subtitle}>
        {$t({ id: 'inventoryTasks.subtitle' })}
      </Typography>

      <Grid container direction="row" alignItems={'center'}>
        {/* Create task button */}
        {canManageTasks && (
          <Grid item xs="auto" sx={{ mr: 1 }}>
            <Button
              variant="contained"
              startIcon={<AddCircleIcon />}
              color="secondary"
              aria-label="add"
              component={Link}
              to={`create`}
            >
              {$t({ id: 'inventoryTasks.newTask' })}
            </Button>
          </Grid>
        )}

        {/* Filters */}
        <Grid item xs="auto" sx={{ mr: 1 }}>
          <SortSelector
            sortingOptions={tasksOrderByOptions}
            onSortChange={(o) => setOrderBy(o ?? 0)}
            initialState={orderBy ?? undefined}
          />
        </Grid>
        <Grid item xs="auto" sx={{ mr: 1 }}>
          <DropdownFilter
            availableFilters={taskStateFilters}
            initialState={filters}
            onFilterChange={setFilters}
          />
        </Grid>

        {/* Spacer */}
        <Grid item xs />

        {/* Task actions/selector */}
        {!isEmpty(pageState.data) && canManageTasks ? (
          <Grid item container direction="row" xs="auto">
            <Grid item xs="auto">
              <Tooltip
                arrow
                sx={{ marginLeft: 'auto' }}
                title={
                  <Typography variant="subtitle2">
                    {$t({
                      id: hasSelection
                        ? 'inventoryTasks.clearSelection'
                        : 'inventoryTasks.selectAll'
                    })}
                  </Typography>
                }
              >
                <IconButton
                  sx={{
                    color: 'info.main',
                    ml: 'auto'
                  }}
                  onClick={hasSelection ? clearSelection : selectAll}
                  size="large"
                >
                  {hasSelection ? <ClearIcon /> : <SelectAllIcon />}
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs="auto">
              <Tooltip
                arrow
                title={
                  <Typography variant="subtitle2">
                    {$t({
                      id: 'inventoryTasks.removeSelected'
                    })}
                  </Typography>
                }
              >
                <span>
                  <IconButton
                    disabled={!hasSelection}
                    aria-label={$t({ id: 'remove' })}
                    sx={{
                      color: hasSelection ? 'error.main' : theme.other.grey.light
                    }}
                    onClick={() => showRemoveTaskDialogConfirmation()}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      {/* Existing tasks */}
      <List
        className={classes.listContainer}
        subheader={
          <ListItemText
            primary={$t({ id: 'inventoryTasks.listHeader' })}
            primaryTypographyProps={{ variant: 'h6' }}
            sx={{ backgroundColor: 'background.default' }}
          />
        }
      >
        {pageState.data && !paginatedData.isLoading ? (
          !isEmpty(pageState.data) ? (
            pageState.data.map((taskSnap) => {
              const taskId = taskSnap.id;
              const task = taskSnap.data();
              if (!task) return null;

              const taskAvatarData = getTaskImageByState(theme, task.state);
              const taskIsComplete = task.state === TaskState.COMPLETED;
              const taskIsPending = task.state === TaskState.PENDING;
              const taskIsStarted = task.state === TaskState.STARTED;
              const taskIsUploading = task.state === TaskState.UPLOADING;

              return (
                <ListItem
                  key={taskId}
                  className={classes.listItem}
                  sx={{
                    backgroundColor: selection[taskId]
                      ? lighten(theme.palette.secondary.light, 0.75)
                      : 'transparent',
                    alignItems: 'flex-start'
                  }}
                >
                  <ListItemAvatar sx={{ m: 0 }}>
                    <Avatar
                      alt={$t({
                        id: 'altInventoryTaskStateImage'
                      })}
                      sx={taskAvatarData.style}
                    >
                      {taskAvatarData.icon({ fontSize: 'large' })}
                    </Avatar>
                  </ListItemAvatar>
                  <UnstyledLink
                    to={taskIsComplete ? `/domains/${domain}/sessions/${taskId}` : '#'}
                    onClick={taskIsComplete ? undefined : showTaskNotCompletedSnack}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      marginRight: 5
                    }}
                  >
                    <ListItemText className={classes.itemText} primary={task.name} />
                    <ListItemText
                      className={cx(classes.itemText, classes.stateDescriptionText)}
                      primary={$t({
                        id: `inventoryTasks.${task.state}`,
                        defaultMessage: $t({
                          id: 'inventoryTasks.unknownState'
                        })
                      })}
                      sx={{ color: getTextColorByState(theme, task.state) }}
                    />
                  </UnstyledLink>
                  {canManageTasks && (
                    <ListItemSecondaryAction>
                      <Tooltip
                        arrow
                        title={
                          <Typography variant="subtitle2">
                            {$t({
                              id:
                                taskIsPending || taskIsUploading
                                  ? 'inventoryTasks.cannotRestart'
                                  : 'restart'
                            })}
                          </Typography>
                        }
                      >
                        <span>
                          <IconButton
                            sx={{
                              color:
                                taskIsPending || taskIsUploading
                                  ? theme.other.grey.main
                                  : 'info.main'
                            }}
                            disabled={taskIsPending || taskIsUploading}
                            onClick={() => showResetTaskDialogConfirmation(taskId)}
                            edge="start"
                            aria-label={$t({
                              id:
                                taskIsPending || taskIsUploading
                                  ? 'inventoryTasks.cannotRestart'
                                  : 'restart'
                            })}
                            size="large"
                          >
                            <ReplayIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                      {task.exampleAddresses && (
                        <Tooltip
                          arrow
                          classes={{ tooltip: classes.tooltipRoot }}
                          title={
                            <Typography variant="subtitle2">
                              {$t(
                                { id: 'inventoryTasks.taskSummary' },
                                {
                                  exampleAddresses: getTaskExampleAddresses(task),
                                  count: task.addressesCount
                                }
                              )}
                            </Typography>
                          }
                        >
                          <IconButton
                            aria-label={$t({ id: 'info' })}
                            sx={{ color: 'info.main' }}
                            size="large"
                          >
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {/* Selection checkbox */}
                      <Checkbox
                        edge="end"
                        onChange={handleCheckboxChange(taskId)}
                        disabled={taskIsStarted || taskIsUploading}
                        checked={!!selection[taskId]}
                        color="secondary"
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              );
            })
          ) : (
            <ListItem key="emptyTasks" alignItems="flex-start" className={classes.listItem}>
              <ListItemText
                id="emptyTasks"
                className={classes.itemText}
                primary={$t({
                  id: 'inventoryTasks.emptyTasks'
                })}
              />
            </ListItem>
          )
        ) : (
          [0, 1, 2].map((i) => (
            <Container className={cx(classes.listItem, classes.skeletonContainer)} key={`skel${i}`}>
              <Skeleton variant="text" animation="wave" className={classes.skeletonItemText} />
            </Container>
          ))
        )}

        {paginatedData.data && paginatedData.count ? (
          <Box className={classes.paginationContainer}>
            <Pagination
              count={Math.ceil(paginatedData.count / pageState.pageSize)}
              color="primary"
              page={pageState.page}
              onChange={handlePaginationChange}
              size="large"
            />
            <Box className={classes.pageSizeSelectorContainer}>
              <Typography>Rows per page: </Typography>
              <FormControl sx={{ m: 1, minWidth: 60 }} size="small">
                <InputLabel id="page-size-select-label"></InputLabel>
                <Select
                  labelId="page-size-select-label"
                  id="page-size-select"
                  value={pageState.pageSize}
                  label=""
                  onChange={handlePageSizeChange}
                >
                  <MenuItem value={5}>05</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>15</MenuItem>
                  <MenuItem value={30}>20</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
        ) : null}

        <ListItem />
      </List>
    </Container>
  );
};

export default InventoryTasks;
