import { Edge } from '@xyflow/react';
import {
  getType,
  NodeType,
  ParentSpecificData
} from 'flyid-core/dist/Database/Models/Settings/ProcessFlow/Elements';
import { IntlShape } from 'react-intl';
import { getOutgoersById } from './common';
import { filterNodesById, filterNodesByType, getNodeById, PARENT_NODE_TYPES } from './node';
import { SpecificDataTypesListable, TypedNode } from './types';

export enum ProcessFlowPathResult {
  OK,
  LOOP_FOUND,
  LEAF_FOUND
}

export const isProcessFlowConsistent = (
  nodes: Array<TypedNode>,
  edges: Array<Edge>,
  errorCallback: (
    err: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    msgProps?: { [param: string]: any } | ((intl: IntlShape) => { [param: string]: any })
  ) => void
): boolean => {
  // There should be exactly one Start and one End nodes
  const startNodes = filterNodesByType(NodeType.Start, nodes);
  if (startNodes.length !== 1) {
    errorCallback('processFlow.oneNodeOnly', (intl) => ({
      name: intl.$t({ id: `processFlow.${NodeType.Start}` })
    }));
    return false;
  }
  // Any special reason to not allow multiple end nodes?
  // const endNodes = filterNodesByType(NodeType.End, nodes);
  // if (endNodes.length !== 1) {
  //   errorCallback('processFlow.oneNodeOnly', (intl) => ({
  //     name: intl.$t({ id: `processFlow.${NodeType.End}` })
  //   }));
  //   return false;
  // }

  // Check if there is a single connection to Start Node
  const startEdge = edges.filter((e) => getType<NodeType>(e.source) === NodeType.Start)[0];
  if (!startEdge) {
    errorCallback('processFlow.shouldStartNode');
    return false;
  }
  const firstNode = getNodeById<SpecificDataTypesListable | undefined>(nodes, startEdge.target);
  const startNode = getNodeById<SpecificDataTypesListable | undefined>(nodes, startEdge.source);
  if (!firstNode || !startNode) {
    errorCallback('processFlow.shouldStartNode');
    return false;
  }

  const visitedNodes = new Set([startNode]);
  const res = checkWorkflowPath(startNode, nodes, edges, visitedNodes);
  switch (res) {
    case ProcessFlowPathResult.LOOP_FOUND: {
      errorCallback('processFlow.loopFound');
      return false;
    }
    case ProcessFlowPathResult.LEAF_FOUND: {
      errorCallback('processFlow.leafFound');
      return false;
    }
    default:
      break;
  }

  // If number of visited nodes is not equal to total node count, there are hanging nodes
  if (visitedNodes.size !== nodes.length) {
    errorCallback('processFlow.hangingNodeFound');
    return false;
  }
  return true;
};

// Follow from first node, visiting all nodes with depth first search
export const checkWorkflowPath = (
  currNode: TypedNode,
  nodes: Array<TypedNode>,
  edges: Array<Edge>,
  visitedNodes: Set<TypedNode>
): ProcessFlowPathResult => {
  return recursiveCheckProcessFlowPath(currNode, nodes, edges, visitedNodes);
};

function recursiveCheckProcessFlowPath(
  currNode: TypedNode,
  nodes: Array<TypedNode>,
  edges: Array<Edge>,
  visitedNodes: Set<TypedNode>,
  internalVisitedNodes: TypedNode[] = []
): ProcessFlowPathResult {
  // console.log('visiting', currNode.id);
  visitedNodes.add(currNode);
  if (currNode.type === NodeType.End) return ProcessFlowPathResult.OK;
  if (internalVisitedNodes.includes(currNode)) return ProcessFlowPathResult.LOOP_FOUND;

  // Parent nodes will have their output through its children
  // Even when an output node is present, the parent's children are the next nodes to
  // visit, since they will end up leading to the output node anyways.
  let nextNodes: TypedNode[];
  if (PARENT_NODE_TYPES.includes(getType(currNode.id)!)) {
    const parentData = currNode.data.specificData as ParentSpecificData;
    if (parentData.contentChildrenIds?.length) {
      nextNodes = filterNodesById(nodes, parentData.contentChildrenIds);
    } else {
      // If parent does not have children, it's a dead end
      nextNodes = [];
    }
  } else {
    nextNodes = getOutgoersById(currNode.id, nodes, edges);
  }

  if (!nextNodes.length) {
    // console.log('Leaf found!', currNode.id);
    return ProcessFlowPathResult.LEAF_FOUND;
  }
  // console.log(nextNodes);

  const nextVisitedNodes = [...internalVisitedNodes, currNode];
  for (const node of nextNodes) {
    const res = recursiveCheckProcessFlowPath(node, nodes, edges, visitedNodes, nextVisitedNodes);
    if (res !== ProcessFlowPathResult.OK) return res;
  }
  return ProcessFlowPathResult.OK;
}
