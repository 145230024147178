import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import SyncIcon from '@mui/icons-material/Sync';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { Box, Button, Container, Fab, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { Property } from 'csstype';
import { APIKey, APIKeyStatus } from 'flyid-core/dist/Database/Models/APIKey';
import { parseDateFromTimestamp } from 'flyid-core/dist/Util/time';
import { isEmpty } from 'lodash';
import { Fragment, JSX, MouseEvent } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { MyDialogState } from 'src/redux/reducers/uiReducer';
import { selectApiKeys } from 'src/redux/selectors/dataSelectors';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { Actions, ParameterMap } from '../../redux/actions/actionsHandler';
import { updateUi } from '../../redux/reducers/uiReducer';
import { copyTextToClipboard } from '../../util/helpers/other';
import LoadingCircle from '../widgets/LoadingCircle';

const useStyles = appMakeStyles(({ spacing, resizableContainer }) => ({
  container: { ...resizableContainer(1), marginLeft: 0 },
  table: { minWidth: '1024px' },
  skeleton: { height: spacing(4) }
}));

enum DialogTypes {
  RENEW_API_KEY,
  REMOVE_API_KEY
}

const MsInADay = 1000 * 60 * 60 * 24;
const MsInAMonth = MsInADay * 30;
const MsInAYear = MsInADay * 365;

type HeaderColumn = { id: string; size: number; justifyContent?: Property.JustifyContent };
type HeaderSubColumn = {
  size: number;
  isSubColumn: boolean;
  columns: { [column: string]: HeaderColumn };
};
const headers: { [column: string]: HeaderColumn | HeaderSubColumn } = {
  status: { id: 'manApiKeys.status', size: 1 },
  creationDate: { id: 'manApiKeys.creationDate', size: 1 },
  description: { id: 'description', size: 2 },
  apiKey: { id: 'manApiKeys.shortenedApiKey', size: 2 },
  authDomains: { id: 'manApiKeys.authDomains', size: 4 },
  subColumn: {
    size: 2,
    isSubColumn: true,
    columns: {
      safetyLevel: { id: 'manApiKeys.safetyLevel', size: 2, justifyContent: 'center' },
      actions: { id: 'manApiKeys.actions', size: 10, justifyContent: 'space-evenly' }
    }
  }
};
const isSubColumn = (candidate: HeaderColumn | HeaderSubColumn): candidate is HeaderSubColumn =>
  !!(candidate as HeaderSubColumn).isSubColumn;

type SubColumnData = {
  isSubcolumn: true;
  columns: { [column: string]: JSX.Element };
};

const ManageApiKeys: React.FC = () => {
  const classes = useStyles();
  const { $t } = useIntl();
  const { palette, text } = useAppTheme();
  const dispatch = useAppDispatch();

  const { hasOwnedApiKeys, apiKeys, loadingApiKeys } = useAppSelector((s) => {
    const apiKeys = selectApiKeys(s);
    const loadingApiKeys = apiKeys === null;
    return {
      loadingApiKeys,
      hasOwnedApiKeys: apiKeys ? !loadingApiKeys && !isEmpty(apiKeys) : false,
      uid: s.user.uid,
      apiKeys
    };
  });

  const handleCopy = (_text: string) => () => {
    copyTextToClipboard(_text, () => {
      dispatch(
        updateUi({
          snackbar: {
            message: $t({ id: 'manApiKeys.keyCopied' }),
            severity: 'success',
            duration: 3000,
            show: true
          }
        })
      );
    });
  };

  const showConfirmationDialog =
    (eventType: DialogTypes, apiKeyId: string, apiKey: APIKey) =>
    (e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
      e.preventDefault();

      let msgId: string, confirmAction: Actions, actionData: ParameterMap[Actions];
      switch (eventType) {
        case DialogTypes.RENEW_API_KEY:
          msgId = 'renewApiKey';
          confirmAction = Actions.RENEW_API_KEY;
          actionData = {
            parentUid: apiKey.parentUid,
            data: { apiKeyId, description: apiKey.description }
          };
          break;
        case DialogTypes.REMOVE_API_KEY: {
          msgId = 'removeApiKey';
          confirmAction = Actions.REMOVE_API_KEY;
          actionData = {
            parentUid: apiKey.parentUid,
            data: { apiKeyId }
          };
          break;
        }
      }

      dispatch(
        updateUi({
          dialog: new MyDialogState({
            title: $t({ id: `manApiKeys.${msgId}Title` }),
            message: $t(
              { id: `manApiKeys.${msgId}Msg` },
              { description: apiKey.description, nl: <br key="nl0" /> }
            ),
            useCheckbox: true,
            checkboxState: false,
            checkboxMessage: <b>{$t({ id: `manApiKeys.${msgId}Check` })}</b>,
            show: true
          }).setConfirmAction(confirmAction, actionData)
        })
      );
    };

  const createRowData = (
    apiKeyId: string,
    apiKey: APIKey
  ): { [column: string]: SubColumnData | JSX.Element } => {
    let index = 0;
    const keyBase = apiKeyId.substring(4);
    const getKey = () => `${keyBase}${index++}`;

    const isInvalid = apiKey.status === APIKeyStatus.INVALID;
    const style = isInvalid ? { color: palette.grey[600] } : {};
    const typogWrap = (content) => (
      <Typography sx={style} key={getKey()}>
        {content}
      </Typography>
    );

    return {
      status: typogWrap($t({ id: `manApiKeys.${apiKey.status}` })),
      creationDate: typogWrap(parseDateFromTimestamp(apiKey.creationDate).toLocaleDateString()),
      description: typogWrap(apiKey.description),
      authDomains: (
        <Box
          sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'center' }}
          key={getKey()}
        >
          <Typography sx={{ overflowWrap: 'anywhere', mr: 'auto', ...style }}>
            {apiKey.authDomains.length ? (
              apiKey.authDomains.map((domain, i) => (
                <Fragment key={`${domain}${i}`}>
                  {domain}
                  <br />
                </Fragment>
              ))
            ) : (
              <Box color={palette.grey[600]}>&lt;{$t({ id: 'none' })}&gt;</Box>
            )}
          </Typography>
          <Tooltip title={$t({ id: 'edit' })}>
            <Box>
              <IconButton
                edge="end"
                aria-label={$t({ id: 'edit' })}
                component={Link}
                to={`/managekeys/${apiKeyId}/authDomains`}
                sx={{ color: 'info.main', mx: 1, my: 0, ...style }}
                disabled={isInvalid}
                size="large"
              >
                <EditIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      ),
      apiKey: (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            alignItems: 'center'
          }}
          key={getKey()}
        >
          <Typography sx={{ overflowWrap: 'anywhere', ...style }}>
            ...{apiKey.token.substring(apiKey.token.length - 60)}
          </Typography>
          <Tooltip title={$t({ id: 'copy' })}>
            <Box>
              <IconButton
                edge="end"
                aria-label={$t({ id: 'copy' })}
                onClick={handleCopy(apiKey.token)}
                sx={{ color: 'info.main', mx: 1, my: 0, ...style }}
                disabled={isInvalid}
                size="large"
              >
                <FileCopyOutlinedIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      ),
      subColumn: {
        isSubcolumn: true,
        columns: {
          safetyLevel: (() => {
            const creationDate = parseDateFromTimestamp(apiKey.creationDate);
            const timeSinceCreationDate = new Date().getTime() - creationDate.getTime();

            if (isInvalid || timeSinceCreationDate < 9 * MsInAMonth) {
              return <CheckCircleOutlineIcon sx={{ color: 'success.main' }} key={getKey()} />;
            } else if (timeSinceCreationDate < MsInAYear) {
              return <WarningRoundedIcon sx={{ color: 'warning.main' }} key={getKey()} />;
            } else {
              return <WarningRoundedIcon sx={{ color: 'error.main' }} key={getKey()} />;
            }
          })(),
          actions: (
            <Fragment key={getKey()}>
              <Tooltip title={$t({ id: 'renew' })}>
                <span>
                  <IconButton
                    edge="end"
                    aria-label={$t({ id: 'renew' })}
                    sx={{ color: 'info.main', ...style }}
                    onClick={showConfirmationDialog(DialogTypes.RENEW_API_KEY, apiKeyId, apiKey)}
                    disabled={isInvalid}
                    size="large"
                  >
                    <SyncIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={$t({ id: 'remove' })}>
                <IconButton
                  edge="end"
                  aria-label={$t({ id: 'remove' })}
                  sx={{ color: 'error.main' }}
                  onClick={showConfirmationDialog(DialogTypes.REMOVE_API_KEY, apiKeyId, apiKey)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Fragment>
          )
        }
      }
    };
  };

  const justifyContentWrapper = (
    justifyContent: Property.JustifyContent | undefined,
    children: JSX.Element
  ) => <Box sx={justifyContent ? { display: 'flex', justifyContent } : {}}> {children} </Box>;

  return (
    <Container className={classes.container}>
      <Typography variant="h4" sx={text.title}>
        {$t({ id: 'manApiKeys.title' })}
      </Typography>
      <Typography variant="subtitle1" sx={text.subtitle}>
        {$t({ id: 'manApiKeys.subtitle' })}
      </Typography>

      <div>
        {loadingApiKeys ? (
          <LoadingCircle />
        ) : hasOwnedApiKeys ? (
          <>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.table}
            >
              {/* Header */}
              {Object.values(headers).map((headerData, i) =>
                isSubColumn(headerData) ? (
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    xs={headerData.size}
                    key={`hc${i}`}
                  >
                    {Object.values(headerData.columns).map((subHeaderData, si) => (
                      <Grid item key={`hi${i}${si}`} xs={subHeaderData.size}>
                        {justifyContentWrapper(
                          subHeaderData.justifyContent,
                          <Typography>{$t({ id: subHeaderData.id })}</Typography>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  <Grid item key={`hi${i}`} xs={headerData.size}>
                    {justifyContentWrapper(
                      headerData.justifyContent,
                      <Typography> {$t({ id: headerData.id })}</Typography>
                    )}
                  </Grid>
                )
              )}
            </Grid>

            {/* Data */}
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
              className={classes.table}
            >
              {Object.entries(apiKeys!)
                .filter(([, apiKey]) => Boolean(apiKey))
                .map(([apiKeyId, apiKey], index) => {
                  const rowData = createRowData(apiKeyId, apiKey!);

                  return Object.entries(headers).map(([headerKey, headerData], i) => {
                    const columnData = rowData[headerKey];

                    return isSubColumn(headerData) ? (
                      <Grid
                        container
                        item
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        xs={headerData.size}
                        key={`hvc${i}`}
                      >
                        {Object.entries(headerData.columns).map(
                          ([colHeaderKey, subHeaderData], si) => {
                            const subColumnData = (columnData as SubColumnData).columns[
                              colHeaderKey
                            ];
                            return (
                              <Grid item key={`${colHeaderKey}${i}${si}`} xs={subHeaderData.size}>
                                {justifyContentWrapper(subHeaderData.justifyContent, subColumnData)}
                              </Grid>
                            );
                          }
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={headerData.size} key={`${headerKey}${index}`}>
                        {justifyContentWrapper(
                          headerData.justifyContent,
                          columnData as JSX.Element
                        )}
                      </Grid>
                    );
                  });
                })}
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6">
              {$t(
                { id: 'manApiKeys.noKeys' },
                {
                  clickHere: (
                    <Button
                      component={Link}
                      to="/managekeys/create"
                      size="small"
                      key={'createKeyBtn'}
                    >
                      {$t({ id: 'clickHere' })}
                    </Button>
                  )
                }
              )}
            </Typography>
          </Grid>
        )}
      </div>

      <Fab
        variant="extended"
        size="medium"
        color="secondary"
        aria-label="createApiKey"
        data-testid="create-key-btn"
        component={Link}
        to="/managekeys/create"
        sx={{ mt: 4 }}
      >
        <AddCircleIcon sx={{ mr: 1 }} />
        {$t({ id: 'manApiKeys.createKey' })}
      </Fab>
    </Container>
  );
};
export default ManageApiKeys;
