import axios, { AxiosRequestConfig } from 'axios';
import { FirebaseError } from 'firebase/app';
import {
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth';
import { getDownloadURL, ref } from 'firebase/storage';
import { Permissions } from 'flyid-core/dist/Database/Models/User';
import {
  getPicturePath,
  getStorageKeyUserProfileImagePath,
  getStorageKeyUserProfileThumbPath,
  getStorageUserProfileImagePath,
  getStorageUserProfileThumbPath
} from 'flyid-core/dist/Util/database';
import { decodeText } from 'flyid-core/dist/Util/web';
import { IntlShape } from 'react-intl';
import imageUnavailable from 'src/assets/images/img_unavailable_white.png';
import { silentyNavigateTo } from 'src/router';
import authAxios, { withParentUid, WithPossibleParent } from 'src/util/axios';
import { getSnackbar } from 'src/util/helpers/server';
import { Nilable } from 'tsdef';
import { formatTimeDate } from '../../util/helpers/string';
import { SupportedLocalesType } from '../../util/locale';
import { urlDataReader } from '../../workers/fileWorkerApi';
import { setPictureLoaded, setPictureLoading } from '../reducers/acquisitionPicsReducer';
import { updateUi } from '../reducers/uiReducer';
import { setProfileImageData, setProfileImageThumb } from '../reducers/userReducer';
import { ThunkActionType } from './../store';
import { resetAction } from './actionTypes';

export type LoginParams = { email: string; password: string };
export const login =
  (data: LoginParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isLoginLoading: true } }));

    signInWithEmailAndPassword(getAuth(), data.email, data.password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (!user) throw new Error('Something went wrong during login!');

        dispatch(updateUi());
        silentyNavigateTo('/');
      })
      .catch((err: Error) =>
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isLoginLoading: false
            }
          })
        )
      );
  };

export type LogoutParams = Nilable<{ redirectOnSuccess?: string }>;
export const logoutAction =
  (data?: LogoutParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi());

    signOut(getAuth())
      .then(() => {
        dispatch(resetAction());
        if (data?.redirectOnSuccess) {
          // If ever used, add on logout test case
          window.location.replace(data.redirectOnSuccess);
        }
      })
      .catch((err: Error) => dispatch(updateUi({ snackbar: getSnackbar(err) })));
  };

export type AddUserParams = {
  firstName: string;
  lastName: string;
  employeeId: string;
  email: string;
  company: string;
  authDomains: string[];
  usesAuthProvider: boolean;
  password?: string;
  confirmation?: string;
  lacksInstitutionalEmail: boolean;
} & Permissions;
export const addUser =
  (data: AddUserParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isAddUserLoading: true } }));

    const config: AxiosRequestConfig = {
      url: `/users`,
      method: 'post',
      data: { data }
    };

    authAxios(getAuth(), getState(), config)
      .then((res) => {
        if (res.status === 200) {
          silentyNavigateTo('/manageusers');
        }
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isAddUserLoading: false
            }
          })
        );
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isAddUserLoading: false
            }
          })
        );
      });
  };

export type EditUserParams = WithPossibleParent<{
  uid: string;
  userData: {
    company: string;
    firstName: string;
    lastName: string;
    employeeId: string;
    email: string;
    profilePicFile?: string;
    authDomains: string[];
  } & Permissions;
}>;
export const editUser =
  (params: EditUserParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    const { data } = params;

    dispatch(updateUi({ loadingButton: { isEditUserLoading: true } }));

    authAxios(getAuth(), getState(), withParentUid({ url: `/users`, method: 'patch' }, params))
      .then((res) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isEditUserLoading: false
            }
          })
        );
        if (data.userData.profilePicFile) {
          const resetImageData = { uid: data.uid, picData: { isLoaded: false, isLoading: false } };
          dispatch(setProfileImageData({ ...resetImageData }));
          dispatch(setProfileImageThumb({ ...resetImageData }));
        }
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isEditUserLoading: false
            }
          })
        );
      });
  };

export type RemoveUserParams = WithPossibleParent<{ uid: string }>;
export const removeUser =
  (params: RemoveUserParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        backdrop: {
          message: { msgCode: 'manUsr.removingUsr', msg: 'Removing user...' },
          show: true
        }
      })
    );

    authAxios(getAuth(), getState(), withParentUid({ url: `/users`, method: 'delete' }, params))
      .then((res) => dispatch(updateUi({ snackbar: getSnackbar(res) })))
      .catch((err: Error) => dispatch(updateUi({ snackbar: getSnackbar(err) })));
  };

export type ChangePasswordParams = {
  currentPassword?: string;
  newPassword: string;
  isPin: boolean;
};
export const changePassword =
  (data: ChangePasswordParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isChangePasswordLoading: true } }));

    authAxios(getAuth(), getState(), {
      url: `/users/pw`,
      method: 'patch',
      data
    })
      .then((res) => {
        dispatch(updateUi({ snackbar: getSnackbar(res) }));
        if (data.isPin) {
          silentyNavigateTo('/');
        } else dispatch(logoutAction());
      })
      .catch((err: Error) => dispatch(updateUi({ snackbar: getSnackbar(err) })));
  };

export type ResetPasswordParams = {
  newPassword: string;
  actionCode: string;
  isPin: boolean;
};
export const resetPassword =
  (data: ResetPasswordParams): ThunkActionType =>
  (dispatch) => {
    dispatch(
      updateUi({
        backdrop: {
          message: {
            msgCode: `${data.isPin ? 'setPin' : 'setPw'}.resetting`,
            msg: `Resetting ${data.isPin ? 'PIN' : 'password'}...`
          },
          show: true
        }
      })
    );

    axios({
      url: `/users/resetpw`,
      method: 'patch',
      data
    })
      .then((res) => {
        dispatch(updateUi({ snackbar: getSnackbar(res) }));
        silentyNavigateTo('/');
      })
      .catch((err: Error) => dispatch(updateUi({ snackbar: getSnackbar(err) })));
  };

export type FetchProfileImagesParams = {
  profileUids: string[];
  company: string | string[];
  isThumb?: boolean;
};
/**
 * Default behavior is to fetch thumb pictures.
 * To fetch larger image profile, set 'isThumb' to false
 */
export const fetchProfileImages =
  (data: FetchProfileImagesParams): ThunkActionType =>
  (dispatch, getState, { getProfilePicsBucket }) => {
    const { profileUids, company, isThumb = true } = data;

    const profilePicsState = getState().user[isThumb ? 'profilePicThumbs' : 'profilePics'];

    profileUids.forEach((uid) => {
      const thisProfilePic = profilePicsState[uid];
      // Only fetch profile if not loading nor loaded
      if (!thisProfilePic || (!thisProfilePic.isLoading && !thisProfilePic.isLoaded)) {
        const setProfileImageAction = isThumb ? setProfileImageThumb : setProfileImageData;

        dispatch(setProfileImageAction({ uid, picData: { isLoaded: false, isLoading: true } }));

        const path = Array.isArray(company)
          ? isThumb
            ? getStorageKeyUserProfileThumbPath(uid)
            : getStorageKeyUserProfileImagePath(uid)
          : isThumb
            ? getStorageUserProfileThumbPath(company, uid)
            : getStorageUserProfileImagePath(company, uid);

        getDownloadURL(ref(getProfilePicsBucket(), path))
          .then((url) =>
            axios({
              baseURL: undefined,
              url,
              method: 'GET',
              responseType: 'blob'
            })
          )
          .then((res) =>
            urlDataReader(res.data as Blob, {
              onload: (result) => {
                const src = result
                  ? typeof result === 'string'
                    ? result
                    : decodeText(result)
                  : undefined;
                dispatch(
                  setProfileImageAction({
                    uid,
                    picData: { src, isLoaded: true, isLoading: false }
                  })
                );
              },
              onerror: (strErr) => {
                console.log('Failed reading file', strErr);
                throw Error(strErr);
              }
            })
          )
          .catch((err) => {
            if (err instanceof FirebaseError) {
              if (String(err.code) !== '404') console.error(err);
            } else console.error(err);
            dispatch(
              setProfileImageAction({
                uid,
                picData: { src: undefined, isLoaded: true, isLoading: false }
              })
            );
          });
      }
    });
  };

export type FetchStoragePicturesParams = {
  company: string;
  domain: string;
  address: string;
  checkedAtMs: number;
  pictureFilenames: string[];
  addressField: string;
  intl: IntlShape;
  dontShowViewer?: boolean;
};
export const fetchAndShowStoragePictures =
  (data: FetchStoragePicturesParams): ThunkActionType =>
  (dispatch, getState, { getDefautBucket }) => {
    const state = getState();

    const {
      company,
      domain,
      address,
      checkedAtMs,
      pictureFilenames,
      addressField,
      intl,
      dontShowViewer
    } = data;

    const pictures = state.acqPictures;

    const allPicturesLoadedOrLoading = pictureFilenames.every((filename) => {
      const imageData = pictures[filename];
      return imageData?.isLoading || (imageData?.isLoaded && imageData?.src);
    });

    if (!dontShowViewer) {
      if (allPicturesLoadedOrLoading) {
        if (pictureFilenames.length) {
          dispatch(
            updateUi({
              imageViewer: {
                show: true,
                pictureFilenames,
                activeIndex: 0
              }
            })
          );
        }
        return;
      }

      dispatch(
        updateUi({
          backdrop: {
            show: true,
            message: { msgCode: 'img.loading', msg: 'Loading image(s)...' }
          },
          imageViewer: {
            show: false
          }
        })
      );
    }

    pictureFilenames.forEach((pictureFilename, idx) => {
      // Handle image loading states
      getDownloadURL(ref(getDefautBucket(), getPicturePath(company, domain, pictureFilename)))
        .then((url) => {
          dispatch(setPictureLoading({ id: pictureFilename }));
          return axios({
            baseURL: undefined,
            url,
            method: 'GET',
            responseType: 'blob'
          });
        })
        .then(
          (res) =>
            new Promise((resolve) => {
              urlDataReader(res.data as Blob, {
                onload: (result) => {
                  const src = result
                    ? typeof result === 'string'
                      ? result
                      : decodeText(result)
                    : undefined;
                  // Save picture data on redux state

                  dispatch(
                    setPictureLoaded({
                      id: pictureFilename,
                      src,
                      alt: `${addressField}: ${address} [${idx}] (${formatTimeDate(
                        new Date(checkedAtMs),
                        intl
                      )})`
                    })
                  );
                  resolve(true);
                },
                onerror: () => {
                  throw new Error('Failed parsing picture');
                }
              });
            })
        )
        .catch((err: Error) => {
          console.error(err);
          dispatch(
            setPictureLoaded({
              id: pictureFilename,
              src: imageUnavailable,
              alt: intl.$t({ id: 'img.unavailable' })
            })
          );
        })
        .finally(() => {
          if (idx === 0 && !dontShowViewer) {
            dispatch(
              updateUi({
                imageViewer: {
                  show: true,
                  pictureFilenames,
                  activeIndex: 0
                }
              })
            );
          }
        });
    });
  };

export type ResendEmailVerificationParams = SupportedLocalesType;
export const resendEmailVerification =
  (locale: SupportedLocalesType): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        backdrop: {
          message: { msgCode: 'sendingEmailVerification', msg: 'Sending email verification...' },
          show: true
        }
      })
    );

    const languageCode = locale.substring(0, 2);

    const auth = getAuth();

    auth.languageCode = languageCode;
    const user = auth.currentUser;

    if (user) {
      sendEmailVerification(user)
        .then(() => {
          dispatch(
            updateUi({
              snackbar: {
                message: {
                  msgCode: '418',
                  msg: 'Email verification sent to %s',
                  args: [user.email || 'missing email!']
                },
                duration: 3000,
                severity: 'success',
                show: true
              }
            })
          );
        })
        .catch((err: Error) => dispatch(updateUi({ snackbar: getSnackbar(err) })));
    }
  };

export type SendPasswordResetEmailParams = { email: string; locale: SupportedLocalesType };
export const sendPasswordResetEmailAction =
  (data: SendPasswordResetEmailParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isForgetPasswordLoading: true } }));

    const languageCode = data.locale.substring(0, 2);

    const auth = getAuth();
    auth.languageCode = languageCode;

    sendPasswordResetEmail(auth, data.email)
      .then(() => {
        dispatch(
          updateUi({
            snackbar: {
              message: {
                msgCode: 'forgotPw.sent',
                msg: 'Password recovery instructions were sent to the provided email'
              },
              duration: 3000,
              severity: 'success',
              show: true
            }
          })
        );
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err)
          })
        );
        silentyNavigateTo('/');
      });
  };
