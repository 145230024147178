import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pickLocale, supportedMessages, SupportedLocalesType } from '../../util/locale';
import { LocaleState } from '../types/localeTypes';

const localeSlice = createSlice({
  name: 'locale',
  initialState: new LocaleState(),
  reducers: {
    setLanguage: (state, action: PayloadAction<SupportedLocalesType>) => {
      const language = action.payload;
      if (language) {
        localStorage.setItem('locale', language);
        state.locale = pickLocale([language]);
        state.messages = supportedMessages[state.locale];
      }
    }
  }
});

export const { setLanguage } = localeSlice.actions;

export default localeSlice.reducer;
