import { releaseProxy, wrap } from 'comlink';
import { AwaitReaderReturnType, ReaderStatus } from '../util/helpers/files';
import { FileWorker } from './fileWorker';

function getWorker() {
  // Here we create our worker and wrap it with comlink so we can interact with it
  const worker = new Worker(new URL('./fileWorker.ts', import.meta.url));
  // Wrap worker with comlink to make api promise-like
  const workerApi = wrap<FileWorker>(worker);

  // A cleanup function that releases the comlink proxy and terminates the worker
  const cleanup = () => {
    workerApi[releaseProxy]();
    worker.terminate();
  };

  return { workerApi, cleanup };
}

// Simple file reader readAsDataUrl method running on a worker
export function urlDataReader(urlData: Blob, callbacks: CallbacksType = {}) {
  const { workerApi, cleanup } = getWorker();
  handleReaderResult(workerApi.readAsDataUrl(urlData), cleanup, callbacks);
}

// Simple file reader readAsDataUrl method running on a worker
export function arrayBufferDataReader(bufferData: Blob, callbacks: CallbacksType = {}) {
  const { workerApi, cleanup } = getWorker();
  handleReaderResult(workerApi.readAsArrayBuffer(bufferData), cleanup, callbacks);
}

type CallbacksType = {
  onerror?: (msg: string) => void;
  onload?: (data: string | ArrayBuffer | null) => void;
};
function handleReaderResult(
  promise: Promise<AwaitReaderReturnType>,
  cleanup: () => void,
  callbacks: CallbacksType
) {
  promise
    .then(([stat, result]) => {
      switch (stat) {
        case ReaderStatus.ERROR:
        case ReaderStatus.ABORTED: {
          callbacks.onerror?.(`Failed to read data: ${JSON.stringify(result)}`);
          break;
        }
        case ReaderStatus.SUCCESS: {
          callbacks.onload?.(result);
          break;
        }
        default:
          callbacks.onerror?.('Unknown ReaderStatus!');
          break;
      }
      cleanup();
    })
    .catch((err: Error) => {
      callbacks.onerror?.(err.message);
      cleanup();
    });
}
