import { AppDispatch } from '../store';
import {
  // AddLabelDesignParams,
  EditDomainFieldsSettingsParams,
  // EditLabelDesignParams,
  EditLicensesParams,
  RemoveApiKeyParams,
  RemoveDomainParams,
  RemoveTableParams,
  RenewApiKeyParams,
  ResetUserPasswordParams,
  RestoreDomainSettingsParams,
  editDomainFieldsSettings,
  editLicenses,
  removeApiKey,
  removeDomain,
  removeTable,
  renewApiKey,
  resetUserPassword,
  restoreDomainSettings
} from './managementActions';
import {
  RemoveSessionsParams,
  RemoveTaskParams,
  RestartTaskParams,
  TriggerSessionPushParams,
  UpdateSessionParams,
  removeSessions,
  removeTask,
  restartTask,
  triggerSessionPush,
  updateSession
} from './operationalActions';
import {
  EditUserParams,
  LogoutParams,
  RemoveUserParams,
  editUser,
  logoutAction,
  removeUser
} from './userActions';

export enum Actions {
  NONE = 'NONE',
  // User Actions
  EDIT_USER = 'EDIT_USER',
  REMOVE_USER = 'REMOVE_USER',
  RESET_USER_PASSWORD = 'RESET_USER_PASSWORD',
  LOGOUT = 'LOGOUT',
  // Domain actions
  REMOVE_SESSIONS = 'REMOVE_SESSIONS',
  REMOVE_DOMAIN = 'REMOVE_DOMAIN',
  TRIGGER_SESSION_PUSH = 'TRIGGER_SESSION_PUSH',
  // Inventory task actions
  RESTART_INVENTORY_TASK = 'RESTART_INVENTORY_TASK',
  REMOVE_INVENTORY_TASK = 'REMOVE_INVENTORY_TASK',
  //Domain Settings Actions
  EDIT_DOMAIN_SETTINGS_FIELDS = 'EDIT_DOMAIN_SETTINGS_FIELDS',
  // Domain Licenses
  EDIT_DOMAIN_LICENSES = 'EDIT_DOMAIN_LICENSES',
  RESTORE_DOMAIN_SETTINGS = 'RESTORE_DOMAIN_SETTINGS',
  REMOVE_TABLE = 'REMOVE_TABLE',
  // API Key management
  RENEW_API_KEY = 'RENEW_API_KEY',
  REMOVE_API_KEY = 'REMOVE_API_KEY',
  // Session Review
  UPDATE_SESSION = 'UPDATE_SESSION'
}

export type ParameterMap = {
  NONE: undefined | null;
  // User Actions
  EDIT_USER: EditUserParams;
  REMOVE_USER: RemoveUserParams;
  RESET_USER_PASSWORD: ResetUserPasswordParams;
  LOGOUT: LogoutParams;
  // Domain actions
  REMOVE_SESSIONS: RemoveSessionsParams;
  REMOVE_DOMAIN: RemoveDomainParams;
  TRIGGER_SESSION_PUSH: TriggerSessionPushParams;
  // Inventory task actions
  RESTART_INVENTORY_TASK: RestartTaskParams;
  REMOVE_INVENTORY_TASK: RemoveTaskParams;
  // Domain Settings Actions
  EDIT_DOMAIN_SETTINGS_FIELDS: EditDomainFieldsSettingsParams;
  EDIT_DOMAIN_LICENSES: EditLicensesParams;
  RESTORE_DOMAIN_SETTINGS: RestoreDomainSettingsParams;
  // Table Data
  REMOVE_TABLE: RemoveTableParams;
  // API Key management
  RENEW_API_KEY: RenewApiKeyParams;
  REMOVE_API_KEY: RemoveApiKeyParams;
  // Session Review
  UPDATE_SESSION: UpdateSessionParams;
};

/* eslint-disable @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-explicit-any */
export const handleAction = (dispatch: AppDispatch, action: Actions, data: any) => {
  switch (action) {
    // User Actions
    case Actions.REMOVE_USER:
      dispatch(removeUser(data));
      break;
    case Actions.EDIT_USER:
      dispatch(editUser(data));
      break;
    case Actions.RESET_USER_PASSWORD:
      dispatch(resetUserPassword(data));
      break;
    case Actions.LOGOUT:
      dispatch(logoutAction(data));
      break;
    // Domain actions
    case Actions.REMOVE_SESSIONS:
      dispatch(removeSessions(data));
      break;
    case Actions.REMOVE_DOMAIN:
      dispatch(removeDomain(data));
      break;
    case Actions.TRIGGER_SESSION_PUSH:
      dispatch(triggerSessionPush(data));
      break;
    // Inventory task actions
    case Actions.RESTART_INVENTORY_TASK:
      dispatch(restartTask(data));
      break;
    case Actions.REMOVE_INVENTORY_TASK:
      dispatch(removeTask(data));
      break;
    //Domain Settings Actions
    case Actions.EDIT_DOMAIN_SETTINGS_FIELDS:
      dispatch(editDomainFieldsSettings(data));
      break;
    case Actions.RESTORE_DOMAIN_SETTINGS:
      dispatch(restoreDomainSettings(data));
      break;
    // case Actions.ADD_LABEL_DESIGN:
    //   dispatch(addLabelDesign(data));
    //   break;
    // case Actions.EDIT_LABEL_DESIGN:
    //   dispatch(editLabelDesign(data));
    //   break;
    // case Actions.REMOVE_LABEL_DESIGN:
    //   dispatch(removeLabelDesign(data));
    //   break;
    case Actions.EDIT_DOMAIN_LICENSES:
      dispatch(editLicenses(data));
      break;
    case Actions.REMOVE_TABLE:
      dispatch(removeTable(data));
      break;
    // API Key management
    case Actions.RENEW_API_KEY:
      dispatch(renewApiKey(data));
      break;
    case Actions.REMOVE_API_KEY:
      dispatch(removeApiKey(data));
      break;
    case Actions.UPDATE_SESSION:
      dispatch(updateSession(data));
      break;
    default:
      return;
  }
};
